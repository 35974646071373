import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import {
  useAuthToken,
  getSelectedAccountInfo,
  decryptData,
  useCurrentUserInfo,
  checkUserPermission
} from '../../../utils/common/user';
import { fetchTasks } from '../../../api/notes-tasks/fetchTasks';
import { DropdownResponse, TasksState } from '../user/state';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchActiveUsers } from '../../../api/common/fetchActiveUsers';
import { TaskFormState } from '../registers/state';
import { useTranslation } from 'react-i18next';
import { TaskResponseError } from './state';
import { createTasks } from '../../../api/notes-tasks/createTasks';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useNotificationContext } from '../layout/NotificationContext';
import { TasksCreateEditProps } from './state';
import DeleteButton from '../common/DeleteButton';
import Popup from '../common/popup/Popup';
import { deleteTask } from '../../../api/adviser/deleteTask';
import { TASK_DELETE } from '../../../constants/constants';
import { clearLocalStorageExcept, getModuleCategory } from '../../../utils/commonUtils';

const AddTasks: React.FC<TasksCreateEditProps> = ({
  moduleType,
  sourceId,
  recordId,
  localKey,
  listUrl
}) => {
  const user = useCurrentUserInfo();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [owner_Id, setOwner_Id] = useState<string>('');
  const [ownerArray, setOwnerArray] = useState<DropdownResponse[]>([]);

  const { setNotificationSuccess } = useNotificationContext();
  const [date_due, setDate_due] = useState<string | null>(null);
  const [date_complete, setDate_complete] = useState<string | null>(null);
  const [showCompletedFields, setShowCompletedFields] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { t } = useTranslation();
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);
  const [pageType, setPageType] = useState<string>('');
  const [deletePermission, setDeletePermission] = useState<boolean>(false);

  const minDate = dayjs(); // This sets the minimum date to today

  const maxAllowedDate = new Date();
  const [date_created, setDate_created] = useState<string | null>(null);
  const minAllowedDate = date_created || maxAllowedDate;
  const review_id = parseInt(recordId!, 10);
  const source_id = parseInt(sourceId!, 10);
  const taskId = parseInt(id!, 10);

  const [formData, setFormData] = useState<TaskFormState>({
    id: 0,
    task: '',
    details: '',
    date_due: '',
    is_complete: 0,
    date_complete: '',
    completed_notes: '',
    status_updates: '',
    is_assign: moduleType === 'meetings' || moduleType === 'reviews' || moduleType === 'prevets' ? 0 : 1,
    error: ''
  });

  useEffect(() => {
    setPageType(getModuleCategory(moduleType));
  }, [moduleType]);

  useEffect(() => {
    const sourceID = pageType === 'Settings' ? 7 : source_id;
    setDeletePermission(checkUserPermission(user, pageType, sourceID, TASK_DELETE));
  }, [user, moduleType, sourceId]);

  const fetchData = async () => {
    try {
      if (authToken && recordId && taskId) {
        const response: TasksState = (await fetchTasks(
          review_id,
          source_id,
          moduleType,
          authToken,
          taskId
        )) as TasksState;

        if (response) {
          const {
            id,
            task,
            details,
            owner,
            date_due,
            is_complete,
            date_complete,
            completed_notes,
            status_updates,
            created_at
          } = response;

          if (created_at) {
            setDate_created(created_at);
          }
          if (owner) {
            setOwner_Id(owner.toString());
          }
          if (is_complete) {
            setShowCompletedFields(true);
          }
          setDate_due(date_due ? date_due : '');
          setDate_complete(date_complete ? date_complete : '');

          const newFormData = {
            id,
            task,
            details,
            owner,
            date_due,
            is_complete,
            date_complete,
            completed_notes,
            status_updates,
            error: ''
          };

          setFormData(newFormData);
        }
      }
    } catch (error) {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  const fetchUsers = async () => {
    try {
      const response: DropdownResponse[] = await fetchActiveUsers(authToken, selectedAccount);
      if (response) {
        setOwnerArray(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchData(); // Call the async function
  }, [id, authToken, t]);

  const handleIsCompleteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({
      ...prevData,
      is_complete: event.target.checked ? 1 : 0,
      date_complete: '',
      completed_notes: ''
    }));
    if (!event.target.checked) {
      setDate_complete('');
      setErrors({});
    }
    setShowCompletedFields(event.target.checked);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.task) {
      newErrors.task = t('error.field.task');
    }
    if (!owner_Id) {
      newErrors.owner = t('error.field.ownerRequired');
    }

    if (!date_due) {
      newErrors.date_due = t('error.field.dueDate');
    }

    if (formData.is_complete === 1) {
      if (!date_complete) {
        newErrors.date_complete = t('error.field.dateComplete');
      }
      if (!formData.completed_notes) {
        newErrors.completed_notes = t('error.field.completeNotes');
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };
  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (id && authToken) {
        const response: any = (await deleteTask(parseInt(id!, 10), authToken, pageType)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.taskDeleted'));
          clearLocalStorageExcept();
          navigate(listUrl);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();
    const isFormValid = validateForm();
    if (!isFormValid) {
      setLoading(false);
      return;
    }
    const ownerIdNumber = parseInt(owner_Id, 10); // Convert owner_Id to a number
    const requestData = {
      id: formData.id,
      task: formData.task,
      details: formData.details,
      owner: ownerIdNumber,
      date_due,
      is_complete: formData.is_complete,
      date_complete,
      completed_notes: formData.completed_notes,
      status_updates: formData.status_updates,
      is_assign: formData.is_assign
    };
    const record_id = parseInt(recordId!, 10);
    const task_id = parseInt(id!, 10);
    const response: TaskResponseError = (await createTasks(
      requestData,
      record_id,
      source_id,
      moduleType,
      authToken,
      selectedAccount,
      task_id
    )) as TaskResponseError;
    if (!response?.id) {
      const newErrors: Record<string, string> = {};
      if (response.task) {
        newErrors.task = response.task[0];
      } else if (response.details) {
        newErrors.details = response.details[0];
      } else if (response.owner) {
        newErrors.owner = response.owner[0];
      } else if (response.date_due) {
        newErrors.date_due = response.date_due[0];
      } else if (response.date_complete) {
        newErrors.date_complete = response.date_complete[0];
      } else if (response.completed_notes) {
        newErrors.completed_notes = response.completed_notes[0];
      } else if (response.status_updates) {
        newErrors.status_updates = response.status_updates[0];
      } else {
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
      setErrors(newErrors);
      setLoading(false);
    } else {
      setLoading(false);
      const msg = id ? t('success.field.taskUpdated') : t('success.field.taskAdded');
      setNotificationSuccess(msg);
      localStorage.removeItem(localKey);
      const taskPageUrl = localStorage.getItem('taskPageUrl');
      if (taskPageUrl && taskPageUrl == '1') {
        navigate(`/tasks`);
      } else if (taskPageUrl && taskPageUrl == '2') {
        navigate(`/my-tasks`);
      } else {
        navigate(listUrl);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeUser = (event: SelectChangeEvent<string>) => {
    setOwner_Id(event.target.value);
  };

  const handleCancelClick = () => {
    navigate(listUrl);
  };

  const formattedContent = (formData.details || '').replace(/<br\/>/g, '\n');

  return (
    <>
      <div className="right-outer">
        <div className="right-inner">
          <div className="form-wrap">
            <form onSubmit={handleSubmit}>
              <div className="card-outline">
                <div className="row-wrap">
                  <div className="form-group w-full">
                    <label className="form-label" htmlFor="task">
                      Task
                    </label>
                    {id ? (
                      <p className="form-control">{formData.task}</p>
                    ) : (
                      <TextField
                        error={!!errors.task}
                        fullWidth
                        name="task"
                        className="form-control"
                        placeholder="Enter the task"
                        value={formData.task}
                        onChange={handleInputChange}
                        helperText={errors.task || ''}
                        inputProps={{
                          maxLength: 100 // Set the maximum character length
                        }}
                      />
                    )}
                  </div>
                  <div className="form-group w-full">
                    <label className="form-label" htmlFor="details">
                      Details
                    </label>
                    {id ? (
                      <p className="form-control">{formattedContent}</p>
                    ) : (
                      <TextField
                        error={!!errors.details}
                        fullWidth
                        name="details"
                        className="form-control textarea"
                        placeholder="Enter detailed description"
                        multiline
                        rows={4}
                        value={formattedContent}
                        onChange={handleInputChange}
                        helperText={errors.details || ''}
                      />
                    )}
                  </div>
                  {id ? (
                    <div className="form-group w-full">
                      <label className="form-label" htmlFor="status_updates">
                        Status updates
                      </label>
                      <TextField
                        error={!!errors.status_updates}
                        fullWidth
                        name="status_updates"
                        className="form-control textarea"
                        placeholder="Enter details for status updates"
                        multiline
                        rows={4}
                        value={formData.status_updates ? formData.status_updates : ''}
                        onChange={handleInputChange}
                        helperText={errors.status_updates || ''}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {Array.isArray(ownerArray) && ownerArray.length > 0 && (
                    <div className="form-group">
                      <label className="form-label">Owner</label>
                      <FormControl variant="outlined" error={!!errors.owner_Id}>
                        <Select
                          error={!!errors.owner}
                          onChange={handleChangeUser}
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={owner_Id}
                          IconComponent={KeyboardArrowDownRoundedIcon}
                          className="custom-select"
                          MenuProps={{ className: 'custom-dropdown-menu' }}
                          renderValue={(selected) => {
                            if (selected === '') {
                              return <p className="dropdown-placeholder">Select Owner</p>; // Render "Select Owner" as placeholder
                            }
                            const selectedOption =
                              ownerArray &&
                              ownerArray.find((option) => option.id === parseInt(selected, 10));
                            return selectedOption ? `${selectedOption.name}` : '';
                          }}>
                          {ownerArray.map((option) => (
                            <MenuItem key={option?.id} value={option?.id || ''}>
                              {option?.name || ''}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {errors.owner && (
                        <Typography variant="caption" color="error">
                          {errors.owner}
                        </Typography>
                      )}
                    </div>
                  )}
                  <div className="form-group">
                    <label className="form-label">Date Due</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MMM/YYYY"
                        className="form-control date-picker"
                        value={date_due ? dayjs(date_due) : null}
                        minDate={minDate}
                        onChange={(newValue) =>
                          setDate_due(newValue ? newValue.format('YYYY-MM-DD') : null)
                        }
                        slotProps={{
                          textField: {
                            error: !!errors.date_due,
                            placeholder: 'Select due date',
                            helperText: errors.date_due
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group check-outer">
                    <label className="form-label">Completed</label>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.is_complete === 1}
                          onChange={handleIsCompleteChange}
                          name="is_complete"
                          className="custom-check custom-check-other"
                        />
                      }
                      label=""
                    />
                  </div>
                  {showCompletedFields && (
                    <>
                      <div className="form-group">
                        <label className="form-label">Date Completed</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MMM/YYYY"
                            className="form-control date-picker"
                            value={date_complete ? dayjs(date_complete) : null}
                            minDate={dayjs(minAllowedDate)} // Set minDate based on the calculated difference
                            maxDate={dayjs(maxAllowedDate)} // Set maxDate to today
                            onChange={(newValue) =>
                              setDate_complete(newValue ? newValue.format('YYYY-MM-DD') : null)
                            }
                            slotProps={{
                              textField: {
                                error: !!errors.date_complete,
                                placeholder: 'Select date completed',
                                helperText: errors.date_complete
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="form-group w-full">
                        <label className="form-label" htmlFor="note">
                          Completed Notes
                        </label>
                        <TextField
                          error={!!errors.completed_notes}
                          fullWidth
                          multiline
                          rows={4}
                          name="completed_notes"
                          className="form-control textarea"
                          placeholder="Add notes about the completion of the task"
                          value={formData.completed_notes ? formData.completed_notes : ''}
                          onChange={handleInputChange}
                          helperText={errors.completed_notes || ''}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                className={`btn-wrap ${taskId !== 0 && deletePermission ? 'space-between' : ''}`}>
                {taskId !== 0 && deletePermission && (
                  <DeleteButton setOpenConfirmationPopup={setOpenConfirmationPopup} />
                )}
                {loading ? (
                  <CircularProgress />
                ) : (
                  <div>
                    <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                      Cancel
                    </Button>
                    <Button type="submit" className="btn primary-btn btn-sm">
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Popup
        type="confirmation"
        openPopup={openConfirmationPopup}
        text={`Are you sure you want to delete this record?`}
        togglePopup={togglePopup}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
};

export default AddTasks;
