import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserIndex from './user/Index';
import AccountIndex from './account-details/AccountDetails';
import ModulesIndex from './modules/Index';
import RegistersIndex from './registers/Index';
import RegisterCreateEditIndex from './registers/RegisterCreateEditIndex';
import RegisterUpdateIndex from './reg-updates/Index';
import TemplateCreateEdit from './templates/CreateEdit';
import RegisterUpdateCreate from './reg-updates/Create';
import Error from '../layout/Error';
import UserCreateEdit from './user/UserCreateEdit';
import AdviserReviewDefault from './review/AdviserReviewDefault';
// import FileReviewTemplatesIndex from './file-review/FileReviewTemplatesIndex';
import ReviewTemplateEdit from './review/ReviewTemplateEdit';
import FileReviewTemplateCreate from './file-review/FileReviewTemplateCreate';
import MeetingEdit from './meeting/MeetingEdit';
import CalenderEdit from './compliance-calender/CalenderEdit';
import ProviderDefault from './provider/ProviderDefault';
import ProviderTemplateEdit from './provider/ProviderTemplateEdit';
import OnboardingDefault from './onboardings/OnboardingDefault';
import OnboardingTemplateEdit from './onboardings/OnboardingTemplateEdit';
import Updates from './updates/Updates';
import UpdateCreateEdit from './updates/UpdateCreateEdit';
import PrevetDefault from './pre-vet/PrevetDefault';
import PrevetTemplateEdit from './pre-vet/PrevetTemplateEdit';
import PrevetFileReviewTemplateCreate from './pre-vet/PrevetFileReviewTemplateCreate';
import Policies from './policies/Policies';
import PoliciesCreateEdit from './policies/PoliciesCreateEdit';
import PoliciesNotesAdd from './policies/PoliciesNotesAdd';
import PoliciesNotes from './policies/PoliciesNotes';
import PoliciesTasksAddEdit from './policies/PoliciesTasksAddEdit';
import PoliciesTasks from './policies/PoliciesTasks';
import UserAccessPermission from './user-access-permission/UserAccessPermission';
import AdviserProfilesDefault from './adviser-profiles/AdviserProfilesDefault';
import AdviserProfilesTemplateEdit from './adviser-profiles/AdviserProfilesTemplateEdit';
import AttestationsDefault from './attestations/AttestationsDefault';
import AttestationsTemplateEdit from './attestations/AttestationsTemplateEdit';
import ProfessionalYearDefault from './professional-years/ProfessionalYearDefault';
import ProfessionalYearTemplateEdit from './professional-years/ProfessionalYearTemplateEdit';
import BillingPortal from './stripe/BillingPortal';
import Plans from './plans/Plans';
import PlansCreateEdit from './plans/PlansCreateEdit';
import AddAccountsIndex from './add-accounts/AddAccountsIndex';
import AccountCreateEdit from './add-accounts/AccountCreateEdit';

const UserSettings: React.FC = () => {
  return (
    <div className="settings-page-wrap">
      <div className="container-full">
        <div className="settings-content">
          <Routes>
            <Route path="/" element={<UserIndex />} />
            <Route path="user-access-permisssion" element={<UserAccessPermission />} />
            <Route path="user" element={<UserIndex />} />
            <Route path="/add-user" element={<UserCreateEdit />} />
            <Route path="/edit-user/:id" element={<UserCreateEdit />} />
            <Route path="account-details" element={<AccountIndex />} />
            <Route path="modules" element={<ModulesIndex />} />
            <Route path="modules/adviser-review/:id" element={<AdviserReviewDefault />} />
            <Route path="modules/onboarding/:id" element={<OnboardingDefault />} />
            <Route path="registers" element={<RegistersIndex />} />
            <Route path="registers-add" element={<RegisterCreateEditIndex />} />
            <Route path="registers-edit" element={<RegisterCreateEditIndex />} />
            <Route path="registers-edit/:id" element={<RegisterCreateEditIndex />} />
            <Route path="register-update" element={<RegisterUpdateIndex />} />
            <Route path="add-reg-update" element={<RegisterUpdateCreate />} />
            <Route path="modules/meeting/:id" element={<MeetingEdit />} />
            <Route path="modules/compliance-calender/:id" element={<CalenderEdit />} />
            <Route path="registers/provider/:id" element={<ProviderDefault />} />
            <Route path="modules/pre-vet/:id" element={<PrevetDefault />} />
            <Route path="modules/adviser-profile/:id" element={<AdviserProfilesDefault />} />
            <Route path="modules/attestations/:id" element={<AttestationsDefault />} />
            <Route path="modules/professional-year/:id" element={<ProfessionalYearDefault />} />
            {/* <Route path="file-review-templates" element={<FileReviewTemplatesIndex />} /> */}

            <Route path="updates" element={<Updates />} />
            <Route path="update-add" element={<UpdateCreateEdit />} />
            <Route path="update-edit/:id" element={<UpdateCreateEdit />} />

            <Route path="policies" element={<Policies />} />
            <Route path="policies/add" element={<PoliciesCreateEdit />} />
            <Route path="policies/update/:id" element={<PoliciesCreateEdit />} />
            <Route path="policies/details/:id" element={<PoliciesCreateEdit />} />
            <Route path="policies/:id/new-version" element={<PoliciesCreateEdit />} />
            <Route path="policies/notes" element={<PoliciesNotes />} />
            <Route path="policies/add-note" element={<PoliciesNotesAdd />} />
            <Route path="policies/edit-note/:id" element={<PoliciesNotesAdd />} />
            <Route path="policies/tasks" element={<PoliciesTasks />} />
            <Route path="policies/add-task" element={<PoliciesTasksAddEdit />} />
            <Route path="policies/edit-task/:id" element={<PoliciesTasksAddEdit />} />

            <Route path="billing" element={<BillingPortal />} />

            {/* plans */}
            <Route path="plans" element={<Plans />} />
            <Route path="plans/add" element={<PlansCreateEdit />} />
            <Route path="plans/update/:id" element={<PlansCreateEdit />} />

            {/* Accounts */}
            <Route path="add-accounts" element={<AddAccountsIndex />} />
            <Route path="/add-account" element={<AccountCreateEdit />} />
            <Route path="/edit-account/:id" element={<AccountCreateEdit />} />

            <Route path="/error" element={<Error />} />
          </Routes>
        </div>
      </div>
      {/* Move the following route outside the container as needed to occupy the entire screen */}
      <Routes>
        <Route path="register-template-edit/:id" element={<TemplateCreateEdit />} />
        <Route path="adviser-review-summary-template-edit/:id" element={<ReviewTemplateEdit />} />
        <Route path="add-file-review-template" element={<FileReviewTemplateCreate />} />
        <Route path="edit-file-review-template" element={<FileReviewTemplateCreate />} />
        <Route path="edit-file-review-template/:id" element={<FileReviewTemplateCreate />} />
        <Route
          path="provider-initial-engagement-template-edit/:id"
          element={<ProviderTemplateEdit />}
        />
        <Route
          path="provider-contract-review-template-edit/:id"
          element={<ProviderTemplateEdit />}
        />
        <Route
          path="onboarding-application-template-edit/:id"
          element={<OnboardingTemplateEdit />}
        />
        <Route
          path="onboarding-assessment-template-edit/:id"
          element={<OnboardingTemplateEdit />}
        />
        <Route path="onboarding-checklist-template-edit/:id" element={<OnboardingTemplateEdit />} />
        <Route path="prevet-summary-template-edit/:id" element={<PrevetTemplateEdit />} />
        <Route
          path="add-prevet-file-review-template"
          element={<PrevetFileReviewTemplateCreate />}
        />
        <Route
          path="edit-prevet-file-review-template"
          element={<PrevetFileReviewTemplateCreate />}
        />
        <Route
          path="edit-prevet-file-review-template/:id"
          element={<PrevetFileReviewTemplateCreate />}
        />
        <Route
          path="adviser-profile-personal-details-template-edit/:id"
          element={<AdviserProfilesTemplateEdit />}
        />
        <Route
          path="adviser-profile-authorisations-template-edit/:id"
          element={<AdviserProfilesTemplateEdit />}
        />
        <Route path="attestation-template-edit/:id" element={<AttestationsTemplateEdit />} />
        <Route
          path="professionalyear-plan-supervisor-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-q1-supervisor-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-q1-entrant-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-q2-supervisor-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-q2-entrant-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-exam-supervisor-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-exam-entrant-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-q3-supervisor-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-q3-entrant-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-q4-supervisor-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-q4-entrant-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-completion-supervisor-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-completion-licensee-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-logbook-workactivity-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
        <Route
          path="professionalyear-log-training-template-edit/:id"
          element={<ProfessionalYearTemplateEdit />}
        />
      </Routes>
    </div>
  );
};

export default UserSettings;
