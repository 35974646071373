import React from 'react';
import { AttestationFormsProps } from './state';
import {
  FormControl,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Button
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { format } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';

const AttestationCreateEditFormData: React.FC<AttestationFormsProps> = ({
  formData,
  periods,
  owners,
  users,
  errors,
  current_route,
  edit_btn_snapshot,
  selectAll,
  userIds,
  templateData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sourceId,
  handleInputChange,
  handleSelectChange,
  handleSelectAll,
  handleUserChange,
  handleFormSubmit,
  handleDateChange,
  attestationIdEncrypted,
  sourceIdEncrypted
}) => {
  return (
    <>
      <form action="" className="record-create-form">
        <div className="card-outline">
          <div className="card-title-wrap secondary-title-wrap">
            <h3>Snapshot</h3>
            <div className="inner-btn-wrap">
              {current_route && current_route === `/attestation/${attestationIdEncrypted}`
                ? edit_btn_snapshot
                : ''}
            </div>
          </div>
          {current_route && current_route === `/attestation/${attestationIdEncrypted}` && (
            <>
              <div className="row-wrap">
                <div className="form-group">
                  <label className="form-label">Created</label>
                  <p className="form-control">
                    {formData.created_at
                      ? format(new Date(formData?.created_at || ''), 'd/MMM/yyyy')
                      : ''}
                  </p>
                </div>
              </div>
              <div className="row-wrap attestation-margin">
                <div className="form-group">
                  <label className="form-label">Relevant period</label>
                  <p className="form-control">{formData.relevant_period}</p>
                </div>

                <div className="form-group">
                  <label className="form-label">Due Date</label>
                  <p className="form-control">
                    {formData.due_date
                      ? format(new Date(formData?.due_date || ''), 'd/MMM/yyyy')
                      : 'N/A'}
                  </p>
                </div>
                <div className="form-group">
                  <label className="form-label">Owner</label>
                  <p className="form-control">
                    {formData.owner?.first_name} {formData.owner?.last_name}
                  </p>
                </div>
                <div className="form-group">
                  <label className="form-label">User</label>
                  <p className="form-control">
                    {formData.user?.first_name} {formData.user?.last_name}
                  </p>
                </div>
                <div className="form-group w-full">
                  <label className="form-label">Instructions</label>
                  <p className="form-control">{formData.instructions || '-'}</p>
                </div>
                <div className={`form-group ${formData.is_open !== 0 && 'w-full-only-reopen'}`}>
                  <label className="form-label">Status</label>
                  <p className="form-control">
                    {formData.is_open == 1
                      ? 'Sent'
                      : formData.is_open == 2
                        ? 'For Review'
                        : 'Archived'}
                  </p>
                </div>

                {formData.is_open !== 0 && (
                  <div className="inner-btn-wrap">
                    <Button className="btn white-btn" onClick={() => handleFormSubmit(0)}>
                      <i className="left">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clipPath="url(#clip0_10218_12413)">
                            <path
                              d="M19.784 6.58183C19.6055 6.37094 19.3433 6.25 19.0649 6.25H0.935092C0.656852 6.25 0.39494 6.37094 0.216426 6.5816C0.0498255 6.77828 -0.0254473 7.03413 0.00763839 7.28816L1.07794 18.4578C1.07888 18.4664 1.07982 18.475 1.08099 18.4834C1.17513 19.1881 1.77356 19.7194 2.47313 19.7194H17.5269C18.2624 19.7194 18.8757 19.1561 18.9221 18.4578L19.9924 7.28839C20.0254 7.03441 19.9504 6.77851 19.784 6.58183ZM17.5306 18.3226C17.5299 18.3215 17.5264 18.3208 17.5269 18.3212L2.48547 18.3233C2.47942 18.3205 2.47055 18.3101 2.46778 18.3019L1.44657 7.64843H18.5534L17.5306 18.3226Z"
                              fill="#667085"
                            />
                            <path
                              d="M18.2334 3.56308C18.0563 3.35918 17.7988 3.24219 17.5266 3.24219H2.4962C2.22425 3.24219 1.96558 3.36359 1.7864 3.57519C1.60628 3.7882 1.52843 4.0655 1.57226 4.32718L1.99171 7.05364L3.37381 6.8411L3.03522 4.64038H16.9879L16.6496 6.8411L18.0314 7.05364L18.4518 4.31925C18.4909 4.04386 18.4115 3.76816 18.2334 3.56308Z"
                              fill="#667085"
                            />
                            <path
                              d="M16.1604 0.602615C15.9833 0.398476 15.7256 0.28125 15.4529 0.28125H4.57043C4.29848 0.28125 4.03981 0.402655 3.86063 0.614255C3.68028 0.827261 3.60266 1.10456 3.64673 1.36812L4.06618 4.04798L5.44757 3.83173L5.11062 1.67944H14.9079L14.5535 3.82587L15.933 4.05376L16.3781 1.35944C16.4177 1.08359 16.3382 0.807925 16.1604 0.602615Z"
                              fill="#667085"
                            />
                            <path
                              d="M12.9362 10.6523H7.06384C6.67771 10.6523 6.36475 10.9653 6.36475 11.3514C6.36475 11.7376 6.67771 12.0505 7.06384 12.0505H12.9362C13.3223 12.0505 13.6353 11.7376 13.6353 11.3514C13.6353 10.9653 13.3223 10.6523 12.9362 10.6523Z"
                              fill="#667085"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_10218_12413">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </i>
                      Archive
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}

          {current_route &&
            (current_route === `/attestation-edit/${attestationIdEncrypted}` ||
              current_route === `/attestation-add`) && (
              <>
                <div className="row-wrap">
                  <div className="form-group">
                    <label className="form-label">Owner</label>
                    <FormControl variant="outlined" error={!!errors.owner_id}>
                      <Select
                        error={!!errors.owner_id}
                        labelId="owner_id-label"
                        id="owner_id"
                        name="owner_id"
                        value={formData.owner_id.toString()}
                        onChange={handleSelectChange}
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        className="custom-select"
                        MenuProps={{ className: 'custom-dropdown-menu' }}
                        renderValue={(selected: string) => {
                          if (selected === '0') {
                            return <p className="dropdown-placeholder">Select the owner</p>; // Render "Select the host" as placeholder
                          }
                          const selectedOption =
                            owners && owners.find((option) => option.id === parseInt(selected, 10));
                          return selectedOption ? `${selectedOption.name}` : '';
                        }}>
                        {owners &&
                          owners?.length > 0 &&
                          owners.map((option) => (
                            <MenuItem key={option?.id} value={option?.id || ''}>
                              {option?.name || ''}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {errors.owner_id && (
                      <Typography variant="caption" color="error">
                        {errors.owner_id}
                      </Typography>
                    )}
                  </div>
                </div>
                <div className="row-wrap attestation-margin">
                  <div className="form-group">
                    <label className="form-label">Relevant Period</label>
                    <FormControl variant="outlined" error={!!errors.relevant_period}>
                      <Select
                        error={!!errors.relevant_period}
                        labelId="relevant_period-label"
                        id="relevant_period"
                        name="relevant_period"
                        value={formData.relevant_period}
                        onChange={handleSelectChange}
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        className="custom-select"
                        MenuProps={{ className: 'custom-dropdown-menu' }}
                        renderValue={(selected) => {
                          if (selected === '') {
                            return <p className="dropdown-placeholder">Select relevant period</p>; // Render "Select the host" as placeholder
                          }
                          const selectedOption =
                            periods && periods.find((option) => option.name === selected);
                          return selectedOption ? `${selectedOption.name}` : '';
                        }}>
                        {periods &&
                          periods?.length > 0 &&
                          periods.map((option) => (
                            <MenuItem key={option?.name} value={option?.name || ''}>
                              {option?.name || ''}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {errors.relevant_period && (
                      <Typography variant="caption" color="error">
                        {errors.relevant_period}
                      </Typography>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="form-label">Due Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MMM/YYYY"
                        className="form-control date-picker"
                        minDate={dayjs()}
                        name="due_date"
                        value={formData.due_date ? dayjs(formData.due_date) : null}
                        onChange={(date) => handleDateChange(date)}
                        slotProps={{
                          textField: {
                            error: !!errors.due_date,
                            placeholder: 'Select due date',
                            helperText: errors.due_date
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group w-full textarea">
                    <label className="form-label">Instructions</label>
                    <TextField
                      id="instructions"
                      variant="outlined"
                      name="instructions"
                      value={formData.instructions}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Enter instructions for recipients"
                      type="text"
                      multiline
                      rows={5}
                    />
                  </div>
                  <div className="form-group w-full">
                    <label className="form-label">Recipients</label>

                    {users && users?.length > 0 ? (
                      <>
                        <div className="switch-wrap mb-3">
                          <Switch
                            className="custom-switch"
                            checked={selectAll}
                            onChange={(e) => handleSelectAll(e)}
                          />
                          <label className="form-label">Select all</label>
                        </div>

                        {users.map((option) => (
                          <div key={option?.id} className="switch-wrap">
                            <Switch
                              className="custom-switch"
                              checked={userIds.includes(option.id)}
                              onChange={(e) => handleUserChange(e, option.id)}
                            />
                            <label className="form-label">{option?.name || ''}</label>
                          </div>
                        ))}
                        {errors.user_id && (
                          <Typography variant="caption" color="error">
                            {errors.user_id}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <label>User not found</label>
                    )}
                  </div>

                  {current_route === `/attestation-add` && (
                    <div className="form-group">
                      <div className="inner-btn-wrap">
                        {templateData !== '' && (
                          <Button
                            className="btn primary-btn btn-sm"
                            component={RouterLink}
                            to={`/attestation/preview?source=${sourceIdEncrypted}`}
                            target="_blank">
                            Preview Attestation
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
        </div>
      </form>
    </>
  );
};

export default AttestationCreateEditFormData;
