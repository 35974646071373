import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  Button,
  CircularProgress
} from '@mui/material';
import rightarrow from '../../../assets/images/right-arrow.svg';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { fetchRecords } from '../../../api/records/fetchRecords';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  decryptData,
  encryptData,
  isSubscriptionExpired
} from '../../../utils/common/user';
import { format } from 'date-fns';
import PaginationSettings from '../settings/Pagination';
import { PaginationResponse, RecordsState, TemplatesState } from '../user/state';
import CommonBreadcrumbs from '../layout/Breadcrumb/CommonBreadcrumbs';
import RecordFilters from './Filters';
import { useRegistersContext } from './RegisterContext';
import CommonLayout from '../layout/CommonLayout';
import { downloadReport, downloadReportCsv } from '../../../api/records/downloadReport';
import { fetchTemplateFromRecordType } from '../../../api/records/fetchTemplateFromRecordType';
import { CREATE, EXPORT, READ, REGISTERS } from '../../../constants/constants';

const RecordIndex: React.FC = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = decryptData(id, true);
  let title = 'Complaints Register';
  let registerPrefix = '';
  const [orderBy, setOrderBy] = useState<keyof RecordsState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [loading, setLoading] = useState<boolean>(true);
  const [exportingFileType, setExportingFileType] = useState<string>('');
  const [dense] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { pathname } = useLocation();
  const [records, setRecords] = useState<RecordsState[]>([]);
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const { registersRecords } = useRegistersContext();
  if (id) {
    const registerId = parseInt(id, 10);
    const foundRegister =
      Array.isArray(registersRecords) && registersRecords.length > 0
        ? registersRecords.find((register) => register.id === registerId)
        : '';
    title = foundRegister ? foundRegister?.name : '';
    registerPrefix = foundRegister && foundRegister.prefix ? foundRegister?.prefix : '';
  }
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  interface HeadCell {
    disablePadding: boolean;
    id: keyof RecordsState;
    label: string;
    numeric: boolean;
  }
  const initialHeadCells: HeadCell[] = [
    {
      id: 'unique_id',
      numeric: false,
      disablePadding: true,
      label: 'ID'
    },
    {
      id: 'complaint_added',
      numeric: false,
      disablePadding: true,
      label: 'Complaint Added'
    },
    {
      id: 'subject',
      numeric: false,
      disablePadding: true,
      label: 'Subject'
    },
    {
      id: 'owner_id',
      numeric: false,
      disablePadding: false,
      label: 'Owner'
    },
    {
      id: registerPrefix === 'ISS' ? 'issue_status' : 'is_open',
      numeric: true,
      disablePadding: false,
      label: registerPrefix === 'ISS' ? 'Issue Status' : 'Status'
    }
  ];

  const [headCells, setHeadCells] = useState<HeadCell[]>(initialHeadCells);

  useEffect(() => {
    if (registerPrefix === 'RLR') {
      setHeadCells((prevHeadCells) => {
        if (!prevHeadCells.find((cell) => cell.id === 'details')) {
          return [
            ...prevHeadCells.slice(0, 3),
            {
              id: 'details',
              numeric: false,
              disablePadding: true,
              label: 'Description'
            },
            ...prevHeadCells.slice(3)
          ];
        }
        return prevHeadCells;
      });
    } else {
      setHeadCells(initialHeadCells); // Reset to initial state
    }
  }, [selectedAccount.prefix, registerPrefix]);

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, REGISTERS, parseInt(id!, 10), READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
    setSelectedStatus('All');
  }, [pathname]);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        if (id && authToken) {
          const response: TemplatesState = (await fetchTemplateFromRecordType(
            authToken,
            selectedAccount,
            parseInt(id, 10)
          )) as TemplatesState;

          if (response) {
            const { form_data } = response;
            if (form_data && form_data !== '""') {
              const elements = JSON.parse(form_data).pages[0].elements;
              elements.map((element: any) => {
                if (element.name == 'Created') {
                  headCells[1].label = element.title;
                } else if (element.name == 'Subject') {
                  headCells[2].label = element.title;
                } else if (element.name == 'Owner') {
                  headCells[3].label = element.title;
                } else if (element.name == 'Status') {
                  headCells[4].label = element.title;
                }
              });
            }
          } else {
            console.error('Error:', response);
          }
        }
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching data:', error);
      }
    };

    fetchTemplate(); // Call the async function
  }, []);

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (authToken && id) {
        const source_id = parseInt(id, 10);
        const response: PaginationResponse = (await fetchRecords(
          source_id,
          authToken,
          selectedAccount,
          0,
          currentPage,
          search,
          selectedStatus,
          undefined,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            // If data is an array (e.g., RecordsState[])
            setRecords(response.data);
          }
          setTotalPages(response.last_page);
          setLoading(false); // Set loading to false when data is fetched
        }
      }
    } catch (error) {
      // Handle any errors here
      setLoading(false); // Set loading to false on error too
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    localStorage.removeItem('survey-json-template');
    localStorage.removeItem('record-id');
    localStorage.removeItem('record_unique_id');
    localStorage.removeItem('recordNotesCount');
    localStorage.removeItem('recordTasksCount');
    localStorage.removeItem('typeData');

    fetchData(orderBy, order); // Call the async function
  }, [id, currentPage, search, selectedStatus]);

  const handleSort = (property: keyof RecordsState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property, isAsc ? 'desc' : 'asc');
  };

  const redirectEditPage = (rowid: number) => {
    navigate(`/register-record/${encryptData(String(rowid), true)}?source=${encryptedId}`);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleExportExcel = async (type: string = 'file') => {
    setExportingFileType(type);

    try {
      if (authToken && id) {
        const source_id = parseInt(id, 10);

        const response: any = await downloadReport(source_id, selectedAccount, authToken, title);

        if (response.status === 200) {
          // Handle success
          setExportingFileType('');
        } else {
          console.log('Error:', response);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleExportCsv = async (type: string = 'file') => {
    setExportingFileType(type);

    try {
      if (authToken && id) {
        const source_id = parseInt(id, 10);
        const response: any = await downloadReportCsv(source_id, selectedAccount, authToken, title);

        if (response.status === 200) {
          // Handle success
          setExportingFileType('');
        } else {
          console.log('Error:', response);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const addButton = (
    <div className="inner-btn-wrap">
      {checkUserPermission(user, REGISTERS, parseInt(id!, 10), EXPORT) &&
        records.length > 0 &&
        registerPrefix === 'IDR' && (
          <>
            {exportingFileType === 'idr' ? (
              <CircularProgress />
            ) : (
              <Button
                className="btn blue-fill-btn"
                onClick={() => handleExportCsv('idr')}
                disabled={exportingFileType ? true : false}>
                <i className="left">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M16.6666 10.416V5.66602C16.6666 4.26588 16.6666 3.56582 16.3941 3.03104C16.1544 2.56063 15.772 2.17818 15.3016 1.9385C14.7668 1.66602 14.0667 1.66602 12.6666 1.66602H7.33325C5.93312 1.66602 5.23306 1.66602 4.69828 1.9385C4.22787 2.17818 3.84542 2.56063 3.60574 3.03104C3.33325 3.56582 3.33325 4.26588 3.33325 5.66602V14.3327C3.33325 15.7328 3.33325 16.4329 3.60574 16.9677C3.84542 17.4381 4.22787 17.8205 4.69828 18.0602C5.23306 18.3327 5.93308 18.3327 7.33313 18.3327H10.4166M11.6666 9.16602H6.66659M8.33325 12.4993H6.66659M13.3333 5.83268H6.66659M12.4999 15.8327L14.9999 18.3327M14.9999 18.3327L17.4999 15.8327M14.9999 18.3327V13.3327"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                ASIC IDR Report
              </Button>
            )}
          </>
        )}
      {checkUserPermission(user, REGISTERS, parseInt(id!, 10), EXPORT) && records.length > 0 && (
        <>
          {exportingFileType === 'file' ? (
            <CircularProgress />
          ) : (
            <Button
              className="btn blue-fill-btn"
              onClick={() => handleExportExcel()}
              disabled={exportingFileType ? true : false}>
              <i className="left">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_6545_879)">
                    <path
                      d="M16.6666 10.416V5.66602C16.6666 4.26588 16.6666 3.56582 16.3941 3.03104C16.1544 2.56063 15.772 2.17818 15.3016 1.9385C14.7668 1.66602 14.0667 1.66602 12.6666 1.66602H7.33325C5.93312 1.66602 5.23306 1.66602 4.69828 1.9385C4.22787 2.17818 3.84542 2.56063 3.60574 3.03104C3.33325 3.56582 3.33325 4.26588 3.33325 5.66602V14.3327C3.33325 15.7328 3.33325 16.4329 3.60574 16.9677C3.84542 17.4381 4.22787 17.8205 4.69828 18.0602C5.23306 18.3327 5.93308 18.3327 7.33313 18.3327H10.4166M11.6666 9.16602H6.66659M8.33325 12.4993H6.66659M13.3333 5.83268H6.66659M12.4999 15.8327L14.9999 18.3327M14.9999 18.3327L17.4999 15.8327M14.9999 18.3327V13.3327"
                      stroke="CurrentColor"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6545_879">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              Export Register
            </Button>
          )}
        </>
      )}
      {checkUserPermission(user, REGISTERS, parseInt(id!, 10), CREATE) && (
        <Button
          className="btn primary-btn"
          component={RouterLink}
          to={`/register-record-add?source=${encryptedId}`}>
          <i className="left">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clipPath="url(#clip0_6545_879)">
                <path
                  d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6545_879">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </i>
          Add Record
        </Button>
      )}
    </div>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section">
            <div className="container-full">
              <CommonBreadcrumbs title={title} pageTitle={title} button={addButton} />

              <RecordFilters
                search={search}
                setSearch={setSearch}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
              <div className="table-outer">
                <TableContainer>
                  {loading ? ( // Check loading state
                    <div className="no-record">
                      <CircularProgress />
                    </div>
                  ) : records.length === 0 ? (
                    <div className="no-record">No records found.</div>
                  ) : (
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                      className="table hover-table">
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={`key-${headCell.id}`}
                              align={headCell.numeric ? 'left' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}>
                              {headCell.id === 'unique_id' ? (
                                headCell.label // Render the label without sorting for the "id" column
                              ) : (
                                <TableSortLabel
                                  active={orderBy === headCell.id}
                                  direction={orderBy === headCell.id ? order : 'asc'}
                                  onClick={() => handleSort(headCell.id)}>
                                  {headCell.label}
                                  {orderBy === headCell.id ? (
                                    <Box component="span" sx={{ display: 'none' }}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              )}
                            </TableCell>
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records.map((row: RecordsState) => (
                          <TableRow key={row.id} onClick={() => redirectEditPage(row.id)}>
                            <TableCell scope="row">{row.unique_id}</TableCell>
                            {/* <TableCell scope="row">{row.name}</TableCell> */}
                            {row.complaint_added ? (
                              <TableCell align="left">
                                {format(new Date(row.complaint_added), 'd/MMM/yyyy')}
                              </TableCell>
                            ) : (
                              <TableCell align="left">N/A</TableCell>
                            )}
                            <TableCell align="left">{row.subject}</TableCell>
                            {registerPrefix === 'RLR' && (
                              <TableCell align="left">{row.details}</TableCell>
                            )}

                            <TableCell align="left">
                              {row.client?.first_name && row.client?.last_name
                                ? `${row.client.first_name} ${row.client.last_name}`
                                : 'N/A'}
                            </TableCell>
                            {registerPrefix === 'ISS' ? (
                              <TableCell align="left">
                                <span>{row.issue_status || 'N/A'}</span>
                              </TableCell>
                            ) : (
                              <TableCell align="left" className={row.is_open ? 'open' : 'closed'}>
                                <span>{row.is_open ? 'Open' : 'Archived'}</span>
                              </TableCell>
                            )}
                            <TableCell align="left">
                              <div className="table-btn">
                                <img src={rightarrow} alt="Right Arrow" />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                  {!loading && records.length > 0 && (
                    <PaginationSettings
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </TableContainer>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default RecordIndex;
