import React, { useState, useEffect } from 'react';
import TasksIndex from '../../tasks/Tasks';
import { useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { useNavigate } from 'react-router-dom';
import { MODULES, TASK_CREATE, TASK_READ } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import ProfessionalYearsLeftMenu from './ProfessionalYearsLeftMenu';
import AddButton from '../../tasks/AddButton';
import { fetchProfessionalYears } from '../../../../api/professional-year/fetchProfessionalYears';
import { ProfessionalYearState } from './state';

const ProfessionalYearTasks = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let professionalYearId = queryParams.get('professionalYearId');
  const encryptedProfessionalYearId = professionalYearId; // Decrypt the ID
  professionalYearId = decryptData(professionalYearId, true);
  const authToken = useAuthToken();
  const [currentStatus, setCurrentStatus] = useState<number>(1);
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const mainPath = `/professional-year/${encryptedSourceId}`;
  const title = 'Professional Year Tasks';
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id_local = localStorage.getItem('professional_year_unique_id');
  if (unique_id_local) {
    secondTitle = unique_id_local;
  }
  const secondpath = `/professional-year/details/${encryptedProfessionalYearId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, TASK_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchProfessionalYearData = async () => {
    try {
      if (professionalYearId && authToken) {
        const editId = parseInt(professionalYearId!, 10);

        const response: ProfessionalYearState = (await fetchProfessionalYears(
          authToken,
          selectedAccount,
          editId
        )) as ProfessionalYearState;

        if (response) {
          const { unique_id, is_open } = response;
          setCurrentStatus(is_open ? is_open : 0);
          localStorage.removeItem('professional_year_unique_id');
          localStorage.setItem('professional_year_unique_id', unique_id || '');
        }
      }
    } catch (error) {
      /* empty */
      console.log(error);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, MODULES, source_Id, TASK_CREATE) && (
        <AddButton
          addPath={`/professional-year/add-task?source=${encryptedSourceId}&professionalYearId=${encryptedProfessionalYearId}`}
        />
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                button={currentStatus ? addButton : null}
              />
              <div className="record-outer-row settings-outer-row">
                <ProfessionalYearsLeftMenu
                  user={user}
                  professionalYearId={professionalYearId ? professionalYearId : ''}
                  sourceId={sourceId ? sourceId : ''}
                  professionalYearIdEncrypted={encryptedProfessionalYearId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <TasksIndex
                  moduleType="professional_years"
                  sourceId={sourceId}
                  recordId={professionalYearId}
                  localKey="professional_year_unique_id"
                  currentStatus={currentStatus}
                  fetchModuleData={fetchProfessionalYearData}
                  editUrl={`/professional-year/edit-task/rowId?source=${encryptedSourceId}&professionalYearId=${encryptedProfessionalYearId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default ProfessionalYearTasks;
