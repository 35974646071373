import React from 'react';
import { useEffect } from 'react';
import '../styles/App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LOGIN, SESSION } from '../constants/page-types';
import { ImageProvider } from '../views/components/layout/ImageContext';
import { NotificationProvider } from '../views/components/layout/NotificationContext';
import Header from '../views/components/layout/Header/Header';
import Login from '../views/components/user/Login';
import ResetPasswordForm from '../views/components/user/ResetPasswordForm';
import ResetPasswordSuccess from '../views/components/user/ResetPasswordSuccess';
import ForgotPasswordForm from '../views/components/user/ForgotPasswordForm';
import ForgotPasswordSuccess from '../views/components/user/ForgotPasswordSuccess';
import Dashboard from '../views/components/dashboard/Dashboard';
import Error from '../views/components/layout/Error';
import UserAccountDetails from '../views/components/dashboard/UserAccountDetails';
import AccountResetPassword from '../views/components/dashboard/AccountResetPassword';
import UserSettings from '../views/components/settings/UserSettings';
import AuthCheck from './AuthCheck'; // Import the AuthCheck component
import AccountSelection from '../views/components/user/AccountSelection';
import AccountResetPasswordSuccess from '../views/components/dashboard/AccountResetPasswordSuccess';
import RecordCreateEdit from '../views/components/registers/RecordCreateEdit';
import RecordChart from '../views/components/registers/RecordChart';
import { RegistersProvider } from '../views/components/registers/RegisterContext';
import PageNotFound from '../views/components/layout/PageNotFound';
import SessionLogin from '../views/components/user/SessionLogin';
import NewUserPasswordForm from '../views/components/user/NewUserPasswordForm';
import AdviserRoutes from '../views/components/modules/adviser-review/AdviserRoutes';
import ReviewCreateEdit from '../views/components/modules/adviser-review/ReviewCreateEdit';
import ReviewIndex from '../views/components/modules/adviser-review/ReviewIndex';
import RecordRoutes from '../views/components/registers/RecordRoutes';
import FinaliseReviewTextPage from '../views/components/modules/adviser-review/FinaliseReviewTextPage';
import FinaliseReviewIndex from '../views/components/modules/adviser-review/FinaliseReviewIndex';
import { ModulesProvider } from '../views/components/settings/modules/ModulesContext';
import Calendar from '../views/components/modules/compliance-calendar/Calendar';
import CalendarCreateEdit from '../views/components/modules/compliance-calendar/CalendarCreateEdit';
import CalendarRoutes from '../views/components/modules/compliance-calendar/CalendarRoutes';
import Meetings from '../views/components/modules/meetings/Meetings';
import MeetingCreateEdit from '../views/components/modules/meetings/MeetingCreateEdit';
import MeetingRoutes from '../views/components/modules/meetings/MeetingRoutes';
import TasksAllIndex from '../views/components/reporting/TasksAllIndex';
import TasksMyIndex from '../views/components/reporting/TasksMyIndex';
import ProviderRoutes from '../views/components/modules/outsource-provider/ProviderRoutes';
import ProviderCreateEdit from '../views/components/modules/outsource-provider/ProviderCreateEdit';
import OnboardingRoutes from '../views/components/modules/onboarding/OnboardingRoutes';
import OnboardingCreateEdit from '../views/components/modules/onboarding/OnboardingCreateEdit';
import OnboardingLogin from '../views/components/modules/onboarding/auth/OnboardingLogin';
import OnboardingApplication from '../views/components/modules/onboarding/OnboardingApplication';
import OnboardingApplicationReview from '../views/components/modules/onboarding/OnboardingApplicationReview';
import CalendarSchedule from '../views/components/modules/compliance-calendar/CalendarSchedule';
import Notifications from '../views/components/resources/updates/Notifications';
import Update from '../views/components/resources/updates/Update';
import PrevetIndex from '../views/components/modules/pre-vet/PrevetIndex';
import PrevetRoutes from '../views/components/modules/pre-vet/PrevetRoutes';
import PrevetCreateEdit from '../views/components/modules/pre-vet/PrevetCreateEdit';
import FinalisePrevetTextPage from '../views/components/modules/pre-vet/FinalisePrevetTextPage';
import FinalisePrevetIndex from '../views/components/modules/pre-vet/FinalisePrevetIndex';
import Signup from '../views/components/user/Signup';
import SignupSuccess from '../views/components/user/SignupSuccess';
import Policies from '../views/components/resources/policies/Policies';
import LoginCheck from './LoginCheck';
import Logout from '../views/components/user/Logout';
import OnboardingApplicationSubmitted from '../views/components/modules/onboarding/OnboardingApplicationSubmitted';
import ComplianceQueryCreateEdit from '../views/components/resources/compliance-query/ComplianceQueryCreateEdit';
import SupportRequestCreateEdit from '../views/components/resources/support-request/SupportRequestCreateEdit';
import PrevetRequestCreate from '../views/components/modules/pre-vet/PrevetRequestCreate';
import AdviserProfiles from '../views/components/modules/adviser-profiles/AdviserProfiles';
import AdviserProfileRoutes from '../views/components/modules/adviser-profiles/AdviserProfileRoutes';
import Attestations from '../views/components/modules/attestations/Attestations';
import AttestationCreateEdit from '../views/components/modules/attestations/AttestationCreateEdit';
import AttestationRoutes from '../views/components/modules/attestations/AttestationRoutes';
import AttestationTemplateForm from '../views/components/modules/attestations/AttestationTemplateForm';
// import ProfessionalYears from '../views/components/modules/professional-years/ProfessionalYears';
import ProfessionalYearCreateEdit from '../views/components/modules/professional-years/ProfessionalYearCreateEdit';
import ProfessionalYearRoutes from '../views/components/modules/professional-years/ProfessionalYearRoutes';
import NewEntrantRoutes from '../views/components/modules/professional-years/new-entrant/NewEntrantRoutes';
import Upgrade from '../views/components/upgrade/Upgrade';
import SelectPlan from '../views/components/upgrade/SelectPlan';
import Success from '../views/components/upgrade/Success';
import MoreThanTwenty from '../views/components/upgrade/MoreThanTwenty';
import MoreThanTwentySent from '../views/components/upgrade/MoreThanTwentySent';
import Redirecting from '../views/components/common/Redirecting';
import ReportRoutes from '../views/components/reporting/ReportRoutes';

const App: React.FC = () => {
  useEffect(() => {
    // Check if the current window is not the top window
    if (window !== window.top) {
      document.body.innerHTML = '<p>This application cannot be embedded in an iframe.</p>';
    }
  }, []);
  return (
    <div className="wrapper">
      <Router>
        <Routes>
          <Route
            path={LOGIN}
            element={
              <LoginCheck>
                <Login />
              </LoginCheck>
            }
          />
          <Route
            path="/"
            element={
              <LoginCheck>
                <Login />
              </LoginCheck>
            }
          />
          <Route
            path="/signup"
            element={
              <LoginCheck>
                <Signup />
              </LoginCheck>
            }
          />
          <Route
            path="/signup-success"
            element={
              <LoginCheck>
                <SignupSuccess />
              </LoginCheck>
            }
          />
          <Route
            path="/reset-password"
            element={
              <LoginCheck>
                <ResetPasswordForm />
              </LoginCheck>
            }
          />
          <Route
            path="/reset-password-success"
            element={
              <LoginCheck>
                <ResetPasswordSuccess />
              </LoginCheck>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <LoginCheck>
                <ForgotPasswordForm />
              </LoginCheck>
            }
          />
          <Route
            path="/forgot-password-success"
            element={
              <LoginCheck>
                <ForgotPasswordSuccess />
              </LoginCheck>
            }
          />
          <Route
            path="/new-password"
            element={
              <LoginCheck>
                <NewUserPasswordForm />
              </LoginCheck>
            }
          />
          <Route path="/error" element={<Error />} />
          <Route
            path={SESSION}
            element={
              <LoginCheck>
                <SessionLogin />
              </LoginCheck>
            }
          />
          <Route
            path="/session-login"
            element={
              <LoginCheck>
                <SessionLogin />
              </LoginCheck>
            }
          />

          {/* Onboardings Module */}
          <Route
            path="/onboarding-login"
            element={
              <LoginCheck>
                <OnboardingLogin />
              </LoginCheck>
            }
          />

          {/* Use a route for the Header component */}
          <Route
            path="/*"
            element={
              <NotificationProvider>
                <ImageProvider>
                  <ModulesProvider>
                    <RegistersProvider>
                      <AuthCheck>
                        <Header />
                        {/* Add AuthCheck before protected routes */}
                        <Routes>
                          {/* Wrap routes that require authentication with ProtectedRoute */}
                          <Route path="/user-profile" element={<UserAccountDetails />} />
                          <Route path="/user-reset-password" element={<AccountResetPassword />} />
                          <Route
                            path="/account-password-success"
                            element={<AccountResetPasswordSuccess />}
                          />
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route path="/tasks" element={<TasksAllIndex />} />
                          <Route path="/my-tasks" element={<TasksMyIndex />} />
                          <Route path="/reports/*" element={<ReportRoutes />} />

                          <Route path="/register/*" element={<RecordRoutes />} />

                          <Route path="/register-record-add" element={<RecordCreateEdit />} />
                          <Route path="/register-record/:id/" element={<RecordCreateEdit />} />
                          <Route path="/register-form-edit/:id/" element={<RecordCreateEdit />} />
                          <Route
                            path="/register-snapshot-edit/:id"
                            element={<RecordCreateEdit />}
                          />
                          <Route path="/register-chart/:id" element={<RecordChart />} />

                          {/* Adviser Review Page Route */}
                          <Route path="/reviews/:id" element={<ReviewIndex />} />
                          <Route path="/review/*" element={<AdviserRoutes />} />
                          <Route path="/review-add" element={<ReviewCreateEdit />} />
                          <Route path="/review-form-edit/:id" element={<ReviewCreateEdit />} />
                          <Route path="/review-snapshot-edit/:id" element={<ReviewCreateEdit />} />
                          <Route path="/review-finalise/:id" element={<FinaliseReviewTextPage />} />
                          <Route
                            path="/review-finalise-list/:id"
                            element={<FinaliseReviewIndex />}
                          />

                          {/* Calendar Module */}
                          <Route path="/calendar/*" element={<CalendarRoutes />} />
                          <Route path="/compliance-calendar/:id" element={<Calendar />} />
                          <Route
                            path="/compliance-calendar-schedule/:id/"
                            element={<CalendarSchedule />}
                          />
                          <Route path="/calendar-event-add" element={<CalendarCreateEdit />} />
                          <Route path="/calendar-event-edit/:id" element={<CalendarCreateEdit />} />

                          {/* Meetings Module */}
                          <Route path="/meetings/:id" element={<Meetings />} />
                          <Route path="/meeting/*" element={<MeetingRoutes />} />
                          <Route path="/meeting-add" element={<MeetingCreateEdit />} />
                          <Route path="/meeting-edit/:id" element={<MeetingCreateEdit />} />

                          {/* Provider Router */}
                          <Route path="/providers/*" element={<ProviderRoutes />} />
                          <Route
                            path="/provider-snapshot-edit/:id"
                            element={<ProviderCreateEdit />}
                          />
                          {/* Provider Router */}

                          {/* Onboardings Module */}
                          <Route path="/onboarding/*" element={<OnboardingRoutes />} />
                          <Route path="/onboarding-add" element={<OnboardingCreateEdit />} />
                          <Route path="/onboarding-edit/:id" element={<OnboardingCreateEdit />} />

                          {/* Onboardings Routes for external user */}
                          <Route
                            path="/onboarding-application"
                            element={<OnboardingApplication />}
                          />
                          <Route
                            path="/onboarding-personal-details"
                            element={<OnboardingApplication />}
                          />
                          <Route
                            path="/onboarding-application-review/:id"
                            element={<OnboardingApplicationReview />}
                          />
                          <Route
                            path="/onboarding-application-submitted/:id"
                            element={<OnboardingApplicationSubmitted />}
                          />

                          {/* Notification/Update */}
                          <Route path="/resources/updates" element={<Notifications />} />
                          <Route path="/resources/update/:id" element={<Update />} />

                          {/* Pre-Vet Page Route */}
                          <Route path="/prevets/:id" element={<PrevetIndex />} />
                          <Route path="/prevet/*" element={<PrevetRoutes />} />
                          <Route path="/prevet-add" element={<PrevetCreateEdit />} />
                          <Route path="/prevet-form-edit/:id" element={<PrevetCreateEdit />} />
                          <Route path="/prevet-snapshot-edit/:id" element={<PrevetCreateEdit />} />
                          <Route path="/prevet-finalise/:id" element={<FinalisePrevetTextPage />} />
                          <Route
                            path="/prevet-finalise-list/:id"
                            element={<FinalisePrevetIndex />}
                          />
                          <Route path="/prevets/request" element={<PrevetRequestCreate />} />
                          <Route path="/prevets/request-sent" element={<PrevetRequestCreate />} />

                          {/* Policies */}
                          <Route path="/resources/policies" element={<Policies />} />

                          {/* Compliance Query */}
                          <Route
                            path="/resources/compliance-query"
                            element={<ComplianceQueryCreateEdit />}
                          />
                          <Route
                            path="/resources/compliance-query-sent"
                            element={<ComplianceQueryCreateEdit />}
                          />
                          {/* Technical Support Request */}

                          <Route
                            path="/resources/support-request"
                            element={<SupportRequestCreateEdit />}
                          />
                          <Route
                            path="/resources/support-request-sent"
                            element={<SupportRequestCreateEdit />}
                          />

                          {/* Adviser Profile Route */}
                          <Route path="/adviser-profiles/:id" element={<AdviserProfiles />} />
                          <Route path="/adviser-profile/*" element={<AdviserProfileRoutes />} />

                          {/* Attestations Route */}
                          <Route path="/attestations/:id" element={<Attestations />} />
                          <Route path="/attestation/*" element={<AttestationRoutes />} />
                          <Route path="/attestation-add" element={<AttestationCreateEdit />} />
                          <Route path="/attestation-edit/:id" element={<AttestationCreateEdit />} />
                          <Route path="/attestation-form" element={<AttestationTemplateForm />} />
                          <Route
                            path="/attestation-form-review"
                            element={<AttestationTemplateForm />}
                          />
                          {/* Professional Year Route */}
                          {/* <Route path="/professional-year/:id" element={<ProfessionalYears />} /> */}
                          <Route path="/new-entrant/*" element={<NewEntrantRoutes />} />
                          <Route path="/professional-year/*" element={<ProfessionalYearRoutes />} />

                          <Route
                            path="/professional-year-add"
                            element={<ProfessionalYearCreateEdit />}
                          />
                          <Route
                            path="/professional-year-edit/:id"
                            element={<ProfessionalYearCreateEdit />}
                          />

                          <Route path="/settings/*" element={<UserSettings />} />

                          {/* Upgrade */}
                          <Route path="/upgrade" element={<Upgrade />} />

                          <Route path="/logout" element={<Logout />} />

                          {/* upgrade */}
                          <Route path="/upgrade" element={<Upgrade />} />
                          <Route path="/select-plan" element={<SelectPlan />} />
                          <Route path="/more-than-20" element={<MoreThanTwenty />} />
                          <Route path="/more-than-20-sent" element={<MoreThanTwentySent />} />
                          <Route path="/success" element={<Success />} />

                          <Route path="/redirecting" element={<Redirecting />} />
                        </Routes>
                      </AuthCheck>
                    </RegistersProvider>
                  </ModulesProvider>
                </ImageProvider>
              </NotificationProvider>
            }
          />
          <Route path="/account-selection" element={<AccountSelection />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/404" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
