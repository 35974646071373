import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { getSelectedAccountInfo, useAuthToken } from '../../../../utils/common/user';
import { PrevetsReportDataState } from '../../user/state';
import { CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';
import { fetchPrevets } from '../../../../api/reporting/fetchReportingPrevets';

const PrevetsReportIndex = () => {
  const title = 'Prevets';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainPath = '/dashboard';
  const secondTitle = 'Reports';
  const secondpath = '/dashboard';
  const pageTitle = title;
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<PrevetsReportDataState>();

  const fetchData = async () => {
    setLoading(true);

    try {
      if (authToken) {
        const response: PrevetsReportDataState = (await fetchPrevets(
          authToken,
          selectedAccount
        )) as PrevetsReportDataState;
        if (response) {
          setChartData(response);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={secondpath}
          />
          {!loading &&
          !chartData?.finalisedPrevets &&
          !chartData?.reviewsFinalisedDays &&
          !chartData?.clientResponsesWithNo &&
          !chartData?.clientResponsesWithYesWithLimitation &&
          !chartData?.AdvisersByLimitation ? (
            <div className="no-record">No Data found.</div>
          ) : chartData ? (
            <>
              <div className="google-chart">
                <div className="report-chart margin-chart">
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={chartData.finalisedPrevets}
                    options={{
                      title: 'Pre-vets Finalised by Month (last 12 months)',
                      pointSize: 5,
                      tooltip: { isHtml: true },
                      legend: { position: 'none' },
                      colors: ['#1DA2EF'],
                      chartArea: {
                        top: 40, // Increase top margin to provide more space for y-axis labels
                        bottom: 60, // Increase bottom margin to provide more space for x-axis labels
                        left: 50,
                        right: 50
                      }
                    }}
                  />
                </div>
                <div className="report-chart">
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={chartData.reviewsFinalisedDays}
                    options={{
                      title: 'Pre-vet Time to Finalise (last 12 months)',
                      tooltip: { isHtml: true }, // CSS styling affects only HTML tooltips.
                      legend: { position: 'none' },
                      colors: ['#1DA2EF'],
                      chartArea: {
                        top: 40, // Increase top margin to provide more space for y-axis labels
                        bottom: 60, // Increase bottom margin to provide more space for x-axis labels
                        left: 50,
                        right: 50
                      }
                    }}
                  />
                </div>
              </div>
              <div className="google-chart">
                <div className="report-chart margin-chart margin-google-chart">
                  <h3>Top 10 ‘No’ Responses from Pre-vetting (last 12 months)</h3>
                  {chartData.clientResponsesWithNo.length > 1 ? (
                    <Chart
                      chartType="Table"
                      data={chartData.clientResponsesWithNo}
                      options={{
                        showRowNumber: true,
                        sort: 'enable'
                      }}
                    />
                  ) : (
                    <div className="no-record">No Data found.</div>
                  )}
                </div>
              </div>
              <div className="google-chart">
                <div className="report-chart margin-chart margin-google-chart">
                  <h3>Top 10 ‘Yes with limitations’ Responses from Pre-vetting (last 12 months)</h3>
                  {chartData.clientResponsesWithYesWithLimitation.length > 1 ? (
                    <Chart
                      chartType="Table"
                      data={chartData.clientResponsesWithYesWithLimitation}
                      options={{
                        showRowNumber: true,
                        sort: 'enable'
                      }}
                    />
                  ) : (
                    <div className="no-record">No Data found.</div>
                  )}
                </div>
              </div>
              {/* <div className="google-chart">
                <div className="report-chart margin-chart margin-google-chart">
                  {chartData.AdvisersByLimitation.length > 1 ? (
                    <Chart
                      chartType="BarChart"
                      width="100%"
                      height="400px"
                      data={chartData.AdvisersByLimitation}
                      options={{
                        title:
                          'Top 10 Advisers with No and Yes with limitations responses (last 12 months)',
                        chartArea: { width: '50%' },
                        hAxis: {
                          title: 'Count',
                          minValue: 0
                        },
                        vAxis: {
                          title: 'Adviser Name'
                        },
                        bars: 'horizontal'
                      }}
                    />
                  ) : (
                    <div className="no-record">No Data found.</div>
                  )}
                </div>
              </div> */}
            </>
          ) : null}

          {loading && (
            <div className="no-record">
              <CircularProgress />
            </div>
          )}
        </div>
      </section>
    </CommonLayout>
  );
};

export default PrevetsReportIndex;
