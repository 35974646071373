import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdviserReviewReportIndex from './adviser-reviews/AdviserReviewReportIndex';
import PrevetsReportIndex from './prevets/PrevetsReportIndex';
import IncidentsBreachesReportIndex from './incidents-breaches/IncidentsBreachesReportIndex';
import ComplaintsReportIndex from './complaints/ComplaintsReportIndex';
import ExportDataIndex from './export-data/ExportData';

const ReportRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/adviser-reviews" element={<AdviserReviewReportIndex />} />
      <Route path="/prevets" element={<PrevetsReportIndex />} />
      <Route path="/incidents-breaches" element={<IncidentsBreachesReportIndex />} />
      <Route path="/complaints" element={<ComplaintsReportIndex />} />
      <Route path="/export-data" element={<ExportDataIndex />} />
    </Routes>
  );
};

export default ReportRoutes;
