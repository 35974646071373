import { ApiResponse } from '../apiResponse';
import { AccountState } from '../../views/components/user/state';
import { format } from 'date-fns';

export const exportData = async (
  authToken?: string,
  accountInfo?: AccountState,
  menuId?: string | number,
  fromDate?: string,
  toDate?: string
) => {
  try {
    const selectedAccountId = accountInfo?.id;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/export-data?account_id=${selectedAccountId}&menu_id=${menuId}&from_date=${fromDate}&to_date=${toDate}`, // Update the API endpoint as needed
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    const extension = 'xlsx';
    const blob = await response.blob();

    const url = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement('a');
    a.href = url;
    a.download =
      accountInfo?.name.replace(/\s/g, '') +
      '-' +
      format(new Date(), 'ddMMyyyy-hhmm') +
      `.${extension}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    return response;
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching data';
  }
};
