import React from 'react';
import { format } from 'date-fns';
import { PresignedUrl } from './surveyFilePresignedUrl';

// Define TypeScript types or interfaces for surveyData and formData
export interface Choices {
  id?: number;
  value: string;
  name?: string;
  type?: string;
  text?: string;
  imageLink?: string;
}

export type ChoicesByUrl = {
  titleName: string;
  url: string;
  valueName?: string;
};

export interface Items {
  name: string;
  title?: string;
}

export interface Element {
  type: any;
  name: string;
  title: string;
  inputType?: string;
  choices?: Choices[];
  choicesByUrl?: ChoicesByUrl;
  startWithNewLine?: boolean;
  items?: Items[];
  html?: string;
}

export interface SurveyData {
  title: string;
  description: string;
  pages: {
    name: string;
    elements: Element[];
    title: string;
    description: string;
  }[];
}

export interface FormData {
  [key: string]: string | string[];
}

export interface TemplateTextModeProps {
  surveyData: SurveyData;
  formData: FormData;
  edit_btn?: JSX.Element | null;
  title?: string;
  emptyText?: string;
  loading?: boolean;
}

export const fetchDynamicChoicesData = async (
  authToken: string,
  surveyData: SurveyData,
  formData: FormData,
  setDynamicChoicesData: React.Dispatch<React.SetStateAction<{ [key: string]: Choices[] }>>
) => {
  const choicesByUrlElements = surveyData?.pages
    ?.flatMap((page) => page.elements)
    .filter(
      (element) =>
        (element.type === 'dropdown' || element.type === 'tagbox') && element.choicesByUrl
    );

  if (choicesByUrlElements) {
    try {
      const fetchPromises = choicesByUrlElements.map(async (element) => {
        const choicesByUrlArray = element.choicesByUrl;
        if (choicesByUrlArray) {
          let url = choicesByUrlArray.url;

          // Replace placeholders like {complaint_category} with actual values from formData
          Object.keys(formData).forEach((key) => {
            const placeholder = `{${key}}`;
            if (url.includes(placeholder)) {
              url = url.replace(placeholder, formData[key] as string);
            }
          });

          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          });
          const data = await response.json();
          setDynamicChoicesData((prevData) => ({
            ...prevData,
            [element.name]: data
          }));
        }
      });

      await Promise.all(fetchPromises);
    } catch (error) {
      console.error('Error fetching dynamic choices data:', error);
    }
  }
};

export const renderFormElement = (
  element: Element,
  value: string | string[] | boolean,
  authToken: string,
  dynamicChoicesData: { [key: string]: Choices[] } = {}
) => {
  let content; // Declare variable outside the switch block
  switch (element.type) {
    case 'dropdown':
      {
        const choices = dynamicChoicesData[element.name] || element.choices || [];
        let selectedChoice: any = '';
        if (dynamicChoicesData[element.name]) {
          selectedChoice = choices.find((choice) => {
            return choice.id && choice.id.toString() == value;
          });
          content = (
            <div className="dropdown-container">
              <p className="selected-choice-text">{selectedChoice ? selectedChoice.name : value}</p>
            </div>
          );
        } else {
          selectedChoice = choices.find((choice) => choice.value === value);

          content = (
            <div className="dropdown-container">
              <p className="selected-choice-text">{selectedChoice ? selectedChoice.text : value}</p>
            </div>
          );
        }
      }
      break;
    case 'tagbox':
      {
        const choices = dynamicChoicesData[element.name] || element.choices || [];
        const selectedValues = Array.isArray(value) ? value : [value];

        const selectedTexts = selectedValues.map((selectedValue) => {
          const selectedChoice = choices.find((choice) => {
            return choice.id?.toString() === selectedValue.toString();
          });
          return selectedChoice ? selectedChoice.text || selectedChoice.name : selectedValue;
        });

        content =
          selectedTexts.length > 0 ? (
            <div className="tagbox-container">
              <p className="selected-tagbox-item">{selectedTexts.join(', ')}</p>
            </div>
          ) : (
            <p className="form-control">{Array.isArray(value) ? value.join(', ') : value}</p>
          );
      }
      break;
    case 'checkbox':
      {
        const choices = element.choices || [];
        const selectedValues = Array.isArray(value) ? value : [value];

        const selectedTexts = choices
          .filter((choice) => selectedValues.includes(choice.value))
          .map((choice) => choice.text);

        content = (
          <div className="tagbox-container">
            {selectedTexts.map((text, index) => (
              <p key={index} className="selected-tagbox-item">
                {text}
              </p>
            ))}
          </div>
        );
      }
      break;
    case 'ranking':
      {
        const choices = element.choices || [];
        const selectedValues = Array.isArray(value) ? value : [value];

        content = (
          <div className="ranking-container">
            {selectedValues.map((selectedValue, index) => {
              const selectedChoice = choices.find((choice) => choice.value === selectedValue);
              const text = selectedChoice ? selectedChoice.text : '';

              return (
                <div key={index} className="ranking-item">
                  <p className="selected-ranking-text">{text}</p>
                  {/* You can add additional styling or elements for each ranking item */}
                </div>
              );
            })}
          </div>
        );
      }
      break;
    case 'boolean':
      {
        if (element.name === 'ComplaintStatus') {
          content = (
            <p className="form-control">{value == '2' ? 'Yes' : 'No'}</p>
          );
        } else {
          content = (
            <p className="form-control">{value == true || value === 'Open' ? 'Yes' : 'No'}</p>
          );
        }
      }
      break;
    case 'file':
      {
        content =
          Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' ? (
            <>
              {value.map((file: any, index: number) => (
                <p
                  onClick={() => PresignedUrl(file.content, authToken)}
                  className="form-control edit-task-arrow-home file"
                  key={index}>
                  {file.name}
                </p>
              ))}
            </>
          ) : (
            <p className="form-control">{value}</p>
          );
      }
      break;
    case 'signaturepad':
      {
        content = <img src={value as string} alt="Signature" />;
      }
      break;
    case 'imagepicker':
      {
        const choices = element.choices || [];
        const selectedImages = Array.isArray(value) ? value : [value];
        content = (
          <div className="image-picker-container">
            {choices.map((choice, index) => (
              <div
                key={index}
                className={`image-picker-item ${
                  selectedImages.includes(choice.value) ? 'selected' : ''
                }`}>
                {selectedImages.includes(choice.value) && (
                  <>
                    <img src={choice?.imageLink} alt={choice.value} />
                    <p>{choice.value}</p>
                  </>
                )}
              </div>
            ))}
          </div>
        );
      }

      break;
    case 'multipletext':
      {
        const items = element.items || [];

        content = (
          <div className="multiple-text-container">
            {items.map((item, index) => {
              const itemName: string = item.name;
              const itemTitle = item.title;

              const itemValue =
                itemName && typeof value === 'object' && value !== null
                  ? String(value[itemName as keyof typeof value])
                  : String(value);

              return (
                <div key={index} className="">
                  <p className="item-title">{itemTitle}</p>
                  <p className="item-value">{itemValue}</p>
                </div>
              );
            })}
          </div>
        );
      }
      break;

    case 'matrix':
      {
        content = <div className="matrix"></div>;
      }
      break;
    case 'html':
      {
        const htmlContent = element.html || '';
        content = (
          <div className="form-control" dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
      }
      break;
    default:
      {
        if (element.inputType && element.inputType === 'date') {
          let dateValue: Date;

          // Convert 'string' or 'number' to 'Date' if possible
          if (typeof value === 'string' || typeof value === 'number') {
            dateValue = new Date(value);
          } else if (value instanceof Date) {
            dateValue = value;
          } else {
            // Handle other cases or throw an error based on your requirements
            throw new Error('Invalid date format');
          }

          content = <p className="form-control">{format(dateValue, 'd/MMM/yyyy')}</p>;
        } else {
          let filteredValue = value;
          if (typeof filteredValue === 'string') {
            // check for \r and \n in value and replace it with <br> to render it in new line
            filteredValue = filteredValue.replace(/(?:\r\n|\r|\n)/g, '<br>');
          }

          // If it's not a Date, string, or number, render 'value' as is
          if (typeof filteredValue === 'string') {
            content = (
              <p className="form-control" dangerouslySetInnerHTML={{ __html: filteredValue }} />
            );
          } else {
            content = <p className="form-control">{filteredValue}</p>;
          }
        }
      }
      break;
  }

  return content;
};
