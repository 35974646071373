import React, { useState, useEffect } from 'react';
import TasksIndex from '../../tasks/Tasks';
import { useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { useNavigate } from 'react-router-dom';
import { MODULES, TASK_CREATE, TASK_READ } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import AttestationLeftMenu from './AttestationLeftMenu';
import AddButton from '../../tasks/AddButton';
import { fetchAttestations } from '../../../../api/attestations/fetchAttestations';
import { AttestationState } from './state';

const AttestationTasks = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let attestationId = queryParams.get('attestationId');
  const encryptedAttestationId = attestationId; // Decrypt the ID
  attestationId = decryptData(attestationId, true);
  const authToken = useAuthToken();
  const [currentStatus, setCurrentStatus] = useState<number>(1);
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const mainPath = `/attestations/${encryptedSourceId}`;
  const title = 'Attestation Tasks';
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id_local = localStorage.getItem('attestation_unique_id');
  if (unique_id_local) {
    secondTitle = unique_id_local;
  }
  const secondpath = `/attestation/${encryptedAttestationId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, TASK_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchAttestationData = async () => {
    try {
      if (attestationId && authToken) {
        const editId = parseInt(attestationId!, 10);

        const response: AttestationState = (await fetchAttestations(
          authToken,
          selectedAccount,
          editId
        )) as AttestationState;

        if (response) {
          const { unique_id, is_open } = response;
          setCurrentStatus(is_open ? is_open : 0);
          localStorage.removeItem('attestation_unique_id');
          localStorage.setItem('attestation_unique_id', unique_id || '');
        }
      }
    } catch (error) {
      /* empty */
      console.log(error);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, MODULES, source_Id, TASK_CREATE) && (
        <AddButton
          addPath={`/attestation/add-task?source=${encryptedSourceId}&attestationId=${encryptedAttestationId}`}
        />
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                button={currentStatus ? addButton : null}
              />
              <div className="record-outer-row settings-outer-row">
                <AttestationLeftMenu
                  user={user}
                  attestationId={attestationId ? attestationId : ''}
                  sourceId={sourceId ? sourceId : ''}
                  attestationIdEncrypted={encryptedAttestationId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <TasksIndex
                  moduleType="attestations"
                  sourceId={sourceId}
                  recordId={attestationId}
                  localKey="attestation_unique_id"
                  currentStatus={currentStatus}
                  fetchModuleData={fetchAttestationData}
                  editUrl={`/attestation/edit-task/rowId?source=${encryptedSourceId}&attestationId=${encryptedAttestationId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default AttestationTasks;
