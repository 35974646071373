import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { TextField } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import ReviewLeftMenu from './ReviewLeftMenu';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import { useNotificationContext } from '../../layout/NotificationContext';
import { createFileReview } from '../../../../api/adviser/createFileReview';
import CommonLayout from '../../layout/CommonLayout';
import { fetchFileReviewTemplates } from '../../../../api/adviser/fetchFileReviewTemplates';
import { FileReviewTemplatesState, FileReviewState } from '../../user/state';
import { fetchFileReviews } from '../../../../api/adviser/fetchFileReviews';
import { FileReviewFormState } from './state';
import { FileReviewResponseError } from './state';
import * as SurveyCore from 'survey-core';
import { MODULES, UPDATE } from '../../../../constants/constants';
import { uploadSurveyFile } from '../../../../api/survey/uploadSurveyFile';
import edit from '../../../../assets/images/edit.svg';
import { updateClientFileName } from '../../../../api/adviser/updateClientFileName';

const FileReviewTemplateEdit: React.FC = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  id = decryptData(id, true);
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let reviewId = queryParams.get('reviewId');
  const encryptedReviewId = reviewId; // Decrypt the ID
  reviewId = decryptData(reviewId, true);
  const review_id = parseInt(reviewId!, 10);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const title = 'Edit File Review';
  const edit_id = parseInt(id!, 10);
  const { t } = useTranslation();
  const { setNotificationSuccess, notificationSuccess } = useNotificationContext();
  const mainPath = `/reviews/${encryptedSourceId}`;
  const pageTitle = 'Client File Review';
  const [loading, setLoading] = useState<boolean>(true);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [templateData, setTemplateData] = useState<string>('');
  const [survey, setSurvey] = useState<any>('');
  const [formData, setFormData] = useState<FileReviewFormState>({
    id: 0,
    review_id: review_id,
    file_review_template_id: 0,
    client: '',
    is_complete: false,
    error: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [clientName, setClientName] = useState(formData.client);

  const [clientNameError, setClientNameError] = useState<string | null>(null);

  let thirdTitle;
  if (formData && formData.review_data?.unique_id) {
    thirdTitle = formData.review_data?.unique_id;
  }
  const thirdPath = `/review/${encryptedReviewId}?source=${encryptedSourceId}`;
  const secondTitle = 'Adviser Reviews';

  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  useEffect(() => {
    // for check this page permission
    if (
      subscriptonExpired ||
      !checkUserPermission(user, MODULES, parseInt(sourceId!, 10), UPDATE)
    ) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  useEffect(() => {
    if (
      Object.keys(templateData).length !== 0 &&
      Object.keys(templateData).length > 1 &&
      formData?.filereview_data &&
      Object.keys(formData?.filereview_data?.form_data).length !== 0
    ) {
      const mergedResponse = mergeFieldValues(
        JSON.parse(templateData),
        JSON.parse(formData.filereview_data.form_data)
      );
      localStorage.removeItem('survey-json-file-review-temp-render');
      localStorage.setItem('survey-json-file-review-temp-render', JSON.stringify(mergedResponse));
    } else if (Object.keys(templateData).length !== 0 && Object.keys(templateData).length > 1) {
      localStorage.removeItem('survey-json-file-review-temp-render');
      localStorage.setItem('survey-json-file-review-temp-render', templateData);
    }

    if (!hasEffectRun && !notificationSuccess) {
      SurveyCore.ChoicesRestfull.clearCache();

      fetchTemplate();
      fetchData();
      setTimeout(() => {
        setHasEffectRun(true);
        setLoading(false);
      }, 1000);
    }

    setSurveyData();
  }, [templateData, formData, hasEffectRun, current_route, notificationSuccess]);

  const fetchTemplate = async () => {
    try {
      if (id && authToken) {
        const response: FileReviewTemplatesState = (await fetchFileReviewTemplates(
          authToken,
          selectedAccount,
          0,
          0,
          edit_id
        )) as FileReviewTemplatesState;

        if (response) {
          const { form_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
          } else {
            localStorage.removeItem('survey-json-file-review-temp-render');
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.notemplateFoundReview')
            }));
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    try {
      if (authToken && id) {
        const reviewId = parseInt(id, 10);
        const response: FileReviewState = (await fetchFileReviews(
          authToken,
          reviewId,
          edit_id,
          0,
          0
        )) as FileReviewState;
        if (response) {
          const {
            id,
            review_id,
            client,
            file_review_template_id,
            is_complete,
            filereview_data,
            review_data
          } = response;

          const newFormData = {
            id,
            review_id,
            client,
            file_review_template_id,
            is_complete: is_complete ? true : false,
            filereview_data: {
              id: filereview_data ? filereview_data.id : 0,
              file_review_id: filereview_data ? filereview_data.file_review_id : 0,
              form_data: filereview_data ? filereview_data.form_data : ''
            },
            review_data: {
              id: review_data ? review_data.id : 0,
              unique_id: review_data ? review_data.unique_id : '',
              adviser: {
                id: review_data?.adviser ? review_data?.adviser.id : 0,
                email: review_data?.adviser ? review_data?.adviser.email : '',
                first_name: review_data?.adviser ? review_data?.adviser.first_name : '',
                last_name: review_data?.adviser ? review_data?.adviser.last_name : ''
              }
            }
          };
          setFormData(newFormData);
          setClientName(client);
          if (newFormData.is_complete) {
            navigate(`/review/${encryptedReviewId}?source=${encryptedSourceId}`);
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const setSurveyData = () => {
    const surveyJson = localStorage.getItem('survey-json-file-review-temp-render') || ''; // Provide an empty string as the default

    if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
      const surveyModel = new Model(surveyJson);
      surveyModel.showCompletedPage = false; // prevent the thank-you page
      surveyModel.showNavigationButtons = true; // Hide the default navigation buttons
      surveyModel.isSinglePage = false; // show all pages in single
      surveyModel.pageNextText = 'Save and next';
      surveyModel.completeText = 'Save';
      surveyModel.focusFirstQuestionAutomatic = true;

      setSurvey(surveyModel);
    }
  };

  const filteredElements: any[] = [];
  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      let number: any;
      page.elements.forEach((element: any) => {
        const fieldName = element.name;
        if (fieldValues[fieldName] !== undefined || fieldName.trim().includes('guidance-')) {
          // Add the value to the element
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const unusedVariable =
            fieldValues[fieldName] !== undefined
              ? (element.defaultValue = fieldValues[fieldName])
              : null;

          // Check if 'choices' array is present and not empty
          if (element.choices && element.choices.length > 0) {
            // Convert defaultValue to an array if it's not already
            const defaultValues = Array.isArray(element.defaultValue)
              ? element.defaultValue
              : [element.defaultValue];

            // Find the choices whose values are present in defaultValues
            const selectedChoices = element.choices.filter((choice: any) => {
              if (choice && choice.value) {
                return defaultValues.includes(choice.value);
              } else {
                return defaultValues.includes(choice);
              }
            });

            // Now you can use selectedChoices as needed
            selectedChoices.forEach((selectedChoice: any) => {
              // Your logic here
              const selectedText =
                selectedChoice && selectedChoice.text ? selectedChoice.text : selectedChoice;
              if (
                fieldName &&
                fieldName.trim().includes('check-') &&
                (selectedText.trim().toLowerCase() === 'yes with limitations' ||
                  selectedText.trim().toLowerCase() === 'no')
              ) {
                const match = fieldName.trim().match(/check-(\d+[A-Za-z]?)/);
                number = match ? match[1] : null;
                const existingIndex = filteredElements.findIndex(
                  (existingElement) => existingElement.name === element.name
                );
  
                if (existingIndex !== -1) {
                  // Update the existing element
                  filteredElements[existingIndex].answer = selectedText.trim();
                } else {
                  // Add the new element
                  element.answer = selectedText.trim();
                  filteredElements.push(element);
                }
              } else {
                const index = filteredElements.findIndex(
                  (existingElement) => existingElement.name === element.name
                );

                if (index !== -1) {
                  filteredElements.splice(index, 1);
                }
              }
            });
          }

          if (number) {
            const regex = new RegExp(`^check-${number}(?:\\s*-\\s*.*|\\s+.*|\\s*$|$)`, 'i');
            const index = filteredElements.findIndex((element) => regex.test(element.name));

            if (element.type === 'html' && fieldName.trim().includes(`guidance-${number}`)) {
              if (index !== -1) {
                filteredElements[index].guidance = filteredElements[index].guidance || [];
                filteredElements[index].guidance.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              (fieldName.trim().toLowerCase().includes(`comment-${number}`) ||
                fieldName.trim().includes(`comment-${number}`))
            ) {
              if (index !== -1) {
                filteredElements[index].comments = filteredElements[index].comments || [];
                filteredElements[index].comments.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              (fieldName.trim().toLowerCase().includes(`action-${number}`) ||
                fieldName.trim().includes(`action-${number}`))
            ) {
              if (index !== -1) {
                filteredElements[index].action = filteredElements[index].action || [];
                filteredElements[index].action.push({ element });
              }
            } else if (
              element.type === 'dropdown' &&
              (fieldName.trim().toLowerCase().includes(`rating-${number}`) ||
                fieldName.trim().includes(`rating-${number}`))
            ) {
              if (index !== -1) {
                filteredElements[index].rating = filteredElements[index].rating || [];
                filteredElements[index].rating.push({ element });
              }
            }
          }
        }
      });
    });
    return mainResponse;
  };

  useEffect(() => {
    if (survey && survey.data) {
      // Dynamic handling for signature pad questions
      survey.onValueChanged.add((sender: any) => {
        survey.getAllQuestions().forEach(function (question: any) {
          if (question.jsonObj.type === 'signaturepad') {
            const visibleIfCondition = question.visibleIf;
            if (visibleIfCondition) {
              // Evaluate the visibleIf condition
              const dependentQuestionName = visibleIfCondition.split(' ')[0].replace(/[{}]/g, '');
              const dependentQuestionValue = visibleIfCondition.split(' ')[2].replace(/[{}]/g, '');

              // Find the dependent question by name
              const dependentQuestion = sender.getQuestionByName(dependentQuestionName);

              if (dependentQuestion) {
                // Check the value of the dependent question
                const isConditionMet =
                  dependentQuestion.value.toString() === dependentQuestionValue.toString();

                question.visible = isConditionMet;
                // Clear signature pad if not visible
                if (!isConditionMet) {
                  question.canvas = '';
                }
              }
            }
          }
        });
      });

      survey.onUploadFiles.add((_: any, options: any) => {
        try {
          uploadSurveyFile(options.files, authToken)
            .then((response) => {
              const data: any = response;
              setTimeout(() => {
                options.callback(
                  options.files.map((file: any, index: number) => {
                    return {
                      file: file,
                      content: data[index]
                    };
                  })
                );
              }, 2000);
            })
            .catch((error) => {
              // Handle other error cases
              console.error(error); // Log the error
              options.callback([], ['An error occurred during file upload.']);
            });
        } catch (error) {
          // Handle other error cases
          console.error(error); // Log the error
          options.callback([], ['An error occurred during file upload.']);
        }
      });

      // Save survey results when click on next
      survey.onCurrentPageChanged.add(() => {
        handleSurveySubmit('nextPage');
      });

      survey.onComplete.add(() => {
        handleSurveySubmit();
      });
    }
  }, [survey]);

  const handleSurveySubmit = async (type = '', status?: boolean) => {
    let validationResult = true;
    if (templateData && !type) {
      validationResult = survey.validate();
    }
    if (!validationResult) {
      return;
    }
    if (validationResult && survey) {
      survey.getAllQuestions().forEach(function (question: any) {
        if (!question.isVisible && question.defaultValue) {
          const enableCondition = question.setValueIf;
          const shouldBeEnabled = survey.runCondition(enableCondition, survey.data);
          if (shouldBeEnabled) {
            survey.setValue(question.name, question.setValueExpression);
          } else {
            survey.setValue(question.name, question.defaultValue); // or any other value you consider as default when the condition is not met
          }
        }
      });

      const surveyData = survey.data;
      surveyData.pageNo = survey.currentPageNo;

      mergeFieldValues(JSON.parse(templateData), surveyData);

      const requestData = {
        form_data: surveyData,
        review_id: formData.review_id,
        client: formData.client,
        is_complete: status ? status : formData.is_complete,
        newStaticVariable: false,
        filteredElements: filteredElements
      };
      // if (!type) {
      //   setPageShow(false);
      // }
      const response: FileReviewResponseError = (await createFileReview(
        requestData,
        authToken,
        selectedAccount,
        edit_id,
        filteredElements
      )) as FileReviewResponseError;
      if (response) {
        if (!type) {
          localStorage.removeItem('survey-json-file-review-temp-render');
          const msg = t('success.field.reviewUpdated');
          setNotificationSuccess(msg);
          setHasEffectRun(false);
          navigate(`/review/${encryptedReviewId}?source=${encryptedSourceId}`);
        }
      }
    }
  };

  const handleExit = () => {
    if (reviewId) {
      navigate(`/review/${encryptedReviewId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/reviews/${encryptedSourceId}`);
    }
  };

  const handleComplete = () => {
    handleSurveySubmit('', true);
    // handleSurveySubmit(true);
  };

  const handleNameEditClick = () => {
    setIsEditing(true);
  };

  const handleNameSaveClick = async () => {
    if (!clientName || !clientName.trim()) {
      setClientNameError('Client name is required');
      return;
    }
    setClientNameError(null);
    // Update the client name in the formData and send the update request
    const updatedFormData = { ...formData, client: clientName };
    setFormData(updatedFormData);

    // Send the updated client name to the server
    try {
      const response = await updateClientFileName(
        authToken,
        selectedAccount,
        review_id,
        clientName
      );
      if (response) {
        // Handle successful update
        setNotificationSuccess('Client name updated successfully');
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error updating client name:', error);
    }
  };

  const handleNameCancelClick = () => {
    setClientName(formData.client); // Reset the input value to the original client name
    setIsEditing(false);
  };

  const editButton = (
    <div className={`inner-btn-wrap ${notificationSuccess == '' ? 'corner-exit-mark' : ''}`}>
      <React.Fragment>
        <Button className="btn white-btn" onClick={handleExit}>
          <i className="left">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
          Exit
        </Button>
        <Button className="btn white-btn" onClick={() => handleComplete()}>
          <i className="left">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clipPath="url(#clip0_10218_12413)">
                <path
                  d="M19.784 6.58183C19.6055 6.37094 19.3433 6.25 19.0649 6.25H0.935092C0.656852 6.25 0.39494 6.37094 0.216426 6.5816C0.0498255 6.77828 -0.0254473 7.03413 0.00763839 7.28816L1.07794 18.4578C1.07888 18.4664 1.07982 18.475 1.08099 18.4834C1.17513 19.1881 1.77356 19.7194 2.47313 19.7194H17.5269C18.2624 19.7194 18.8757 19.1561 18.9221 18.4578L19.9924 7.28839C20.0254 7.03441 19.9504 6.77851 19.784 6.58183ZM17.5306 18.3226C17.5299 18.3215 17.5264 18.3208 17.5269 18.3212L2.48547 18.3233C2.47942 18.3205 2.47055 18.3101 2.46778 18.3019L1.44657 7.64843H18.5534L17.5306 18.3226Z"
                  fill="#667085"
                />
                <path
                  d="M18.2334 3.56308C18.0563 3.35918 17.7988 3.24219 17.5266 3.24219H2.4962C2.22425 3.24219 1.96558 3.36359 1.7864 3.57519C1.60628 3.7882 1.52843 4.0655 1.57226 4.32718L1.99171 7.05364L3.37381 6.8411L3.03522 4.64038H16.9879L16.6496 6.8411L18.0314 7.05364L18.4518 4.31925C18.4909 4.04386 18.4115 3.76816 18.2334 3.56308Z"
                  fill="#667085"
                />
                <path
                  d="M16.1604 0.602615C15.9833 0.398476 15.7256 0.28125 15.4529 0.28125H4.57043C4.29848 0.28125 4.03981 0.402655 3.86063 0.614255C3.68028 0.827261 3.60266 1.10456 3.64673 1.36812L4.06618 4.04798L5.44757 3.83173L5.11062 1.67944H14.9079L14.5535 3.82587L15.933 4.05376L16.3781 1.35944C16.4177 1.08359 16.3382 0.807925 16.1604 0.602615Z"
                  fill="#667085"
                />
                <path
                  d="M12.9362 10.6523H7.06384C6.67771 10.6523 6.36475 10.9653 6.36475 11.3514C6.36475 11.7376 6.67771 12.0505 7.06384 12.0505H12.9362C13.3223 12.0505 13.6353 11.7376 13.6353 11.3514C13.6353 10.9653 13.3223 10.6523 12.9362 10.6523Z"
                  fill="#667085"
                />
              </g>
              <defs>
                <clipPath id="clip0_10218_12413">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </i>
          Archive
        </Button>
      </React.Fragment>
    </div>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={title}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
                errorMsg={formData.error}
                button={editButton}
              />
              <div className="record-outer-row settings-outer-row">
                <ReviewLeftMenu
                  user={user}
                  fileformData_record={formData}
                  filereviewId={id}
                  reviewIdEncrypted={encryptedReviewId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <div className="right-outer">
                  <div className="right-inner">
                    {loading ? (
                      <div className="no-record">
                        <CircularProgress />
                      </div>
                    ) : survey ? (
                      <>
                        <div className="client-file-review">
                          <div className="card-outline-inner">
                            {isEditing ? (
                              <div className="form-group edit-container">
                                <TextField
                                  error={!!clientNameError}
                                  id="Subject"
                                  variant="outlined"
                                  name="subject"
                                  value={clientName}
                                  onChange={(e) => setClientName(e.target.value)}
                                  className="form-control"
                                  placeholder="Enter a client name"
                                  type="text"
                                  // helperText={errors.clientName}
                                  inputProps={{
                                    maxLength: 120 // Set the maximum character length
                                  }}
                                />
                                <Button
                                  className="btn primary-btn btn-sm"
                                  onClick={handleNameSaveClick}>
                                  Save
                                </Button>
                                <Button
                                  className="btn border-btn btn-sm"
                                  onClick={handleNameCancelClick}>
                                  Cancel
                                </Button>
                              </div>
                            ) : (
                              <div className="client-file-review-template client-name">
                                <h3>{formData.client}</h3>
                                <Button
                                  onClick={() => handleNameEditClick()}
                                  className="edit btn template-inner-edit-btn">
                                  <img src={edit} alt="Right Arrow" />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="card-outline select-dropdown-survay">
                          <Survey model={survey} />
                          {/* {pageShow && (
                          <div className="inner-btn-wrap">
                            <Dropdown survey={survey} />
                          </div>
                        )} */}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default FileReviewTemplateEdit;
