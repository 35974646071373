import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list'; // Include the list plugin
import timeGridPlugin from '@fullcalendar/timegrid'; // Include the timegrid plugin
import { RRule } from 'rrule';
import { getCalendarEvents } from '../../../../api/calendar/getCalendarEvents';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  decryptData,
  encryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { Button } from '@mui/material';
import { CalenderEventState } from '../../user/state';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import { format } from 'date-fns';
import { CREATE, MODULES, READ } from '../../../../constants/constants';

const Calendar = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = decryptData(id, true);
  const title = 'Compliance Calendar';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const [events, setEvents] = useState<CalenderEventState[]>([]);
  const [recurringEvents, setRecurringEvents] = useState<any[]>([]);

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, parseInt(id!, 10), READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchRecurringContent = (recurrence_rule: string, is_recurring: string) => {
    let recurringContent = ''; // Initialize recurringContent variable
    if (recurrence_rule && is_recurring === 'yes') {
      const rule = RRule.fromString(recurrence_rule);
      const recurrence_pattern =
        rule.options.freq >= 0 ? mapFrequencyToString(rule.options.freq) : '';
      recurringContent = 'Recurs ' + recurrence_pattern.toLowerCase();
    }
    return recurringContent;
  };

  useEffect(() => {
    localStorage.removeItem('calendarTasksCount');
    localStorage.removeItem('calendarNotesCount');
    const fetchData = async () => {
      try {
        if (authToken) {
          const source_id = parseInt(id!, 10);
          const response: CalenderEventState[] = await getCalendarEvents(
            source_id,
            authToken,
            selectedAccount
          );

          if (response) {
            const transformedEvents = response.map((event: any) => {
              const recurringContent = fetchRecurringContent(
                event.recurrence_rule,
                event.is_recurring
              );
              return {
                title: event.unique_id || '',
                start: event.event_date || '',
                end: event.event_date || '',
                id: event.id,
                unique_id: event.unique_id || '',
                owner_id: event.owner_id,
                regulatory_reference: event.regulatory_reference,
                event_date: event.event_date,
                module_id: event.module_id,
                color: event.color || '',
                is_recurring: event.is_recurring,
                recurrence_type: event.recurrence_type,
                user: event.user,
                data: event.data,
                recurrence_pattern: event.recurrence_pattern,
                task_days: event.task_days,
                recurrence_rule: event.recurrence_rule || '',
                inactive_date: event.inactive_date,
                created_at: event.created_at,
                create_task: event.create_task,
                required_action: event.required_action || '',
                subject: event.subject || '',
                recurringContent: recurringContent
              };
            });
            setEvents(transformedEvents);
          } else {
            console.error('Data is not available in the response:', response);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const mapFrequencyToString = (frequency: number) => {
    switch (frequency) {
      case RRule.YEARLY:
        return 'YEARLY';
      case RRule.MONTHLY:
        return 'MONTHLY';
      case RRule.WEEKLY:
        return 'WEEKLY';
      case RRule.DAILY:
        return 'DAILY';
      default:
        return '';
    }
  };

  useEffect(() => {
    const generateRecurringEvents = () => {
      const allRecurringEvents: any[] = [];
      if (events && events.length > 0) {
        events.forEach((event) => {
          if (event.recurrence_rule && event.is_recurring) {
            const recurringContent = fetchRecurringContent(
              event.recurrence_rule,
              event.is_recurring
            );
            const rule = RRule.fromString(event.recurrence_rule);
            const occurrences = rule.all();
            const newRecurringEvents = occurrences
              .filter(
                (date) =>
                  format(new Date(date), 'yyyy-MM-dd') >
                  format(new Date(event.event_date), 'yyyy-MM-dd')
              ) // Filter out occurrences before the event start date
              .filter((date) => {
                // Filter out occurrences after the inactive date
                if (event.inactive_date) {
                  return (
                    format(new Date(date), 'yyyy-MM-dd') <=
                    format(new Date(event.inactive_date), 'yyyy-MM-dd')
                  );
                }
                return true; // Include events with no inactive_date
              })
              .map((date) => {
                return {
                  eventId: event.id,
                  title: event.unique_id ? event.unique_id : event.title,
                  start: date,
                  end: date,
                  color: event.color,
                  subject: event.subject,
                  required_action: event.required_action,
                  recurringContent: recurringContent
                };
              });
            allRecurringEvents.push(...newRecurringEvents);
          }
        });
      }
      setRecurringEvents(allRecurringEvents);
    };

    if (events.length > 0) {
      generateRecurringEvents();
    }
  }, [events]);

  const allEvents = [...events, ...recurringEvents];
  const handleEventClick = (info: any) => {
    let eventId;
    eventId = info.event.extendedProps.eventId;
    if (eventId == undefined) {
      eventId = info.event.id;
    }
    navigate(`/calendar/${encryptData(String(eventId), true)}?source=${encryptedId}`);
  };

  const addButton = (
    <div className="inner-btn-wrap">
      <Button
        className="btn blue-fill-btn"
        component={RouterLink}
        to={`/compliance-calendar-schedule/${encryptedId}`}>
        <i className="left">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M11.6667 18.3346C11.6667 18.3346 12.3744 18.2335 15.3033 15.3046C18.2322 12.3757 18.2322 7.62693 15.3033 4.698C14.2656 3.66027 12.9994 2.99021 11.6667 2.68782M11.6667 18.3346H16.6667M11.6667 18.3346L11.6667 13.3346M8.33333 1.66813C8.33333 1.66813 7.62563 1.76923 4.6967 4.69816C1.76777 7.6271 1.76777 12.3758 4.6967 15.3048C5.73443 16.3425 7.0006 17.0126 8.33333 17.3149M8.33333 1.66813L3.33333 1.66797M8.33333 1.66813L8.33333 6.66797"
              stroke="CurrentColor"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </i>
        View Schedule
      </Button>

      {checkUserPermission(user, MODULES, parseInt(id!, 10), CREATE) && (
        <Button
          className="btn primary-btn"
          component={RouterLink}
          to={`/calendar-event-add?source=${encryptedId}`}>
          <i className="left">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clipPath="url(#clip0_6545_879)">
                <path
                  d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6545_879">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </i>
          Add Event
        </Button>
      )}
    </div>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section">
            <div className="container-full">
              <CommonBreadcrumbs title={title} pageTitle={title} button={addButton} />
              <div className="table-outer">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                  initialView="dayGridMonth"
                  events={allEvents}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,listYear'
                  }}
                  buttonText={{
                    today: 'Today'
                  }}
                  views={{
                    dayGridMonth: {
                      buttonText: 'Calendar View'
                    },
                    listYear: {
                      buttonText: 'List View',
                      listDayFormat: { day: 'numeric', month: 'short', year: 'numeric' }
                    }
                  }}
                  eventContent={(eventInfo: any) => (
                    <div
                      className={`custom-event-content-${
                        eventInfo.view.type.includes('list') ? 'list' : 'grid'
                      }`}
                      style={{
                        backgroundColor: eventInfo.view.type.includes('list')
                          ? ''
                          : eventInfo.event.backgroundColor
                      }}>
                      <div className="event-subject">{eventInfo.event.extendedProps.subject}</div>
                      {eventInfo.view.type.includes('list') && (
                        <>
                          <div className="event-action">
                            {eventInfo.event.extendedProps.required_action}
                          </div>

                          <div className="event-recurrence">
                            {eventInfo.event.extendedProps.recurringContent &&
                            eventInfo.event.extendedProps.recurringContent != ''
                              ? eventInfo.event.extendedProps.recurringContent
                              : 'Not recurring'}
                          </div>

                          <div className="table-btn">
                            <img src={rightarrow} alt="Right Arrow" />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  eventClick={handleEventClick}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default Calendar;
