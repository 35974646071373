import React from 'react';
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TableContainer,
  TextField,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { OnboardingFormsProps } from './state';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { utcToZonedTime } from 'date-fns-tz';
import { encryptData, useCurrentUserInfo } from '../../../../utils/common/user';
import { t } from 'i18next';

const OnboardingCreateEditFormData: React.FC<OnboardingFormsProps> = ({
  formData,
  onboardingApplicationData,
  proceedToAssessment,
  onboardingAssessmentData,
  proceedToAuthorisations,
  proceedToChecklist,
  onboardingChecklistData,
  onboardingAuthorisationsData,
  users,
  showApplicationSaveButton,
  showAssessmentSaveButton,
  showAuthorisationsSaveButton,
  errors,
  applicationError,
  assessmentError,
  authorisationsError,
  closed_at,
  isDisabled,
  current_route,
  onboardingAddMode,
  edit_btn_snapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sourceId,
  handleComplete,
  handleInputChange,
  handleSelectChange,
  handleFurtherOnbpradingApplication,
  handleFurtherOnbpradingAssessment,
  handleFurtherOnboardingAuthorisations,
  onboardingIdEncrypted,
  sourceIdEncrypted,
  selectedAccount
}) => {
  const currentUser = useCurrentUserInfo();

  return (
    <>
      <form action="" className="record-create-form">
        <div className="card-outline">
          <div className="card-title-wrap secondary-title-wrap">
            <h3>Snapshot</h3>
            <div className="inner-btn-wrap">
              {isDisabled &&
              current_route &&
              current_route === `/onboarding/details/${onboardingIdEncrypted}`
                ? edit_btn_snapshot
                : ''}
            </div>
          </div>
          {isDisabled &&
            current_route &&
            current_route === `/onboarding/details/${onboardingIdEncrypted}` && (
              <div className="row-wrap">
                <div className="form-group w-full">
                  <label className="form-label">Created</label>
                  <p className="form-control">
                    {formData.created_at
                      ? format(new Date(formData.created_at || ''), 'd/MMM/yyyy')
                      : ''}
                  </p>
                </div>
                <div className="form-group">
                  <label className="form-label">Owner</label>
                  <p className="form-control">
                    {formData.owner?.first_name} {formData.owner?.last_name}
                  </p>
                </div>
                <div className="form-group">
                  <label className="form-label">CAR (if applicable)</label>
                  <p className="form-control">{formData.car ? formData.car : '-'}</p>
                </div>
                <div className="form-group">
                  <label className="form-label">Adviser first name</label>
                  <p className="form-control">{formData.first_name}</p>
                </div>
                <div className="form-group">
                  <label className="form-label">Adviser surname</label>
                  <p className="form-control">{formData.surname}</p>
                </div>
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <p className="form-control">{formData.email}</p>
                </div>
                <div className="form-group">
                  <label className="form-label">State</label>
                  <p className="form-control">{formData.state}</p>
                </div>
                <div className="form-group w-full-reopen-calendar">
                  <label className="form-label">Status</label>
                  <p className="form-control">{formData.is_open == 1 ? 'Open' : 'Archived'}</p>
                </div>

                {formData.is_open == 1 && (
                  <div className="inner-btn-wrap">
                    <Button className="btn white-btn" onClick={() => handleComplete(0)}>
                      <i className="left">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clipPath="url(#clip0_10218_12413)">
                            <path
                              d="M19.784 6.58183C19.6055 6.37094 19.3433 6.25 19.0649 6.25H0.935092C0.656852 6.25 0.39494 6.37094 0.216426 6.5816C0.0498255 6.77828 -0.0254473 7.03413 0.00763839 7.28816L1.07794 18.4578C1.07888 18.4664 1.07982 18.475 1.08099 18.4834C1.17513 19.1881 1.77356 19.7194 2.47313 19.7194H17.5269C18.2624 19.7194 18.8757 19.1561 18.9221 18.4578L19.9924 7.28839C20.0254 7.03441 19.9504 6.77851 19.784 6.58183ZM17.5306 18.3226C17.5299 18.3215 17.5264 18.3208 17.5269 18.3212L2.48547 18.3233C2.47942 18.3205 2.47055 18.3101 2.46778 18.3019L1.44657 7.64843H18.5534L17.5306 18.3226Z"
                              fill="#667085"
                            />
                            <path
                              d="M18.2334 3.56308C18.0563 3.35918 17.7988 3.24219 17.5266 3.24219H2.4962C2.22425 3.24219 1.96558 3.36359 1.7864 3.57519C1.60628 3.7882 1.52843 4.0655 1.57226 4.32718L1.99171 7.05364L3.37381 6.8411L3.03522 4.64038H16.9879L16.6496 6.8411L18.0314 7.05364L18.4518 4.31925C18.4909 4.04386 18.4115 3.76816 18.2334 3.56308Z"
                              fill="#667085"
                            />
                            <path
                              d="M16.1604 0.602615C15.9833 0.398476 15.7256 0.28125 15.4529 0.28125H4.57043C4.29848 0.28125 4.03981 0.402655 3.86063 0.614255C3.68028 0.827261 3.60266 1.10456 3.64673 1.36812L4.06618 4.04798L5.44757 3.83173L5.11062 1.67944H14.9079L14.5535 3.82587L15.933 4.05376L16.3781 1.35944C16.4177 1.08359 16.3382 0.807925 16.1604 0.602615Z"
                              fill="#667085"
                            />
                            <path
                              d="M12.9362 10.6523H7.06384C6.67771 10.6523 6.36475 10.9653 6.36475 11.3514C6.36475 11.7376 6.67771 12.0505 7.06384 12.0505H12.9362C13.3223 12.0505 13.6353 11.7376 13.6353 11.3514C13.6353 10.9653 13.3223 10.6523 12.9362 10.6523Z"
                              fill="#667085"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_10218_12413">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </i>
                      Archive
                    </Button>
                  </div>
                )}

                {formData.is_open == 0 && closed_at && (
                  <div className="form-group">
                    <label className="form-label">Date Closed</label>
                    {closed_at ? format(new Date(closed_at), 'd/MMM/yyyy') : ''}
                  </div>
                )}
              </div>
            )}

          {!isDisabled &&
            current_route &&
            (current_route === `/onboarding-edit/${onboardingIdEncrypted}` ||
              current_route === `/onboarding-add`) &&
            !onboardingAddMode && (
              <div className="row-wrap ">
                <div className="form-group">
                  <label className="form-label">Owner</label>
                  <FormControl variant="outlined" error={!!errors.host_id}>
                    <Select
                      error={!!errors.owner_id}
                      labelId="owner-label"
                      id="owner"
                      name="owner_id"
                      value={formData.owner_id.toString()}
                      onChange={(e) => handleSelectChange(e)}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      renderValue={(selected) => {
                        if (selected === '0') {
                          return <p className="dropdown-placeholder">Select Owner</p>; // Render "Select Owner" as placeholder
                        }
                        const selectedOption =
                          users && users.find((option) => option.id === parseInt(selected, 10));
                        return selectedOption ? `${selectedOption.name}` : '';
                      }}>
                      {users &&
                        users.map((option) => (
                          <MenuItem key={option?.id} value={option?.id || ''}>
                            {option?.name || ''}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.owner_id && (
                    <Typography variant="caption" color="error">
                      {errors.owner_id}
                    </Typography>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">CAR name (if applicable)</label>
                  <TextField
                    error={!!errors.car}
                    id="car"
                    variant="outlined"
                    name="car"
                    value={formData.car}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter CAR (if applicable)"
                    type="text"
                    helperText={errors.car}
                    inputProps={{
                      maxLength: 120 // Set the maximum character length
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Adviser first name</label>
                  <TextField
                    error={!!errors.first_name}
                    id="first-name"
                    variant="outlined"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter first name"
                    type="text"
                    helperText={errors.first_name}
                    inputProps={{
                      maxLength: 120 // Set the maximum character length
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Adviser surname</label>
                  <TextField
                    error={!!errors.surname}
                    id="surname"
                    variant="outlined"
                    name="surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter surname"
                    type="text"
                    helperText={errors.surname}
                    inputProps={{
                      maxLength: 120 // Set the maximum character length
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Adviser personal email</label>
                  <TextField
                    error={!!errors.email}
                    id="email"
                    variant="outlined"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter email"
                    type="text"
                    helperText={errors.email}
                    inputProps={{
                      maxLength: 120 // Set the maximum character length
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">State</label>
                  <TextField
                    error={!!errors.state}
                    id="state"
                    variant="outlined"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter state"
                    type="text"
                    helperText={errors.state}
                    inputProps={{
                      maxLength: 120 // Set the maximum character length
                    }}
                  />
                </div>
              </div>
            )}
        </div>

        {isDisabled &&
          current_route &&
          current_route === `/onboarding/details/${onboardingIdEncrypted}` && (
            <>
              <div className="card-outline">
                <div className="card-title-wrap secondary-title-wrap">
                  <h3>Application Form</h3>
                </div>
                <div className="row-wrap">
                  <TableContainer>
                    {!onboardingApplicationData.submitted && (
                      <div className="no-record no-record-submitted">
                        Waiting for {formData.first_name} {formData.surname} to complete Application
                        Form
                      </div>
                    )}

                    {onboardingApplicationData.submitted == 1 && (
                      <>
                        <div className="inner-wrap-title inner-color-wrap">
                          {onboardingApplicationData.updated_at && (
                            <p>
                              Application form submitted on{' '}
                              {format(new Date(onboardingApplicationData.updated_at), 'd/MMM/yyyy')}{' '}
                              at{' '}
                              {format(
                                utcToZonedTime(
                                  new Date(onboardingApplicationData.updated_at),
                                  process.env.REACT_APP_TIMEZONE || ''
                                ),
                                'h:mm aa'
                              )}
                            </p>
                          )}
                        </div>
                        <div className="inner-btn-wrap">
                          <div className="form-group">
                            <Button
                              className="btn blue-fill-btn btn-sm"
                              component={RouterLink}
                              to={`/onboarding/review-application/${encryptData(String(onboardingApplicationData.id), true)}?source=${sourceIdEncrypted}&onboardingId=${onboardingIdEncrypted}`}
                              target="_blank">
                              <i className="left">
                                <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                  <path
                                    d="M9.66732 1.88867V5.33079C9.66732 5.7975 9.66732 6.03085 9.75815 6.20911C9.83804 6.36592 9.96552 6.4934 10.1223 6.57329C10.3006 6.66412 10.5339 6.66412 11.0007 6.66412H14.4428M9.66732 14.1641H4.66732M11.334 10.8307H4.66732M14.6673 8.32092V14.3307C14.6673 15.7309 14.6673 16.4309 14.3948 16.9657C14.1552 17.4361 13.7727 17.8186 13.3023 18.0582C12.7675 18.3307 12.0674 18.3307 10.6673 18.3307H5.33398C3.93385 18.3307 3.23379 18.3307 2.69901 18.0582C2.2286 17.8186 1.84615 17.4361 1.60647 16.9657C1.33398 16.4309 1.33398 15.7309 1.33398 14.3307V5.66406C1.33398 4.26393 1.33398 3.56387 1.60647 3.02909C1.84615 2.55868 2.2286 2.17623 2.69901 1.93655C3.23379 1.66406 3.93385 1.66406 5.33398 1.66406H8.01046C8.62194 1.66406 8.92768 1.66406 9.2154 1.73314C9.47049 1.79438 9.71435 1.89539 9.93803 2.03246C10.1903 2.18707 10.4065 2.40326 10.8389 2.83564L13.4957 5.49249C13.9281 5.92487 14.1443 6.14106 14.2989 6.39335C14.436 6.61703 14.537 6.86089 14.5982 7.11598C14.6673 7.4037 14.6673 7.70944 14.6673 8.32092Z"
                                    stroke="CurrentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </i>
                              View Application Form
                            </Button>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-label label-size-title">
                            Do you want to proceed to assessment for this prospective adviser?
                          </label>
                          {proceedToAssessment == null ? (
                            <FormControl variant="outlined">
                              <Select
                                labelId="proceed-to-assessment-label"
                                id="proceed-to-assessment"
                                name="proceed_to_assessment"
                                value={
                                  onboardingApplicationData.proceed_to_assessment
                                    ? onboardingApplicationData.proceed_to_assessment
                                    : ''
                                }
                                onChange={(e) =>
                                  handleSelectChange(e, t('templateType.application'))
                                }
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                className="custom-select"
                                MenuProps={{ className: 'custom-dropdown-menu' }}>
                                <MenuItem value="0">No</MenuItem>
                                <MenuItem value="1">Yes</MenuItem>
                              </Select>
                            </FormControl>
                          ) : (
                            <p className="form-control">
                              {proceedToAssessment == 0 ? 'No' : 'Yes'}
                            </p>
                          )}
                        </div>
                        {onboardingApplicationData.proceed_to_assessment == 0 && (
                          <div className="form-group w-full">
                            <label className="form-label label-size-title">Reason</label>
                            {proceedToAssessment == null ? (
                              <TextField
                                error={!!applicationError.reason}
                                fullWidth
                                multiline
                                rows={4}
                                name="reason"
                                className="form-control textarea"
                                placeholder="Enter reason you will not proceed"
                                value={onboardingApplicationData.reason}
                                onChange={(e) =>
                                  handleInputChange(e, t('templateType.application'))
                                }
                                helperText={applicationError.reason || ''}
                              />
                            ) : (
                              <p className="form-control">{onboardingApplicationData.reason}</p>
                            )}
                          </div>
                        )}

                        {showApplicationSaveButton && proceedToAssessment == null && (
                          <div className="inner-btn-wrap">
                            <div className="form-group w-full">
                              <Button
                                className="btn primary-btn btn-sm"
                                onClick={handleFurtherOnbpradingApplication}>
                                <i className="left">
                                  <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
                                    <path
                                      d="M4.33333 1.5V4.33333C4.33333 4.80004 4.33333 5.0334 4.42416 5.21166C4.50406 5.36846 4.63154 5.49594 4.78834 5.57584C4.9666 5.66667 5.19996 5.66667 5.66667 5.66667H11.3333C11.8 5.66667 12.0334 5.66667 12.2117 5.57584C12.3685 5.49594 12.4959 5.36846 12.5758 5.21166C12.6667 5.0334 12.6667 4.80004 12.6667 4.33333V2.33333M12.6667 16.5V11.1667C12.6667 10.7 12.6667 10.4666 12.5758 10.2883C12.4959 10.1315 12.3685 10.0041 12.2117 9.92416C12.0334 9.83333 11.8 9.83333 11.3333 9.83333H5.66667C5.19996 9.83333 4.9666 9.83333 4.78834 9.92416C4.63154 10.0041 4.50406 10.1315 4.42416 10.2883C4.33333 10.4666 4.33333 10.7 4.33333 11.1667V16.5M16 6.77124V12.5C16 13.9001 16 14.6002 15.7275 15.135C15.4878 15.6054 15.1054 15.9878 14.635 16.2275C14.1002 16.5 13.4001 16.5 12 16.5H5C3.59987 16.5 2.8998 16.5 2.36502 16.2275C1.89462 15.9878 1.51217 15.6054 1.27248 15.135C1 14.6002 1 13.9001 1 12.5V5.5C1 4.09987 1 3.3998 1.27248 2.86502C1.51217 2.39462 1.89462 2.01217 2.36502 1.77248C2.8998 1.5 3.59987 1.5 5 1.5H10.7288C11.1364 1.5 11.3402 1.5 11.5321 1.54605C11.7021 1.58688 11.8647 1.65422 12.0138 1.7456C12.182 1.84867 12.3261 1.9928 12.6144 2.28105L15.219 4.88562C15.5072 5.17387 15.6513 5.318 15.7544 5.48619C15.8458 5.63531 15.9131 5.79789 15.9539 5.96795C16 6.15976 16 6.36358 16 6.77124Z"
                                      stroke="CurrentColor"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </i>
                                Save
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </TableContainer>
                </div>
              </div>

              {onboardingApplicationData.submitted == 1 && proceedToAssessment == 1 && (
                <div className="card-outline">
                  <div className="card-title-wrap secondary-title-wrap">
                    <h3>Assessment</h3>
                  </div>
                  <div className="row-wrap">
                    <TableContainer>
                      {onboardingAssessmentData.submitted != 1 ? (
                        <>
                          <p className="form-group mb-2">
                            {' '}
                            Click the button below to complete the onboarding checklist
                          </p>
                          <div className="inner-btn-wrap">
                            <div className="form-group">
                              <Button
                                className="btn primary-btn btn-sm"
                                component={RouterLink}
                                to={`/onboarding/assessment?source=${sourceIdEncrypted}&onboardingId=${onboardingIdEncrypted}`}>
                                <i className="left">
                                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                    <path
                                      d="M9.66732 1.88867V5.33079C9.66732 5.7975 9.66732 6.03085 9.75815 6.20911C9.83804 6.36592 9.96552 6.4934 10.1223 6.57329C10.3006 6.66412 10.5339 6.66412 11.0007 6.66412H14.4428M9.66732 14.1641H4.66732M11.334 10.8307H4.66732M14.6673 8.32092V14.3307C14.6673 15.7309 14.6673 16.4309 14.3948 16.9657C14.1552 17.4361 13.7727 17.8186 13.3023 18.0582C12.7675 18.3307 12.0674 18.3307 10.6673 18.3307H5.33398C3.93385 18.3307 3.23379 18.3307 2.69901 18.0582C2.2286 17.8186 1.84615 17.4361 1.60647 16.9657C1.33398 16.4309 1.33398 15.7309 1.33398 14.3307V5.66406C1.33398 4.26393 1.33398 3.56387 1.60647 3.02909C1.84615 2.55868 2.2286 2.17623 2.69901 1.93655C3.23379 1.66406 3.93385 1.66406 5.33398 1.66406H8.01046C8.62194 1.66406 8.92768 1.66406 9.2154 1.73314C9.47049 1.79438 9.71435 1.89539 9.93803 2.03246C10.1903 2.18707 10.4065 2.40326 10.8389 2.83564L13.4957 5.49249C13.9281 5.92487 14.1443 6.14106 14.2989 6.39335C14.436 6.61703 14.537 6.86089 14.5982 7.11598C14.6673 7.4037 14.6673 7.70944 14.6673 8.32092Z"
                                      stroke="CurrentColor"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </i>
                                Complete assessment
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : onboardingAssessmentData.submitted == 1 ? (
                        <>
                          <div className="inner-wrap-title">
                            {onboardingAssessmentData.updated_at && (
                              <p>
                                Assessment form completed by{' '}
                                {onboardingAssessmentData?.user?.first_name}{' '}
                                {onboardingAssessmentData?.user?.last_name} on{' '}
                                {format(
                                  new Date(onboardingAssessmentData.updated_at),
                                  'd/MMM/yyyy'
                                )}{' '}
                                at{' '}
                                {format(
                                  utcToZonedTime(
                                    new Date(onboardingAssessmentData.updated_at),
                                    process.env.REACT_APP_TIMEZONE || ''
                                  ),
                                  'h:mm aa'
                                )}
                              </p>
                            )}
                          </div>
                          <div className="inner-btn-wrap">
                            <div className="form-group">
                              <Button
                                className="btn blue-fill-btn btn-sm"
                                component={RouterLink}
                                to={`/onboarding/assessment-review/${encryptData(String(onboardingAssessmentData.id), true)}?sourceId=${sourceIdEncrypted}&onboardingId=${onboardingIdEncrypted}`}
                                target="_blank">
                                <i className="left">
                                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                    <path
                                      d="M9.66732 1.88867V5.33079C9.66732 5.7975 9.66732 6.03085 9.75815 6.20911C9.83804 6.36592 9.96552 6.4934 10.1223 6.57329C10.3006 6.66412 10.5339 6.66412 11.0007 6.66412H14.4428M9.66732 14.1641H4.66732M11.334 10.8307H4.66732M14.6673 8.32092V14.3307C14.6673 15.7309 14.6673 16.4309 14.3948 16.9657C14.1552 17.4361 13.7727 17.8186 13.3023 18.0582C12.7675 18.3307 12.0674 18.3307 10.6673 18.3307H5.33398C3.93385 18.3307 3.23379 18.3307 2.69901 18.0582C2.2286 17.8186 1.84615 17.4361 1.60647 16.9657C1.33398 16.4309 1.33398 15.7309 1.33398 14.3307V5.66406C1.33398 4.26393 1.33398 3.56387 1.60647 3.02909C1.84615 2.55868 2.2286 2.17623 2.69901 1.93655C3.23379 1.66406 3.93385 1.66406 5.33398 1.66406H8.01046C8.62194 1.66406 8.92768 1.66406 9.2154 1.73314C9.47049 1.79438 9.71435 1.89539 9.93803 2.03246C10.1903 2.18707 10.4065 2.40326 10.8389 2.83564L13.4957 5.49249C13.9281 5.92487 14.1443 6.14106 14.2989 6.39335C14.436 6.61703 14.537 6.86089 14.5982 7.11598C14.6673 7.4037 14.6673 7.70944 14.6673 8.32092Z"
                                      stroke="CurrentColor"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </i>
                                View Assessment
                              </Button>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-label label-size-title">
                              Do you want to authorise this adviser?
                            </label>
                            {proceedToAuthorisations == null ? (
                              <FormControl variant="outlined">
                                <Select
                                  labelId="proceed-to-authorisations-label"
                                  id="proceed-to-authorisations"
                                  name="proceed_to_authorisations"
                                  value={
                                    onboardingAssessmentData.proceed_to_authorisations
                                      ? onboardingAssessmentData.proceed_to_authorisations
                                      : ''
                                  }
                                  onChange={(e) =>
                                    handleSelectChange(e, t('templateType.assessment'))
                                  }
                                  IconComponent={KeyboardArrowDownRoundedIcon}
                                  className="custom-select"
                                  MenuProps={{ className: 'custom-dropdown-menu' }}>
                                  <MenuItem value="0">No</MenuItem>
                                  <MenuItem value="1">Yes</MenuItem>
                                </Select>
                              </FormControl>
                            ) : (
                              <p className="form-control">
                                {proceedToAuthorisations == 0 ? 'No' : 'Yes'}
                              </p>
                            )}
                          </div>
                          {onboardingAssessmentData.proceed_to_authorisations == 0 && (
                            <div className="form-group w-full">
                              <label className="form-label label-size-title">Reason</label>
                              {proceedToAuthorisations == null ? (
                                <TextField
                                  error={!!assessmentError.reason}
                                  fullWidth
                                  multiline
                                  rows={4}
                                  name="reason"
                                  className="form-control textarea"
                                  placeholder="Enter reason you will not proceed"
                                  value={onboardingAssessmentData.reason}
                                  onChange={(e) =>
                                    handleInputChange(e, t('templateType.assessment'))
                                  }
                                  helperText={assessmentError.reason || ''}
                                />
                              ) : (
                                <p className="form-control">{onboardingAssessmentData.reason}</p>
                              )}
                            </div>
                          )}

                          {showAssessmentSaveButton && proceedToAuthorisations == null && (
                            <div className="inner-btn-wrap">
                              <div className="form-group w-full">
                                <Button
                                  className="btn primary-btn btn-sm"
                                  onClick={handleFurtherOnbpradingAssessment}>
                                  <i className="left">
                                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
                                      <path
                                        d="M4.33333 1.5V4.33333C4.33333 4.80004 4.33333 5.0334 4.42416 5.21166C4.50406 5.36846 4.63154 5.49594 4.78834 5.57584C4.9666 5.66667 5.19996 5.66667 5.66667 5.66667H11.3333C11.8 5.66667 12.0334 5.66667 12.2117 5.57584C12.3685 5.49594 12.4959 5.36846 12.5758 5.21166C12.6667 5.0334 12.6667 4.80004 12.6667 4.33333V2.33333M12.6667 16.5V11.1667C12.6667 10.7 12.6667 10.4666 12.5758 10.2883C12.4959 10.1315 12.3685 10.0041 12.2117 9.92416C12.0334 9.83333 11.8 9.83333 11.3333 9.83333H5.66667C5.19996 9.83333 4.9666 9.83333 4.78834 9.92416C4.63154 10.0041 4.50406 10.1315 4.42416 10.2883C4.33333 10.4666 4.33333 10.7 4.33333 11.1667V16.5M16 6.77124V12.5C16 13.9001 16 14.6002 15.7275 15.135C15.4878 15.6054 15.1054 15.9878 14.635 16.2275C14.1002 16.5 13.4001 16.5 12 16.5H5C3.59987 16.5 2.8998 16.5 2.36502 16.2275C1.89462 15.9878 1.51217 15.6054 1.27248 15.135C1 14.6002 1 13.9001 1 12.5V5.5C1 4.09987 1 3.3998 1.27248 2.86502C1.51217 2.39462 1.89462 2.01217 2.36502 1.77248C2.8998 1.5 3.59987 1.5 5 1.5H10.7288C11.1364 1.5 11.3402 1.5 11.5321 1.54605C11.7021 1.58688 11.8647 1.65422 12.0138 1.7456C12.182 1.84867 12.3261 1.9928 12.6144 2.28105L15.219 4.88562C15.5072 5.17387 15.6513 5.318 15.7544 5.48619C15.8458 5.63531 15.9131 5.79789 15.9539 5.96795C16 6.15976 16 6.36358 16 6.77124Z"
                                        stroke="CurrentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </i>
                                  Save
                                </Button>
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}
                    </TableContainer>
                  </div>
                </div>
              )}

              {onboardingApplicationData.submitted == 1 &&
                proceedToAssessment == 1 &&
                onboardingAssessmentData.submitted == 1 &&
                proceedToAuthorisations == 1 && (
                  <>
                    <div className="card-outline">
                      <div className="card-title-wrap secondary-title-wrap">
                        <h3>Authorisations</h3>
                      </div>
                      <div className="row-wrap">
                        <TableContainer>
                          {onboardingAuthorisationsData.submitted != 1 ? (
                            <>
                              <p className="form-group mb-2">
                                {' '}
                                Click the button below to complete the authorisations
                              </p>
                              <div className="inner-btn-wrap">
                                <div className="form-group">
                                  <Button
                                    className="btn primary-btn btn-sm"
                                    component={RouterLink}
                                    to={`/onboarding/authorisations?source=${sourceIdEncrypted}&onboardingId=${onboardingIdEncrypted}`}>
                                    <i className="left">
                                      <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                        <path
                                          d="M9.66732 1.88867V5.33079C9.66732 5.7975 9.66732 6.03085 9.75815 6.20911C9.83804 6.36592 9.96552 6.4934 10.1223 6.57329C10.3006 6.66412 10.5339 6.66412 11.0007 6.66412H14.4428M9.66732 14.1641H4.66732M11.334 10.8307H4.66732M14.6673 8.32092V14.3307C14.6673 15.7309 14.6673 16.4309 14.3948 16.9657C14.1552 17.4361 13.7727 17.8186 13.3023 18.0582C12.7675 18.3307 12.0674 18.3307 10.6673 18.3307H5.33398C3.93385 18.3307 3.23379 18.3307 2.69901 18.0582C2.2286 17.8186 1.84615 17.4361 1.60647 16.9657C1.33398 16.4309 1.33398 15.7309 1.33398 14.3307V5.66406C1.33398 4.26393 1.33398 3.56387 1.60647 3.02909C1.84615 2.55868 2.2286 2.17623 2.69901 1.93655C3.23379 1.66406 3.93385 1.66406 5.33398 1.66406H8.01046C8.62194 1.66406 8.92768 1.66406 9.2154 1.73314C9.47049 1.79438 9.71435 1.89539 9.93803 2.03246C10.1903 2.18707 10.4065 2.40326 10.8389 2.83564L13.4957 5.49249C13.9281 5.92487 14.1443 6.14106 14.2989 6.39335C14.436 6.61703 14.537 6.86089 14.5982 7.11598C14.6673 7.4037 14.6673 7.70944 14.6673 8.32092Z"
                                          stroke="CurrentColor"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </i>
                                    Complete Authorisations
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : onboardingAuthorisationsData.submitted == 1 ? (
                            <>
                              <div className="inner-wrap-title">
                                {onboardingAuthorisationsData.updated_at &&
                                  onboardingAuthorisationsData.user && (
                                    <p>
                                      Onboarding checklist completed by{' '}
                                      {onboardingAuthorisationsData.user.first_name}{' '}
                                      {onboardingAuthorisationsData.user.last_name} on{' '}
                                      {format(
                                        new Date(onboardingAuthorisationsData.updated_at),
                                        'd/MMM/yyyy'
                                      )}{' '}
                                      at{' '}
                                      {format(
                                        utcToZonedTime(
                                          new Date(onboardingAuthorisationsData.updated_at),
                                          process.env.REACT_APP_TIMEZONE || ''
                                        ),
                                        'h:mm aa'
                                      )}
                                    </p>
                                  )}
                              </div>
                              <div className="inner-btn-wrap">
                                <div className="form-group">
                                  <Button
                                    className="btn blue-fill-btn btn-sm"
                                    component={RouterLink}
                                    to={`/onboarding/authorisations-review/${encryptData(String(onboardingAuthorisationsData.id), true)}?sourceId=${sourceIdEncrypted}&onboardingId=${onboardingIdEncrypted}`}
                                    target="_blank">
                                    <i className="left">
                                      <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                        <path
                                          d="M9.66732 1.88867V5.33079C9.66732 5.7975 9.66732 6.03085 9.75815 6.20911C9.83804 6.36592 9.96552 6.4934 10.1223 6.57329C10.3006 6.66412 10.5339 6.66412 11.0007 6.66412H14.4428M9.66732 14.1641H4.66732M11.334 10.8307H4.66732M14.6673 8.32092V14.3307C14.6673 15.7309 14.6673 16.4309 14.3948 16.9657C14.1552 17.4361 13.7727 17.8186 13.3023 18.0582C12.7675 18.3307 12.0674 18.3307 10.6673 18.3307H5.33398C3.93385 18.3307 3.23379 18.3307 2.69901 18.0582C2.2286 17.8186 1.84615 17.4361 1.60647 16.9657C1.33398 16.4309 1.33398 15.7309 1.33398 14.3307V5.66406C1.33398 4.26393 1.33398 3.56387 1.60647 3.02909C1.84615 2.55868 2.2286 2.17623 2.69901 1.93655C3.23379 1.66406 3.93385 1.66406 5.33398 1.66406H8.01046C8.62194 1.66406 8.92768 1.66406 9.2154 1.73314C9.47049 1.79438 9.71435 1.89539 9.93803 2.03246C10.1903 2.18707 10.4065 2.40326 10.8389 2.83564L13.4957 5.49249C13.9281 5.92487 14.1443 6.14106 14.2989 6.39335C14.436 6.61703 14.537 6.86089 14.5982 7.11598C14.6673 7.4037 14.6673 7.70944 14.6673 8.32092Z"
                                          stroke="CurrentColor"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </i>
                                    View Authorisations
                                  </Button>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="form-label label-size-title">
                                  Do you want to proceed to checklist ?
                                </label>
                                {proceedToChecklist == null ? (
                                  <FormControl variant="outlined">
                                    <Select
                                      labelId="proceed-to-checklist-label"
                                      id="proceed-to-checklist"
                                      name="proceed_to_checklist"
                                      value={
                                        onboardingAuthorisationsData.proceed_to_checklist
                                          ? onboardingAuthorisationsData.proceed_to_checklist
                                          : ''
                                      }
                                      onChange={(e) =>
                                        handleSelectChange(e, t('templateType.authorisations'))
                                      }
                                      IconComponent={KeyboardArrowDownRoundedIcon}
                                      className="custom-select"
                                      MenuProps={{ className: 'custom-dropdown-menu' }}>
                                      <MenuItem value="0">No</MenuItem>
                                      <MenuItem value="1">Yes</MenuItem>
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <p className="form-control">
                                    {proceedToChecklist == 0 ? 'No' : 'Yes'}
                                  </p>
                                )}
                              </div>
                              {onboardingAuthorisationsData.proceed_to_checklist == 0 && (
                                <div className="form-group w-full">
                                  <label className="form-label label-size-title">Reason</label>
                                  {proceedToChecklist == null ? (
                                    <TextField
                                      error={!!authorisationsError.reason}
                                      fullWidth
                                      multiline
                                      rows={4}
                                      name="reason"
                                      className="form-control textarea"
                                      placeholder="Enter reason you will not proceed"
                                      value={onboardingAuthorisationsData.reason}
                                      onChange={(e) =>
                                        handleInputChange(e, t('templateType.authorisations'))
                                      }
                                      helperText={authorisationsError.reason || ''}
                                    />
                                  ) : (
                                    <p className="form-control">
                                      {onboardingAuthorisationsData.reason}
                                    </p>
                                  )}
                                </div>
                              )}
                              {showAuthorisationsSaveButton && proceedToChecklist == null && (
                                <div className="inner-btn-wrap">
                                  <div className="form-group w-full">
                                    <Button
                                      className="btn primary-btn btn-sm"
                                      onClick={handleFurtherOnboardingAuthorisations}>
                                      <i className="left">
                                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
                                          <path
                                            d="M4.33333 1.5V4.33333C4.33333 4.80004 4.33333 5.0334 4.42416 5.21166C4.50406 5.36846 4.63154 5.49594 4.78834 5.57584C4.9666 5.66667 5.19996 5.66667 5.66667 5.66667H11.3333C11.8 5.66667 12.0334 5.66667 12.2117 5.57584C12.3685 5.49594 12.4959 5.36846 12.5758 5.21166C12.6667 5.0334 12.6667 4.80004 12.6667 4.33333V2.33333M12.6667 16.5V11.1667C12.6667 10.7 12.6667 10.4666 12.5758 10.2883C12.4959 10.1315 12.3685 10.0041 12.2117 9.92416C12.0334 9.83333 11.8 9.83333 11.3333 9.83333H5.66667C5.19996 9.83333 4.9666 9.83333 4.78834 9.92416C4.63154 10.0041 4.50406 10.1315 4.42416 10.2883C4.33333 10.4666 4.33333 10.7 4.33333 11.1667V16.5M16 6.77124V12.5C16 13.9001 16 14.6002 15.7275 15.135C15.4878 15.6054 15.1054 15.9878 14.635 16.2275C14.1002 16.5 13.4001 16.5 12 16.5H5C3.59987 16.5 2.8998 16.5 2.36502 16.2275C1.89462 15.9878 1.51217 15.6054 1.27248 15.135C1 14.6002 1 13.9001 1 12.5V5.5C1 4.09987 1 3.3998 1.27248 2.86502C1.51217 2.39462 1.89462 2.01217 2.36502 1.77248C2.8998 1.5 3.59987 1.5 5 1.5H10.7288C11.1364 1.5 11.3402 1.5 11.5321 1.54605C11.7021 1.58688 11.8647 1.65422 12.0138 1.7456C12.182 1.84867 12.3261 1.9928 12.6144 2.28105L15.219 4.88562C15.5072 5.17387 15.6513 5.318 15.7544 5.48619C15.8458 5.63531 15.9131 5.79789 15.9539 5.96795C16 6.15976 16 6.36358 16 6.77124Z"
                                            stroke="CurrentColor"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </i>
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : null}
                        </TableContainer>
                      </div>
                    </div>
                    <div className="card-outline">
                      <div className="card-title-wrap secondary-title-wrap">
                        <h3>Create User</h3>
                      </div>
                      <div className="row-wrap">
                        <TableContainer>
                          {!formData?.user?.email ? (
                            <>
                              <p className="form-group mb-2">
                                {' '}
                                Click the button below to create a new user for the prospective
                                adviser
                              </p>
                              <div className="inner-btn-wrap">
                                <div className="form-group">
                                  <Button
                                    className="btn primary-btn btn-sm"
                                    component={RouterLink}
                                    to={`/onboarding/create-user?source=${sourceIdEncrypted}&onboardingId=${onboardingIdEncrypted}&userId=${encryptData(String(formData.user_id), true)}`}>
                                    <i className="left">
                                      <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                        <path
                                          d="M9.66732 1.88867V5.33079C9.66732 5.7975 9.66732 6.03085 9.75815 6.20911C9.83804 6.36592 9.96552 6.4934 10.1223 6.57329C10.3006 6.66412 10.5339 6.66412 11.0007 6.66412H14.4428M9.66732 14.1641H4.66732M11.334 10.8307H4.66732M14.6673 8.32092V14.3307C14.6673 15.7309 14.6673 16.4309 14.3948 16.9657C14.1552 17.4361 13.7727 17.8186 13.3023 18.0582C12.7675 18.3307 12.0674 18.3307 10.6673 18.3307H5.33398C3.93385 18.3307 3.23379 18.3307 2.69901 18.0582C2.2286 17.8186 1.84615 17.4361 1.60647 16.9657C1.33398 16.4309 1.33398 15.7309 1.33398 14.3307V5.66406C1.33398 4.26393 1.33398 3.56387 1.60647 3.02909C1.84615 2.55868 2.2286 2.17623 2.69901 1.93655C3.23379 1.66406 3.93385 1.66406 5.33398 1.66406H8.01046C8.62194 1.66406 8.92768 1.66406 9.2154 1.73314C9.47049 1.79438 9.71435 1.89539 9.93803 2.03246C10.1903 2.18707 10.4065 2.40326 10.8389 2.83564L13.4957 5.49249C13.9281 5.92487 14.1443 6.14106 14.2989 6.39335C14.436 6.61703 14.537 6.86089 14.5982 7.11598C14.6673 7.4037 14.6673 7.70944 14.6673 8.32092Z"
                                          stroke="CurrentColor"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </i>
                                    Create User
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="inner-wrap-title">
                                {selectedAccount.name && (
                                  <p>
                                    User was created by {currentUser && currentUser.first_name}{' '}
                                    {currentUser && currentUser.last_name} on{' '}
                                    {format(new Date(formData.user.updated_at), 'd/MMM/yyyy')} at{' '}
                                    {format(
                                      utcToZonedTime(
                                        new Date(formData.user.updated_at),
                                        process.env.REACT_APP_TIMEZONE || ''
                                      ),
                                      'h:mm aa'
                                    )}
                                  </p>
                                )}
                              </div>
                            </>
                          )}
                        </TableContainer>
                      </div>
                    </div>
                  </>
                )}

              {onboardingApplicationData.submitted == 1 &&
                proceedToAssessment == 1 &&
                onboardingAssessmentData.submitted == 1 &&
                proceedToChecklist == 1 && (
                  <div className="card-outline">
                    <div className="card-title-wrap secondary-title-wrap">
                      <h3>Onboarding Checklist</h3>
                    </div>
                    <div className="row-wrap">
                      <TableContainer>
                        {onboardingChecklistData.submitted != 1 ? (
                          <>
                            <p className="form-group mb-2">
                              {' '}
                              Click the button below to complete the onboarding checklist
                            </p>
                            <div className="inner-btn-wrap">
                              <div className="form-group">
                                <Button
                                  className="btn primary-btn btn-sm"
                                  component={RouterLink}
                                  to={`/onboarding/checklist?source=${sourceIdEncrypted}&onboardingId=${onboardingIdEncrypted}`}>
                                  <i className="left">
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                      <path
                                        d="M9.66732 1.88867V5.33079C9.66732 5.7975 9.66732 6.03085 9.75815 6.20911C9.83804 6.36592 9.96552 6.4934 10.1223 6.57329C10.3006 6.66412 10.5339 6.66412 11.0007 6.66412H14.4428M9.66732 14.1641H4.66732M11.334 10.8307H4.66732M14.6673 8.32092V14.3307C14.6673 15.7309 14.6673 16.4309 14.3948 16.9657C14.1552 17.4361 13.7727 17.8186 13.3023 18.0582C12.7675 18.3307 12.0674 18.3307 10.6673 18.3307H5.33398C3.93385 18.3307 3.23379 18.3307 2.69901 18.0582C2.2286 17.8186 1.84615 17.4361 1.60647 16.9657C1.33398 16.4309 1.33398 15.7309 1.33398 14.3307V5.66406C1.33398 4.26393 1.33398 3.56387 1.60647 3.02909C1.84615 2.55868 2.2286 2.17623 2.69901 1.93655C3.23379 1.66406 3.93385 1.66406 5.33398 1.66406H8.01046C8.62194 1.66406 8.92768 1.66406 9.2154 1.73314C9.47049 1.79438 9.71435 1.89539 9.93803 2.03246C10.1903 2.18707 10.4065 2.40326 10.8389 2.83564L13.4957 5.49249C13.9281 5.92487 14.1443 6.14106 14.2989 6.39335C14.436 6.61703 14.537 6.86089 14.5982 7.11598C14.6673 7.4037 14.6673 7.70944 14.6673 8.32092Z"
                                        stroke="CurrentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </i>
                                  Complete checklist
                                </Button>
                              </div>
                            </div>
                          </>
                        ) : onboardingChecklistData.submitted == 1 ? (
                          <>
                            <div className="inner-wrap-title">
                              {onboardingChecklistData.updated_at &&
                                onboardingChecklistData.user && (
                                  <p>
                                    Onboarding checklist completed by{' '}
                                    {onboardingChecklistData.user.first_name}{' '}
                                    {onboardingChecklistData.user.last_name} on{' '}
                                    {format(
                                      new Date(onboardingChecklistData.updated_at),
                                      'd/MMM/yyyy'
                                    )}{' '}
                                    at{' '}
                                    {format(
                                      utcToZonedTime(
                                        new Date(onboardingChecklistData.updated_at),
                                        process.env.REACT_APP_TIMEZONE || ''
                                      ),
                                      'h:mm aa'
                                    )}
                                  </p>
                                )}
                            </div>
                            <div className="inner-btn-wrap">
                              <div className="form-group">
                                <Button
                                  className="btn primary-btn btn-sm"
                                  component={RouterLink}
                                  to={`/onboarding/checklist-review/${encryptData(String(onboardingChecklistData.id), true)}?sourceId=${sourceIdEncrypted}&onboardingId=${onboardingIdEncrypted}`}
                                  target="_blank">
                                  <i className="left">
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                      <path
                                        d="M9.66732 1.88867V5.33079C9.66732 5.7975 9.66732 6.03085 9.75815 6.20911C9.83804 6.36592 9.96552 6.4934 10.1223 6.57329C10.3006 6.66412 10.5339 6.66412 11.0007 6.66412H14.4428M9.66732 14.1641H4.66732M11.334 10.8307H4.66732M14.6673 8.32092V14.3307C14.6673 15.7309 14.6673 16.4309 14.3948 16.9657C14.1552 17.4361 13.7727 17.8186 13.3023 18.0582C12.7675 18.3307 12.0674 18.3307 10.6673 18.3307H5.33398C3.93385 18.3307 3.23379 18.3307 2.69901 18.0582C2.2286 17.8186 1.84615 17.4361 1.60647 16.9657C1.33398 16.4309 1.33398 15.7309 1.33398 14.3307V5.66406C1.33398 4.26393 1.33398 3.56387 1.60647 3.02909C1.84615 2.55868 2.2286 2.17623 2.69901 1.93655C3.23379 1.66406 3.93385 1.66406 5.33398 1.66406H8.01046C8.62194 1.66406 8.92768 1.66406 9.2154 1.73314C9.47049 1.79438 9.71435 1.89539 9.93803 2.03246C10.1903 2.18707 10.4065 2.40326 10.8389 2.83564L13.4957 5.49249C13.9281 5.92487 14.1443 6.14106 14.2989 6.39335C14.436 6.61703 14.537 6.86089 14.5982 7.11598C14.6673 7.4037 14.6673 7.70944 14.6673 8.32092Z"
                                        stroke="CurrentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </i>
                                  View checklist
                                </Button>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </TableContainer>
                    </div>
                  </div>
                )}
            </>
          )}
      </form>
    </>
  );
};

export default OnboardingCreateEditFormData;
