import React, { useEffect, useState } from 'react';
import { SurveyData } from '../../../../utils/common/surveyjs/survey-js';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import PaginationSettings from '../../settings/Pagination';
import { PaginationResponse, UserState } from '../../user/state';
import { format } from 'date-fns';
import {
  checkUserIsNewEntrant,
  checkUserPermission,
  encryptData,
  getSelectedAccountInfo
} from '../../../../utils/common/user';
import { DELETE, MODULES, UPDATE } from '../../../../constants/constants';
import { ProfessionalYearState, ProfessionalYearTemplatesState } from './state';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import trashGrey from '../../../../assets/images/trash-grey.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from '../../common/popup/Popup';
import { useNotificationContext } from '../../layout/NotificationContext';
import { t } from 'i18next';
import { fetchProfessionalYearsLogTrainingData } from '../../../../api/professional-year/fetchProfessionalYearsLogTrainingData';
import { deleteProfessionalYearLogTrainingData } from '../../../../api/professional-year/deleteProfessionalYearLogTrainingData';
import { fetchProfessionalYears } from '../../../../api/professional-year/fetchProfessionalYears';
import { fetchProfessionalYearTemplates } from '../../../../api/settings/fetchProfessionalYearTemplates';

export interface ProfessionalYearLogTrainingTemplateTableModeProps {
  user: UserState | undefined;
  sourceId: number;
  professionalYearId: number;
  authToken: string;
  title?: string;
  type?: string;
  formData?: ProfessionalYearState;
  surveyData?: SurveyData;
  add_btn?: JSX.Element | null;
  trainingLogAdded?: boolean;
  setTrainingLogAdded?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleTemplateDialog?: (type: string, id: number) => void;
  professionalYearIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

export interface ProfessionalYearLogTrainingTemplateData {
  id: number;
  date: string;
  course_name: string;
  course_provider: string;
  area: string;
  hours: string;
}

const ProfessionalYearLogTrainingTemplateTableMode: React.FC<
  ProfessionalYearLogTrainingTemplateTableModeProps
> = ({
  user,
  sourceId,
  professionalYearId,
  authToken,
  title,
  type,
  formData,
  surveyData,
  add_btn,
  trainingLogAdded,
  setTrainingLogAdded,
  toggleTemplateDialog,
  professionalYearIdEncrypted,
  sourceIdEncrypted
}) => {
  const navigate = useNavigate();
  const selectedAccount = getSelectedAccountInfo();
  const { pathname, search } = useLocation();
  const current_route = pathname;
  const [loading, setLoading] = useState<boolean>(true);
  const [dense] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [records, setRecords] = useState<ProfessionalYearLogTrainingTemplateData[]>([]);
  const [logTrainingDataId, setLogTrainingDataId] = useState<number>(0);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);
  const { setNotificationSuccess } = useNotificationContext();
  const [logTrainingTemplate, setLogTrainingTemplate] = useState<SurveyData>({} as SurveyData);
  const [proessionalYear, setProessionalYear] = useState<ProfessionalYearState>({
    id: 0
  });

  interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
  }
  const [headCells] = useState<HeadCell[]>([
    {
      id: 'date',
      numeric: false,
      disablePadding: true,
      label: 'Date'
    },
    {
      id: 'course_name',
      numeric: false,
      disablePadding: true,
      label: 'Course Name'
    },
    {
      id: 'course_provider',
      numeric: false,
      disablePadding: true,
      label: 'Course Provider'
    },
    {
      id: 'area',
      numeric: false,
      disablePadding: true,
      label: 'Area of learning/ competency'
    },
    {
      id: 'hours',
      numeric: true,
      disablePadding: false,
      label: 'Hours'
    }
  ]);

  const fetchProfessionalYearData = async () => {
    if (professionalYearId && authToken) {
      try {
        const response: ProfessionalYearState = (await fetchProfessionalYears(
          authToken,
          selectedAccount,
          professionalYearId
        )) as ProfessionalYearState;

        if (response) {
          setProessionalYear(response);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setProessionalYear((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem('redirect_back');

    if (formData) {
      setProessionalYear(formData);
    } else {
      fetchProfessionalYearData();
    }
  }, [formData]);

  const getProfessionalYearTemplates = async () => {
    try {
      if (professionalYearId && authToken) {
        const response: ProfessionalYearTemplatesState[] = (await fetchProfessionalYearTemplates(
          authToken,
          selectedAccount,
          sourceId,
          professionalYearId
        )) as ProfessionalYearTemplatesState[];

        if (response.length > 0) {
          response.map((template: ProfessionalYearTemplatesState) => {
            if (template.template !== '""') {
              if (template.type === t('templateType.logTraining')) {
                setLogTrainingTemplate(JSON.parse(template.template));
              }
            } else {
              console.log('Error: ', t('error.field.notemplateFound'));
            }
          });
        } else {
          console.log('Error: ', t('error.field.notemplateFound'));
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (surveyData && Object.keys(surveyData).length > 0) {
      setLogTrainingTemplate(surveyData);
    } else {
      getProfessionalYearTemplates();
    }
  }, [surveyData]);

  useEffect(() => {
    if (logTrainingTemplate && Object.keys(logTrainingTemplate).length > 0) {
      const elements = logTrainingTemplate.pages[0].elements;

      elements.map((element: any) => {
        if (element.name == 'Date') {
          headCells[0].label = element.title;
        } else if (element.name == 'CourseName') {
          headCells[1].label = element.title;
        } else if (element.name == 'CourseProvider') {
          headCells[2].label = element.title;
        } else if (element.name == 'Area') {
          headCells[3].label = element.title;
        } else if (element.name == 'Hours') {
          headCells[4].label = element.title;
        }
      });
    }
  }, [logTrainingTemplate]);

  const getLogTrainingTemplateDatas = async () => {
    try {
      if (authToken && professionalYearId) {
        setLoading(true);

        const response: PaginationResponse = (await fetchProfessionalYearsLogTrainingData(
          authToken,
          professionalYearId,
          type || '',
          currentPage
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setRecords(response.data);
          }
          setTotalPages(response.last_page);
          setLoading(false);

          if (setTrainingLogAdded) {
            setTrainingLogAdded(false);
          }
        }
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLogTrainingTemplateDatas();
  }, [professionalYearId, currentPage]);

  useEffect(() => {
    if (trainingLogAdded) {
      getLogTrainingTemplateDatas();
    }
  }, [trainingLogAdded]);

  const redirectEditPage = (rowid: number) => {
    localStorage.setItem('redirect_back', pathname + search);
    navigate(
      `/professional-year/log-training-edit/${encryptData(String(rowid), true)}?source=${sourceIdEncrypted}&professionalYearId=${professionalYearIdEncrypted}`
    );
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (logTrainingDataId && authToken) {
        const response: ProfessionalYearState = (await deleteProfessionalYearLogTrainingData(
          logTrainingDataId,
          authToken
        )) as ProfessionalYearState;

        if (response.id) {
          setNotificationSuccess(t('success.field.professionalYearLogTrainingDataDeleted'));
          getLogTrainingTemplateDatas();
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  const checkCurrentRoute = () => {
    const routes = [
      '/new-entrant',
      '/new-entrant/q1plan-entrant-edit',
      '/new-entrant/q1plan-entrant-view',
      '/new-entrant/q2plan-entrant-edit',
      '/new-entrant/q2plan-entrant-view',
      '/new-entrant/q3plan-entrant-edit',
      '/new-entrant/q3plan-entrant-view',
      '/new-entrant/q4plan-entrant-edit',
      '/new-entrant/q4plan-entrant-view'
    ];

    return routes.includes(current_route);
  };

  return (
    <>
      <div className="card-title-wrap secondary-title-wrap">
        <div>
          <h3>{title ? title : surveyData?.title ? surveyData?.title : 'Training Log'}</h3>
        </div>
        <div className="inner-btn-wrap">{add_btn}</div>
      </div>
      <div className="client-review-table">
        <div className="table-outer">
          <TableContainer>
            {loading ? ( // Check loading state
              <div className="no-record">
                <CircularProgress />
              </div>
            ) : records.length === 0 ? (
              <div className="no-record">No records found.</div>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                className="table hover-table">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={`key-${headCell.id}`}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}>
                        {headCell.label}
                      </TableCell>
                    ))}
                    {checkUserIsNewEntrant(user) && (checkUserPermission(user, MODULES, sourceId, UPDATE) ||
                      checkUserPermission(user, MODULES, sourceId, DELETE)) && (
                      <TableCell></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records.map((row: ProfessionalYearLogTrainingTemplateData) => (
                    <TableRow key={row.id}>
                      {row.date ? (
                        <TableCell align="left">
                          {format(new Date(row.date), 'd/MMM/yyyy')}
                        </TableCell>
                      ) : (
                        <TableCell align="left">N/A</TableCell>
                      )}
                      <TableCell align="left">{row.course_name}</TableCell>
                      <TableCell align="left">{row.course_provider}</TableCell>
                      <TableCell align="left">{row.area}</TableCell>
                      <TableCell align="left">{row.hours}</TableCell>
                      {checkUserIsNewEntrant(user) && (checkUserPermission(user, MODULES, sourceId, UPDATE) ||
                        checkUserPermission(user, MODULES, sourceId, DELETE)) && (
                        <TableCell align="left" className="right-arrow-width">
                          <div className="table-btn">
                            {checkUserPermission(user, MODULES, sourceId, DELETE) && (
                              <Button
                                className="edit"
                                onClick={() => {
                                  setLogTrainingDataId(row.id);
                                  setOpenConfirmationPopup(true);
                                }}>
                                <img src={trashGrey} alt="trashGrey" />
                              </Button>
                            )}

                            {checkUserPermission(user, MODULES, sourceId, UPDATE) && (
                              <>
                                {proessionalYear && proessionalYear.is_open == 1 && (
                                  <Button
                                    className="edit"
                                    onClick={() => {
                                      checkCurrentRoute() && toggleTemplateDialog
                                        ? toggleTemplateDialog(
                                            t('templateType.logTraining'),
                                            row.id
                                          )
                                        : redirectEditPage(row.id);
                                    }}>
                                    <img src={rightarrow} alt="Right Arrow" />
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}

            {!loading && records.length > 0 && (
              <PaginationSettings
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                scroll={false}
              />
            )}
          </TableContainer>
        </div>
      </div>

      <Popup
        type="confirmation"
        openPopup={openConfirmationPopup}
        text={`Are you sure you want to delete this record?`}
        togglePopup={togglePopup}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
};

export default ProfessionalYearLogTrainingTemplateTableMode;
