import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  CircularProgress,
  Avatar,
  Grid
} from '@mui/material';
import { getCalendarEvents } from '../../../../api/calendar/getCalendarEvents';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  decryptData,
  encryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { CalenderEventState } from '../../user/state';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import { format } from 'date-fns';
import { RRule } from 'rrule';
import { mapFrequencyToString, recurringPatternContent } from '../../../../utils/commonUtils';
import { MODULES, READ } from '../../../../constants/constants';

const CalendarSchedule = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = decryptData(id, true);
  const title = 'Schedule';
  const pageTitle = 'Compliance Calendar Schedule';
  const secondTitle = 'Compliance Calendar';
  const mainPath = `/compliance-calendar/${encryptedId}`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: Compliance Calendar ${title}`;
  const [orderBy, setOrderBy] = useState<keyof CalenderEventState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const [events, setEvents] = useState<CalenderEventState[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [dense] = useState(false);

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, parseInt(id!, 10), READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const getNextDate = (dates: string[], currentDate: Date, eventDate: Date): Date | null => {
    const sortedDates = dates.map((date) => new Date(date)).sort((a: any, b: any) => a - b);

    for (const date of sortedDates) {
      if (date > currentDate && !isSameDay(date, eventDate)) {
        return date;
      }
    }

    return null;
  };

  const isSameDay = (date1: Date, date2: Date): boolean => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const getNextEventDate = (recurringDates: string, eventDate: string): string => {
    const dates = recurringDates.split(',');
    const eventDateObj = new Date(eventDate);
    const nextDate = getNextDate(dates, new Date(), eventDateObj);

    return nextDate ? format(nextDate, 'd/MMM/yyyy') : 'N/A';
  };

  interface HeadCell {
    disablePadding: boolean;
    id: keyof CalenderEventState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'event_date',
      numeric: false,
      disablePadding: true,
      label: 'Commenced'
    },
    {
      id: 'subject',
      numeric: false,
      disablePadding: true,
      label: 'Subject'
    },
    {
      id: 'owner_id',
      numeric: true,
      disablePadding: false,
      label: 'Owner'
    },
    {
      id: 'recurrence_rule',
      numeric: true,
      disablePadding: false,
      label: 'Recurrence'
    },
    {
      id: 'recurring_event_dates',
      numeric: false,
      disablePadding: true,
      label: 'Next'
    }
  ];

  const fetchRecurrenceContent = (data: CalenderEventState) => {
    if (data.is_recurring === 'yes' && data.recurrence_rule) {
      const rule = RRule.fromString(data.recurrence_rule);
      const freq = rule.options.freq >= 0 ? mapFrequencyToString(rule.options.freq) : '';

      if (data.recurrence_type === 0) {
        const requestData = {
          day: rule.options.bymonthday ? rule.options.bymonthday.toString() : '',
          month: rule.options.interval ? rule.options.interval.toString() : '',
          monthNumber: rule.options.bymonth ? rule.options.bymonth.toString() : '',
          recurrence_pattern: freq,
          pattern_dayNumber: '',
          pattern_dayName: '',
          pattern_month: ''
        };
        return recurringPatternContent(data, requestData);
      } else {
        const byDayMatch = data.recurrence_rule.match(/BYDAY=([+-]?\d*)([A-Za-z]{2})/);

        let occurrenceNumber: number = 0; // Initialize with a default value
        let dayAbbreviation: string = '';

        if (byDayMatch) {
          occurrenceNumber = parseInt(byDayMatch[1].toString(), 10); // Extracted occurrence number
          dayAbbreviation = byDayMatch[2]; // Extracted day abbreviation
        }

        const requestData = {
          recurrence_pattern: freq,
          pattern_dayNumber: occurrenceNumber ? occurrenceNumber.toString() : '',
          pattern_dayName: dayAbbreviation,
          pattern_month: rule.options.interval ? rule.options.interval.toString() : '',
          pattern_monthNumber: rule.options.bymonth ? rule.options.bymonth.toString() : ''
        };
        return recurringPatternContent(data, requestData);
      }
    }
  };

  const handleSort = (property: keyof CalenderEventState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property as string, isAsc ? 'desc' : 'asc'); // Cast to string
  };

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (authToken) {
        const source_id = parseInt(id!, 10);
        const response: CalenderEventState[] = await getCalendarEvents(
          source_id,
          authToken,
          selectedAccount,
          true,
          orderBy,
          order
        );

        if (response) {
          if (Array.isArray(response)) {
            setEvents(response);
          }
          setLoading(false);
        } else {
          console.error('Data is not available in the response:', response);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    localStorage.removeItem('calendarTasksCount');
    localStorage.removeItem('calendarNotesCount');

    fetchData(orderBy as string, order); // Call the async function
  }, [id]);

  const redirectEditPage = (rowid: number) => {
    navigate(`/calendar/${encryptData(String(rowid), true)}?source=${encryptedId}`);
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section">
            <div className="container-full">
              <CommonBreadcrumbs
                title={title}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
              />
              <div className="table-outer">
                <TableContainer>
                  {loading ? (
                    <div className="no-record">
                      <CircularProgress />
                    </div>
                  ) : events.length === 0 ? (
                    <div className="no-record">No Events found.</div>
                  ) : (
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                      className="table hover-table">
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={`key-${headCell.id}`}
                              align={headCell.numeric ? 'left' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}>
                              {headCell.id === 'unique_id' ? (
                                headCell.label // Render the label without sorting for the "id" column
                              ) : (
                                <TableSortLabel
                                  active={orderBy === headCell.id}
                                  direction={orderBy === headCell.id ? order : 'asc'}
                                  onClick={() => handleSort(headCell.id)}>
                                  {headCell.label}
                                  {orderBy === headCell.id ? (
                                    <Box component="span" sx={{ display: 'none' }}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              )}
                            </TableCell>
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {events.map((data: CalenderEventState) => (
                          <TableRow
                            key={data.id}
                            onClick={() => redirectEditPage(data.id ? data.id : 0)}>
                            <TableCell scope="row" className="unique-id-width">
                              {format(new Date(data.event_date), 'd/MMM/yyyy')}
                            </TableCell>
                            <TableCell align="left">{data.subject}</TableCell>

                            <TableCell align="left">
                              <Grid container alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                  {data?.owner?.profile_image ? (
                                    <Avatar
                                      src={`${JSON.parse(data.owner.profile_image).path}`}
                                      alt={data?.owner ? data?.owner.first_name : 'user'}
                                      onError={(e) => {
                                        const target = e.target as HTMLImageElement;
                                        target.style.display = 'none'; // Hide the image
                                        const nextElement =
                                          target.nextElementSibling as HTMLElement | null;
                                        if (nextElement) {
                                          nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                        }
                                      }}
                                    />
                                  ) : (
                                    <Avatar alt={data?.owner?.first_name} src="." />
                                  )}
                                </Grid>
                                <Grid item className="content">
                                  <p>{data?.owner?.first_name + ' ' + data?.owner?.last_name}</p>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="left">{fetchRecurrenceContent(data)}</TableCell>
                            <TableCell scope="row" className="unique-id-width">
                              {getNextEventDate(data.recurring_event_dates || '', data.event_date)}
                            </TableCell>

                            <TableCell align="left" className="right-arrow-width">
                              <div className="table-btn">
                                <img src={rightarrow} alt="Right Arrow" />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default CalendarSchedule;
