import React, { useState, useEffect } from 'react';
import Tasks from '../tasks/Tasks';
import { useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../utils/common/user';
import { useNavigate } from 'react-router-dom';
import { REGISTERS, TASK_CREATE, TASK_READ } from '../../../constants/constants';
import CommonLayout from '../layout/CommonLayout';
import CommonBreadcrumbs from '../layout/Breadcrumb/CommonBreadcrumbs';
import RecordLeftMenu from './RecordLeftMenu';
import AddButton from '../tasks/AddButton';
import { useRegistersContext } from './RegisterContext';
import { fetchRecords } from '../../../api/records/fetchRecords';
import { RecordsState } from '../user/state';

const RegisterTasksIndex = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let recordId = queryParams.get('recordId');
  const encryptedRecordId = recordId; // Decrypt the ID
  recordId = decryptData(recordId, true);
  const authToken = useAuthToken();
  const [currentStatus, setCurrentStatus] = useState<number>(1);
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const mainPath = `/register/${encryptedSourceId}`;
  const title = 'Tasks';
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { registersRecords } = useRegistersContext();

  if (sourceId && registersRecords) {
    const registerId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(registersRecords) && registersRecords.length > 0
        ? registersRecords.find((register) => register.id === registerId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id_local = localStorage.getItem('record_unique_id');
  if (unique_id_local) {
    secondTitle = unique_id_local;
  }
  const secondpath = `/register-record/${encryptedRecordId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, REGISTERS, source_Id, TASK_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchRecordData = async () => {
    try {
      if (recordId && authToken) {
        const editId = parseInt(recordId!, 10);

        const response: RecordsState = (await fetchRecords(
          0,
          authToken,
          selectedAccount,
          editId
        )) as RecordsState;

        if (response) {
          const { unique_id, is_open } = response;
          setCurrentStatus(is_open ? is_open : 0);
          localStorage.removeItem('record_unique_id');
          localStorage.setItem('record_unique_id', unique_id || '');
        }
      }
    } catch (error) {
      /* empty */
      console.log(error);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, REGISTERS, source_Id, TASK_CREATE) && (
        <AddButton
          addPath={`/register/add-task?source=${encryptedSourceId}&recordId=${encryptedRecordId}`}
        />
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                button={currentStatus ? addButton : null}
              />
              <div className="record-outer-row settings-outer-row">
                <RecordLeftMenu
                  user={user}
                  recordId={recordId ? recordId : ''}
                  sourceId={sourceId ? sourceId : ''}
                  registerIdEncrypted={encryptedRecordId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <Tasks
                  moduleType="register_records"
                  sourceId={sourceId}
                  recordId={recordId}
                  localKey="record_unique_id"
                  currentStatus={currentStatus}
                  fetchModuleData={fetchRecordData}
                  editUrl={`/register/edit-task/rowId?source=${encryptedSourceId}&recordId=${encryptedRecordId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default RegisterTasksIndex;
