import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  Button,
  CircularProgress,
  Avatar
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  encryptData
} from '../../../utils/common/user';
import { format, isAfter } from 'date-fns';
import PaginationSettings from '../settings/Pagination';
import { PaginationResponse } from '../user/state';
import { fetchTasks } from '../../../api/reporting/fetchReportingTasks';
import CommonBreadcrumbs from '../layout/Breadcrumb/CommonBreadcrumbs';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import edit from '../../../assets/images/edit.svg';
import CommonLayout from '../layout/CommonLayout';
import TasksFilters from './FilterBtn';
import { TasksState } from './state';
import { EXPORT, READ, TASKS, UPDATE } from '../../../constants/constants';
import { downloadTasksReport } from '../../../api/tasks/downloadTasksReport';

const TasksMyIndex: React.FC = () => {
  const title = 'My Tasks';
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [orderBy, setOrderBy] = useState<keyof TasksState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [loading, setLoading] = useState<boolean>(true);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [dense] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tasks, setTasks] = useState<TasksState[]>([]);
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const [openRow, setOpenRow] = useState<number | null>(null);
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('0');

  interface HeadCell {
    disablePadding: boolean;
    id: keyof TasksState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'date_due',
      numeric: false,
      disablePadding: true,
      label: 'Due'
    },
    {
      id: 'task',
      numeric: false,
      disablePadding: true,
      label: 'Task'
    },
    {
      id: 'owner',
      numeric: false,
      disablePadding: true,
      label: 'Owner'
    },
    {
      id: 'unique_id',
      numeric: false,
      disablePadding: true,
      label: 'Source'
    },
    {
      id: 'is_complete',
      numeric: false,
      disablePadding: true,
      label: 'Status'
    },
    {
      id: 'date_complete',
      numeric: false,
      disablePadding: true,
      label: 'Completed'
    }
  ];

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, TASKS, 2, READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const handleSort = (property: keyof TasksState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property, isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleRowToggle = (rowId: number) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
    // Set the currently open row
    if (openRow === rowId) {
      setOpenRow(null); // If the same row was clicked again, close it
    } else {
      setOpenRow(rowId);
    }
  };

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (authToken) {
        const response: PaginationResponse = (await fetchTasks(
          authToken,
          selectedAccount,
          currentPage,
          10,
          2,
          search,
          selectedStatus,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTasks(response.data);
          }
          setTotalPages(response.last_page);
          setLoading(false); // Set loading to false when data is fetched
        }
      }
    } catch (error) {
      // Handle any errors here
      setLoading(false); // Set loading to false on error too
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const allKeys = Object.keys(localStorage);
    const keysToKeep = ['userData', 'selected_account', 'lastPageURL'];
    allKeys.forEach((key) => {
      if (!keysToKeep.includes(key)) {
        localStorage.removeItem(key);
      }
    });
    fetchData(orderBy, order); // Call the async function
  }, [currentPage, search, selectedStatus]);

  const UrlAsModuleType = (type: string, taskId: number, parentId: number, sourceId: number) => {
    let url;
    if (type === 'register_records') {
      url = `/register/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&recordId=${encryptData(String(parentId), true)}`;
    } else if (type === 'reviews') {
      url = `/review/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&reviewId=${encryptData(String(parentId), true)}`;
    } else if (type === 'calendar_events') {
      url = `/calendar/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&moduleId=${encryptData(String(parentId), true)}`;
    } else if (type === 'meetings') {
      url = `/meeting/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&meetingId=${encryptData(String(parentId), true)}`;
    } else if (type === 'onboardings') {
      url = `/onboarding/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&onboardingId=${encryptData(String(parentId), true)}`;
    } else if (type === 'providers') {
      url = `/providers/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&providerId=${encryptData(String(parentId), true)}`;
    } else if (type === 'prevets') {
      url = `/prevet/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&prevetId=${encryptData(String(parentId), true)}`;
    } else if (type === 'adviser-profile') {
      url = `/adviser-profile/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&adviserProfileId=${encryptData(String(parentId), true)}`;
    } else if (type === 'attestations') {
      url = `/attestation/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&attestationId=${encryptData(String(parentId), true)}`;
    } else if (type === 'professional_years') {
      url = `/professional-year/edit-task/${encryptData(String(taskId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}&professionalYearId=${encryptData(String(parentId), true)}`;
    }
    return url;
  };

  const UrlForModule = (type: string, parentId: number, sourceId: number) => {
    let url;
    if (type === 'register_records') {
      url = `/register-record/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    } else if (type === 'reviews') {
      url = `/review/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    } else if (type === 'calendar_events') {
      url = `/calendar/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    } else if (type === 'meetings') {
      url = `/meeting/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    } else if (type === 'onboardings') {
      url = `/onboarding/details/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    } else if (type === 'providers') {
      url = `/providers/details/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    } else if (type === 'prevets') {
      url = `/prevet/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    } else if (type === 'adviser-profile') {
      url = `/adviser-profile/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    } else if (type === 'attestations') {
      url = `/attestation/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    } else if (type === 'professional_years') {
      url = `/professional-year/details/${encryptData(String(parentId), true)}?source=${encryptData(
        String(sourceId),
        true
      )}`;
    }
    return url;
  };

  const handleEditTask = (url: string) => {
    if (url) {
      localStorage.setItem('taskPageUrl', '2');
      navigate(url);
    }
  };

  const handleExportExcel = async () => {
    setIsExporting(true);
    try {
      if (authToken) {
        const response: any = await downloadTasksReport(authToken, selectedAccount, 1, title);

        if (response.status === 200) {
          // Handle success
        } else {
          console.log('Error:', response);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally{
      setIsExporting(false)
    }
  };

  const addButton = (
    <div className="inner-btn-wrap">
      {checkUserPermission(user, TASKS, 2, EXPORT) && tasks.length > 0 && (
        <>
          {isExporting ? (
            <CircularProgress />
          ) : (
            <Button
              className="btn blue-fill-btn"
              onClick={() => handleExportExcel()}>
              <i className="left">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_6545_879)">
                    <path
                      d="M16.6666 10.416V5.66602C16.6666 4.26588 16.6666 3.56582 16.3941 3.03104C16.1544 2.56063 15.772 2.17818 15.3016 1.9385C14.7668 1.66602 14.0667 1.66602 12.6666 1.66602H7.33325C5.93312 1.66602 5.23306 1.66602 4.69828 1.9385C4.22787 2.17818 3.84542 2.56063 3.60574 3.03104C3.33325 3.56582 3.33325 4.26588 3.33325 5.66602V14.3327C3.33325 15.7328 3.33325 16.4329 3.60574 16.9677C3.84542 17.4381 4.22787 17.8205 4.69828 18.0602C5.23306 18.3327 5.93308 18.3327 7.33313 18.3327H10.4166M11.6666 9.16602H6.66659M8.33325 12.4993H6.66659M13.3333 5.83268H6.66659M12.4999 15.8327L14.9999 18.3327M14.9999 18.3327L17.4999 15.8327M14.9999 18.3327V13.3327"
                      stroke="CurrentColor"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6545_879">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              Export all data
            </Button>
          )}
        </>
      )}
    </div>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section">
            <div className="container-full">
              <CommonBreadcrumbs title={title} pageTitle={title} button={addButton} />
              <TasksFilters
                search={search}
                setSearch={setSearch}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
              <div className="table-outer">
                <TableContainer>
                  {loading ? ( // Check loading state
                    <div className="no-record">
                      <CircularProgress />
                    </div>
                  ) : tasks.length === 0 ? (
                    <div className="no-record">No tasks found.</div>
                  ) : (
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                      className="table">
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? 'left' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}
                              sortDirection={orderBy === headCell.id ? order : false}>
                              <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => handleSort(headCell.id)}>
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                  <Box component="span" sx={{ display: 'none' }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tasks.map((row: TasksState) => {
                          const url = UrlAsModuleType(
                            row.module_type,
                            row.id,
                            row.module_id,
                            row.source_id
                          );
                          const parentUrl = UrlForModule(
                            row.module_type,
                            row.module_id,
                            row.source_id
                          );
                          return (
                            row?.account_id == selectedAccount.id && (
                              <React.Fragment key={row.id}>
                                <TableRow
                                  key={row.id}
                                  id={`main-col-${row.id}`}
                                  className={openRow === row.id ? 'collapse' : ''}>
                                  {row.date_due && !row.is_complete ? (
                                    <TableCell
                                      align="left"
                                      className={
                                        isAfter(new Date(), new Date(row.date_due)) &&
                                        !row.is_complete
                                          ? 'overdue-status'
                                          : 'other-status'
                                      }>
                                      {row.date_due
                                        ? format(new Date(row.date_due), 'd/MMM/yyyy')
                                        : 'N/A'}
                                    </TableCell>
                                  ) : row.is_complete ? (
                                    <TableCell align="left">-</TableCell>
                                  ) : (
                                    <TableCell align="left">N/A</TableCell>
                                  )}
                                  <TableCell align="left">
                                    {(row.task || '')
                                      .replace(/<br\/>/g, '\n')
                                      .replace(/actioncontent/g, ' ')}
                                  </TableCell>
                                  <TableCell align="left">
                                    <div className="user-img-wrap">
                                      {row.profile_image ? (
                                        <Avatar
                                          src={`${JSON.parse(row.profile_image).path}`}
                                          alt={row.first_name ? row.first_name : 'user'}
                                          onError={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.style.display = 'none'; // Hide the image
                                            const nextElement =
                                              target.nextElementSibling as HTMLElement | null;
                                            if (nextElement) {
                                              nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                            }
                                          }}
                                        />
                                      ) : (
                                        <Avatar
                                          alt={row.first_name ? row.first_name : ''}
                                          src="."
                                        />
                                      )}
                                      {row.first_name ? (
                                        <p className="user-name">
                                          {row.first_name + ' ' + row.last_name}
                                        </p>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell align="left" className="unique-id-width">
                                    <Button
                                      component={RouterLink}
                                      to={parentUrl ? parentUrl : ''}
                                      className="">
                                      {row.unique_id ? row.unique_id : 'Profile'}
                                    </Button>
                                  </TableCell>
                                  {row.date_due ? (
                                    <TableCell
                                      align="left"
                                      className={
                                        isAfter(new Date(), new Date(row.date_due)) &&
                                        !row.is_complete
                                          ? 'overdue'
                                          : row.is_complete > 0
                                            ? 'completed'
                                            : 'opened'
                                      }>
                                      <span>
                                        {isAfter(new Date(), new Date(row.date_due)) &&
                                        !row.is_complete
                                          ? 'Overdue'
                                          : row.is_complete > 0
                                            ? 'Completed'
                                            : 'Open'}
                                      </span>
                                    </TableCell>
                                  ) : (
                                    <TableCell align="left">Open</TableCell>
                                  )}
                                  <TableCell align="left">
                                    {row.date_complete
                                      ? format(new Date(row.date_complete), 'd/MMM/yyyy')
                                      : '-'}
                                  </TableCell>
                                  <TableCell align="left">
                                    <div className="collapse-btn-wrapper">
                                      {checkUserPermission(user, TASKS, 2, UPDATE) &&
                                        row.parent_status == 1 && (
                                          <Button
                                            onClick={() => handleEditTask(url ? url : '')}
                                            className="edit table-inner-btn">
                                            <img src={edit} alt="Right Arrow" />
                                          </Button>
                                        )}
                                      <IconButton
                                        aria-label="expand row"
                                        size="large"
                                        onClick={() => handleRowToggle(row.id)}>
                                        {openRows[row.id] ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-open" key={`collapse-${row.id}`}>
                                  <TableCell colSpan={7}>
                                    <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
                                      {row.details && row.details.trim() && (
                                        <div className="mb-2 mt-2">
                                          Details:
                                          <pre className="pre-content">
                                            {(row.details || '')
                                              .replace(/<br\/>/g, '\n')
                                              .replace(/actioncontent/g, ' ')}
                                          </pre>
                                        </div>
                                      )}
                                      {row.status_updates && row.status_updates.trim() !== '' && (
                                        <div>Status Updates: {row.status_updates}</div>
                                      )}
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            )
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                  {!loading && tasks.length > 0 && (
                    <PaginationSettings
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </TableContainer>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default TasksMyIndex;
