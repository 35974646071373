import numberToWords from 'number-to-words';
import {
  CalendarFormData,
  CalendarEventData
} from '../views/components/modules/compliance-calendar/state';
import { RRule } from 'rrule';

export const recurringPatternContent = (formData: CalendarFormData, event: CalendarEventData) => {
  let pattern = '';
  // console.log(event, 'data')
  if (formData.is_recurring === 'yes') {
    let monthName = '';
    if (event.monthNumber == '1' || event.pattern_monthNumber == '1') {
      monthName = 'January';
    } else if (event.monthNumber == '2' || event.pattern_monthNumber == '2') {
      monthName = 'February';
    } else if (event.monthNumber == '3' || event.pattern_monthNumber == '3') {
      monthName = 'March';
    } else if (event.monthNumber == '4' || event.pattern_monthNumber == '4') {
      monthName = 'April';
    } else if (event.monthNumber == '5' || event.pattern_monthNumber == '5') {
      monthName = 'May';
    } else if (event.monthNumber == '6' || event.pattern_monthNumber == '6') {
      monthName = 'June';
    } else if (event.monthNumber == '7' || event.pattern_monthNumber == '7') {
      monthName = 'July';
    } else if (event.monthNumber == '8' || event.pattern_monthNumber == '8') {
      monthName = 'August';
    } else if (event.monthNumber == '9' || event.pattern_monthNumber == '9') {
      monthName = 'September';
    } else if (event.monthNumber == '10' || event.pattern_monthNumber == '10') {
      monthName = 'October';
    } else if (event.monthNumber == '11' || event.pattern_monthNumber == '11') {
      monthName = 'November';
    } else if (event.monthNumber == '12' || event.pattern_monthNumber == '12') {
      monthName = 'December';
    }

    if (formData.recurrence_type === 0) {
      if (event.day) {
        pattern = `${capitalizeFirstLetter(event.recurrence_pattern.toLowerCase())} - Day ${event.day} of every ${event.month} ${recurrence_naming(event.recurrence_pattern.toLowerCase())}(s)`;

        if (event.recurrence_pattern === 'YEARLY') {
          pattern = `${capitalizeFirstLetter(event.recurrence_pattern.toLowerCase())} - Day ${event.day} of ${monthName} of every ${event.month} ${recurrence_naming(event.recurrence_pattern.toLowerCase())}(s)`;
        }
      }
    } else {
      if (event.pattern_dayName) {
        pattern = `${capitalizeFirstLetter(event.recurrence_pattern.toLowerCase())} - ${getOrdinalWord(event.pattern_dayNumber ? event.pattern_dayNumber : '')} ${mapDaysToString(event.pattern_dayName)} of every ${event.pattern_month} ${recurrence_naming(event.recurrence_pattern.toLowerCase())}(s)`;

        if (event.recurrence_pattern === 'YEARLY') {
          pattern = `${capitalizeFirstLetter(event.recurrence_pattern.toLowerCase())} - ${getOrdinalWord(event.pattern_dayNumber ? event.pattern_dayNumber : '')} ${mapDaysToString(event.pattern_dayName)} of ${monthName} of every ${event.pattern_month} ${recurrence_naming(event.recurrence_pattern.toLowerCase())}(s)`;
        }
      }
    }
  }
  return pattern;
};

export const recurrence_naming = (name: string) => {
  if (name === 'weekly') {
    return 'week';
  } else if (name === 'monthly') {
    return 'month';
  } else if (name === 'yearly') {
    return 'year';
  }

  // Default case if none of the above conditions are met
  return 'unknown';
};

export const mapFrequencyToString = (frequency: number) => {
  switch (frequency) {
    case RRule.YEARLY:
      return 'YEARLY';
    case RRule.MONTHLY:
      return 'MONTHLY';
    case RRule.WEEKLY:
      return 'WEEKLY';
    case RRule.DAILY:
      return 'DAILY';
    default:
      return '';
  }
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const mapDaysToString = (day: string) => {
  switch (day) {
    case 'MO':
      return 'Monday';
    case 'TU':
      return 'Tuesday';
    case 'WE':
      return 'Wednesday';
    case 'TH':
      return 'Thursday';
    case 'FR':
      return 'Friday';
    case 'ST':
      return 'Saturday';
    case 'SU':
      return 'Sunday';
    default:
      return '';
  }
};

const getOrdinalWord = (number: string) => {
  if (number) {
    return numberToWords.toWordsOrdinal(number);
  }
  return '';
};

export const formattedContent = (details: string): string => {
  return details.replace(/<br\/>/g, '\n').replace(/actioncontent/g, ' ');
};

export const fetchReportPath = (report: string | undefined) => {
  const reportObject = report ? JSON.parse(report) : null;
  const typedReportObject = reportObject as { path: string; original_name: string };

  if (report) {
    return typedReportObject.path;
  } else {
    return null;
  }
};

export const isValidJsonString = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const moduleTypeMappings: Record<string, string> = {
  'adviser-profile': 'Modules',
  'reviews': 'Modules',
  'attestations': 'Modules',
  'calendar_events': 'Modules',
  'meetings': 'Modules',
  'onboardings': 'Modules',
  'providers': 'Registers',
  'prevets': 'Modules',
  'professional_years': 'Modules',
  'register_records': 'Registers',
  'policies': 'Settings'
};

export const getModuleCategory = (moduleType: string): string => {
  return moduleTypeMappings[moduleType];
};

export function clearLocalStorageExcept() {
  const allKeys = Object.keys(localStorage);
  const keysToKeep = ['userData', 'selected_account', 'lastPageURL', 'record-id', 'typeData'];
  allKeys.forEach((key) => {
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  });
}


export const isFormatted = (dateString: string): boolean => {
  // Check if the date string matches the expected format
  const regex = /^\d{1,2}\/[a-zA-Z]{3}\/\d{4}$/;
  return regex.test(dateString);
};