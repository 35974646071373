import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { getSelectedAccountInfo, useAuthToken } from '../../../../utils/common/user';
import { IncidentsBreachesReportDataState } from '../../user/state';
import { CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';
import { fetchIncidentsBreaches } from '../../../../api/reporting/fetchReportingIncidentsBreaches';

const IncidentsBreachesReportIndex = () => {
  const title = 'Incidents & Breaches';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainPath = '/dashboard';
  const secondTitle = 'Reports';
  const secondpath = '/dashboard';
  const pageTitle = title;
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<IncidentsBreachesReportDataState>();

  const fetchData = async () => {
    setLoading(true);

    try {
      if (authToken) {
        const response: IncidentsBreachesReportDataState = (await fetchIncidentsBreaches(
          authToken,
          selectedAccount
        )) as IncidentsBreachesReportDataState;
        if (response) {
          setChartData(response);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={secondpath}
          />
          {!loading &&
          !chartData?.incidentsRecorded &&
          !chartData?.incidentsRecordedDays &&
          !chartData?.clientResponsesForBreachReportable &&
          !chartData?.individualsByBeaches ? (
            <div className="no-record">No Data found.</div>
          ) : chartData ? (
            <>
              <div className="google-chart">
                <div className="report-chart margin-chart">
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={chartData.incidentsRecorded}
                    options={{
                      title: 'Number of Incidents / Breaches Recorded (last 12 months)',
                      pointSize: 5,
                      tooltip: { isHtml: true },
                      legend: { position: 'none' },
                      colors: ['#1DA2EF'],
                      chartArea: {
                        top: 40, // Increase top margin to provide more space for y-axis labels
                        bottom: 60, // Increase bottom margin to provide more space for x-axis labels
                        left: 50,
                        right: 50
                      }
                    }}
                  />
                </div>
                <div className="report-chart">
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={chartData.incidentsRecordedDays}
                    options={{
                      title: 'Days Taken to Rectify Breaches (last 12 months)',
                      tooltip: { isHtml: true }, // CSS styling affects only HTML tooltips.
                      legend: { position: 'none' },
                      colors: ['#1DA2EF'],
                      chartArea: {
                        top: 40, // Increase top margin to provide more space for y-axis labels
                        bottom: 60, // Increase bottom margin to provide more space for x-axis labels
                        left: 50,
                        right: 50
                      }
                    }}
                  />
                </div>
              </div>
              {chartData.clientResponsesForBreachReportable.length > 1 && (
                <div className="google-chart">
                  <div className="report-chart margin-chart">
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="400px"
                      data={chartData.clientResponsesForBreachReportable}
                      options={{
                        title: 'Ratio of non-reportable breaches to Reportable Situations',
                        // is3D: true,
                        pieSliceText: 'value',
                        slices: {
                          0: { offset: 0.1 }
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              {chartData.individualsByBeaches.length > 1 && (
                <div className="google-chart">
                  <div className="report-chart margin-chart margin-google-chart">
                    <Chart
                      chartType="BarChart"
                      width="100%"
                      height="400px"
                      data={chartData.individualsByBeaches}
                      options={{
                        title:
                          'Top 10 individuals by number of breaches identified (last 12 months)',
                        chartArea: { width: '50%' },
                        hAxis: {
                          title: 'Count',
                          minValue: 0
                        },
                        vAxis: {
                          title: 'Adviser Name'
                        },
                        bars: 'horizontal'
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          ) : null}

          {loading && (
            <div className="no-record">
              <CircularProgress />
            </div>
          )}
        </div>
      </section>
    </CommonLayout>
  );
};

export default IncidentsBreachesReportIndex;
