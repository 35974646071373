import React, { useEffect, useState } from 'react';
import CommonLayout from '../layout/CommonLayout';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useNavigate } from 'react-router';
import { useModuleContext } from '../settings/modules/ModulesContext';
import { useRegistersContext } from '../registers/RegisterContext';
import { isEmpty } from 'lodash';
import {
  checkUserIsNewEntrant,
  checkUserPermission,
  encryptData,
  getSelectedAccountInfo,
  isSubscriptionExpired,
  useAuthToken,
  useCurrentUserInfo
} from '../../../utils/common/user';
import { DashboardDataState, UserAccessState } from '../user/state';
import { fetchDashboardData } from '../../../api/dashboard/fetchDashboardData';
// import uploadPremium from '../../../assets/images/upgrade-premium.png';
import { CREATE, MODULES, PREVET_ID, READ, REGISTERS } from '../../../constants/constants';
import TaskColumn from './TaskColumn';
import UpdateColumn from './UpdateColumn';
import lockLightGrey from '../../../assets/images/lock-light-grey.svg';

interface SvgDetailsState {
  name: string;
  svgClipPath: string;
  svgPath: string;
}

const Dashboard: React.FC = () => {
  const metapageTitle = `${process.env.REACT_APP_NAME}: Dashboard`;

  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { registersRecords } = useRegistersContext();
  const { moduleRecords } = useModuleContext();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));

  const userAccess: UserAccessState = user?.user_access
    ? user?.user_access?.filter((item) => item.account_id === selectedAccount.id)[0]
    : ({} as UserAccessState);
  const authToken = useAuthToken();
  const [dashboardData, setDashboardData] = useState<DashboardDataState | undefined>();

  let filteredRegistersRecords = registersRecords;
  let registerDropdownOptions = registersRecords;

  if (Array.isArray(registerDropdownOptions) && registerDropdownOptions.length > 0) {
    // check for registers permission
    registerDropdownOptions = registerDropdownOptions.filter(
      (register) =>
        checkUserPermission(user, REGISTERS, register.id, CREATE) &&
        Array.isArray(register.account_register_access) &&
        register.account_register_access.length > 0
    );
  }

  if (Array.isArray(filteredRegistersRecords) && filteredRegistersRecords.length > 0) {
    // check for registers permission
    filteredRegistersRecords = filteredRegistersRecords.filter(
      (register) =>
        Array.isArray(register.account_register_access) &&
        register.account_register_access.length > 0
    );
  }

  let filteredModulesRecords = moduleRecords;
  let moduleDropdownOptions = moduleRecords;

  if (Array.isArray(moduleDropdownOptions) && moduleDropdownOptions.length > 0) {
    // check for modules permission
    moduleDropdownOptions = moduleDropdownOptions.filter(
      (module) =>
        checkUserPermission(user, MODULES, module.id, CREATE) &&
        module.id !== 6 &&
        Array.isArray(module.account_module_access) &&
        module.account_module_access.length > 0
    );
  }

  if (Array.isArray(filteredModulesRecords) && filteredModulesRecords.length > 0) {
    // check for modules permission
    filteredModulesRecords = filteredModulesRecords.filter(
      (module) =>
        Array.isArray(module.account_module_access) && module.account_module_access.length > 0
    );
  }

  let prevetRequest: { [key: string]: string }[] = [];

  if (userAccess && userAccess.isAdviser === 1) {
    prevetRequest = [
      {
        name: 'Request Pre-Vet',
        prefix: 'RPV'
      }
    ];
  }

  const allRegisterModuleRecords = [
    ...registerDropdownOptions,
    ...moduleDropdownOptions,
    ...prevetRequest
  ];

  allRegisterModuleRecords.sort((a, b) => {
    const labelA = a.add_record_label ? a.add_record_label : a.name;
    const labelB = b.add_record_label ? b.add_record_label : b.name;
    return labelA.localeCompare(labelB);
  });

  const SvgDetails: SvgDetailsState[] = [
    {
      name: 'Complaints (IDR) Register',
      svgClipPath: 'clip0_6545_879',
      svgPath:
        'M24.9993 6.66667V15M24.9993 15L33.3326 15M24.9993 15L34.9992 5M17.0443 23.1051C15.0417 21.1025 13.4604 18.8381 12.3004 16.422C12.2006 16.2142 12.1507 16.1103 12.1124 15.9788C11.9762 15.5116 12.074 14.9378 12.3574 14.5421C12.4371 14.4307 12.5324 14.3355 12.7229 14.145C13.3056 13.5623 13.5969 13.271 13.7874 12.978C14.5057 11.8732 14.5057 10.4489 13.7874 9.34405C13.5969 9.05109 13.3056 8.75975 12.7229 8.17707L12.3981 7.85229C11.5124 6.96655 11.0695 6.52369 10.5939 6.28311C9.64792 5.80466 8.53081 5.80466 7.58488 6.28311C7.10924 6.52369 6.66638 6.96655 5.78064 7.85229L5.51791 8.11502C4.63521 8.99772 4.19386 9.43907 3.85678 10.0391C3.48274 10.705 3.21381 11.7391 3.21608 12.5028C3.21813 13.1911 3.35163 13.6614 3.61864 14.6022C5.05359 19.6578 7.76105 24.4284 11.741 28.4084C15.721 32.3884 20.4916 35.0958 25.5472 36.5308C26.488 36.7978 26.9584 36.9313 27.6466 36.9334C28.4103 36.9356 29.4445 36.6667 30.1103 36.2927C30.7104 35.9556 31.1517 35.5142 32.0344 34.6315L32.2971 34.3688C33.1829 33.4831 33.6257 33.0402 33.8663 32.5646C34.3448 31.6186 34.3448 30.5015 33.8663 29.5556C33.6257 29.0799 33.1829 28.6371 32.2971 27.7513L31.9724 27.4266C31.3897 26.8439 31.0983 26.5525 30.8054 26.3621C29.7006 25.6437 28.2763 25.6437 27.1714 26.3621C26.8785 26.5525 26.5871 26.8439 26.0045 27.4266C25.8139 27.6171 25.7187 27.7123 25.6073 27.7921C25.2116 28.0754 24.6378 28.1733 24.1706 28.0371C24.0391 27.9987 23.9352 27.9488 23.7274 27.8491C21.3114 26.6891 19.0469 25.1077 17.0443 23.1051Z'
    },

    {
      name: 'Incident & Breach Register',
      svgClipPath: 'clip0_6545_879',
      svgPath:
        'M19.9999 13.333V19.9997M19.9999 26.6663H20.0166M3.33325 14.2042V25.7951C3.33325 26.2028 3.33325 26.4066 3.3793 26.5984C3.42013 26.7685 3.48747 26.931 3.57885 27.0801C3.68192 27.2483 3.82605 27.3925 4.1143 27.6807L12.3189 35.8853C12.6071 36.1735 12.7513 36.3177 12.9194 36.4207C13.0686 36.5121 13.2311 36.5795 13.4012 36.6203C13.593 36.6663 13.7968 36.6663 14.2045 36.6663H25.7953C26.203 36.6663 26.4068 36.6663 26.5986 36.6203C26.7687 36.5795 26.9313 36.5121 27.0804 36.4207C27.2486 36.3177 27.3927 36.1735 27.681 35.8853L35.8855 27.6807C36.1738 27.3925 36.3179 27.2483 36.421 27.0801C36.5124 26.931 36.5797 26.7685 36.6205 26.5984C36.6666 26.4066 36.6666 26.2028 36.6666 25.7951V14.2042C36.6666 13.7966 36.6666 13.5928 36.6205 13.401C36.5797 13.2309 36.5124 13.0683 36.421 12.9192C36.3179 12.751 36.1738 12.6069 35.8855 12.3186L27.681 4.11406C27.3927 3.8258 27.2486 3.68168 27.0804 3.57861C26.9313 3.48723 26.7687 3.41989 26.5986 3.37906C26.4068 3.33301 26.203 3.33301 25.7953 3.33301H14.2045C13.7968 3.33301 13.593 3.33301 13.4012 3.37906C13.2311 3.41989 13.0686 3.48723 12.9194 3.57861C12.7513 3.68168 12.6071 3.8258 12.3189 4.11406L4.1143 12.3186C3.82605 12.6069 3.68192 12.751 3.57885 12.9192C3.48747 13.0683 3.42013 13.2309 3.3793 13.401C3.33325 13.5928 3.33325 13.7966 3.33325 14.2042Z'
    },

    {
      name: 'Onboardings',
      svgClipPath: 'clip0_6545_879',
      svgPath:
        'M19.9999 25.8333H12.4999C10.174 25.8333 9.01101 25.8333 8.06468 26.1204C5.93401 26.7667 4.26665 28.4341 3.62032 30.5648C3.33325 31.5111 3.33325 32.6741 3.33325 35M31.6666 35V25M26.6666 30H36.6666M24.1666 12.5C24.1666 16.6421 20.8087 20 16.6666 20C12.5244 20 9.16659 16.6421 9.16659 12.5C9.16659 8.35786 12.5244 5 16.6666 5C20.8087 5 24.1666 8.35786 24.1666 12.5Z'
    },

    {
      name: 'Risk Register',
      svgClipPath: 'clip0_6545_879',
      svgPath:
        'M19.9995 15.0004V21.6671M19.9995 28.3337H20.0162M17.6917 6.48659L3.98356 30.1643C3.22322 31.4776 2.84305 32.1343 2.89924 32.6732C2.94825 33.1433 3.19453 33.5705 3.5768 33.8484C4.01506 34.1671 4.77383 34.1671 6.29137 34.1671H33.7077C35.2252 34.1671 35.984 34.1671 36.4222 33.8484C36.8045 33.5705 37.0508 33.1433 37.0998 32.6732C37.156 32.1343 36.7758 31.4776 36.0155 30.1643L22.3073 6.48658C21.5497 5.17798 21.1709 4.52368 20.6767 4.30392C20.2456 4.11223 19.7534 4.11223 19.3223 4.30392C18.8281 4.52368 18.4493 5.17798 17.6917 6.48659Z'
    },

    {
      name: 'Regulatory Updates',
      svgClipPath: 'clip0_6545_879',
      svgPath:
        'M23.3334 3.78223V10.6665C23.3334 11.5999 23.3334 12.0666 23.5151 12.4231C23.6749 12.7367 23.9298 12.9917 24.2434 13.1515C24.5999 13.3331 25.0667 13.3331 26.0001 13.3331H32.8843M26.6667 21.6663H13.3334M26.6667 28.333H13.3334M16.6667 14.9997H13.3334M23.3334 3.33301H14.6667C11.8665 3.33301 10.4664 3.33301 9.3968 3.87798C8.45599 4.35734 7.69108 5.12225 7.21172 6.06306C6.66675 7.13261 6.66675 8.53275 6.66675 11.333V28.6663C6.66675 31.4666 6.66675 32.8667 7.21172 33.9363C7.69108 34.8771 8.45599 35.642 9.3968 36.1214C10.4664 36.6663 11.8665 36.6663 14.6667 36.6663H25.3334C28.1337 36.6663 29.5338 36.6663 30.6034 36.1214C31.5442 35.642 32.3091 34.8771 32.7884 33.9363C33.3334 32.8667 33.3334 31.4666 33.3334 28.6663V13.333L23.3334 3.33301Z'
    },

    {
      name: 'Other Register',
      svgClipPath: 'clip0_6545_879',
      svgPath:
        'M35 19.9997L15 19.9997M35 9.99968L15 9.99967M35 29.9997L15 29.9997M8.33333 19.9997C8.33333 20.9201 7.58714 21.6663 6.66667 21.6663C5.74619 21.6663 5 20.9201 5 19.9997C5 19.0792 5.74619 18.333 6.66667 18.333C7.58714 18.333 8.33333 19.0792 8.33333 19.9997ZM8.33333 9.99967C8.33333 10.9201 7.58714 11.6663 6.66667 11.6663C5.74619 11.6663 5 10.9201 5 9.99967C5 9.0792 5.74619 8.33301 6.66667 8.33301C7.58714 8.33301 8.33333 9.0792 8.33333 9.99967ZM8.33333 29.9997C8.33333 30.9201 7.58714 31.6663 6.66667 31.6663C5.74619 31.6663 5 30.9201 5 29.9997C5 29.0792 5.74619 28.333 6.66667 28.333C7.58714 28.333 8.33333 29.0792 8.33333 29.9997Z'
    },

    {
      name: 'Meetings',
      svgClipPath: 'clip0_6545_879',
      svgPath:
        'M36.6666 35V31.6667C36.6666 28.5603 34.542 25.9501 31.6666 25.21M25.8333 5.4846C28.2764 6.47358 29.9999 8.86885 29.9999 11.6667C29.9999 14.4645 28.2764 16.8598 25.8333 17.8487M28.3333 35C28.3333 31.8937 28.3333 30.3406 27.8258 29.1154C27.1492 27.4819 25.8513 26.1841 24.2178 25.5075C22.9927 25 21.4395 25 18.3333 25H13.3333C10.227 25 8.67384 25 7.4487 25.5075C5.81518 26.1841 4.51735 27.4819 3.84072 29.1154C3.33325 30.3406 3.33325 31.8937 3.33325 35M22.4999 11.6667C22.4999 15.3486 19.5152 18.3333 15.8333 18.3333C12.1514 18.3333 9.16659 15.3486 9.16659 11.6667C9.16659 7.98477 12.1514 5 15.8333 5C19.5152 5 22.4999 7.98477 22.4999 11.6667Z'
    },
    {
      name: 'Adviser Reviews',
      svgClipPath: 'clip0_6545_879',
      svgPath:
        'M6.66663 36.3621C7.67092 36.6663 9.0274 36.6663 11.3333 36.6663H28.6666C30.9724 36.6663 32.3289 36.6663 33.3332 36.3621M6.66663 36.3621C6.45129 36.2968 6.25214 36.2176 6.0633 36.1214C5.12249 35.642 4.35759 34.8771 3.87822 33.9363C3.33325 32.8667 3.33325 31.4666 3.33325 28.6663V11.333C3.33325 8.53275 3.33325 7.13261 3.87822 6.06306C4.35759 5.12225 5.12249 4.35734 6.0633 3.87798C7.13286 3.33301 8.53299 3.33301 11.3333 3.33301H28.6666C31.4668 3.33301 32.867 3.33301 33.9365 3.87798C34.8773 4.35734 35.6423 5.12225 36.1216 6.06306C36.6666 7.13261 36.6666 8.53275 36.6666 11.333V28.6663C36.6666 31.4666 36.6666 32.8667 36.1216 33.9363C35.6423 34.8771 34.8773 35.642 33.9365 36.1214C33.7477 36.2176 33.5485 36.2968 33.3332 36.3621M6.66663 36.3621C6.66717 35.0132 6.67526 34.2994 6.79468 33.6991C7.32073 31.0545 9.38805 28.9872 12.0327 28.4611C12.6766 28.333 13.4511 28.333 14.9999 28.333H24.9999C26.5488 28.333 27.3232 28.333 27.9672 28.4611C30.6118 28.9872 32.6791 31.0545 33.2052 33.6991C33.3246 34.2994 33.3327 35.0132 33.3332 36.3621M26.6666 15.833C26.6666 19.5149 23.6818 22.4997 19.9999 22.4997C16.318 22.4997 13.3333 19.5149 13.3333 15.833C13.3333 12.1511 16.318 9.16634 19.9999 9.16634C23.6818 9.16634 26.6666 12.1511 26.6666 15.833Z'
    }
  ];

  useEffect(() => {
    if (checkUserIsNewEntrant(user)) {
      navigate('/new-entrant');
    }
  }, []);

  const handlerecordRedirect = (record: any | undefined) => {
    let route;
    if (record.prefix == 'PRV') {
      route = '/providers/add';
    } else if (record && registersRecords.includes(record)) {
      route = '/register-record-add';
    } else if (record && filteredModulesRecords.includes(record)) {
      route = '/' + record.route.slice(0, -1) + '-add';
      if (record.prefix == 'CAL') {
        route = '/calendar-event-add';
      } else if (record.prefix == 'ONB') {
        route = '/onboarding-add';
      } else if (record.prefix == 'PRY') {
        route = '/professional-year-add';
      }
    } else if (record.prefix == 'RPV') {
      route = '/prevets/request';
      navigate(`${route}?source=${encryptData(String(PREVET_ID), true)}`);
      return;
    }

    navigate(`${route}?source=${encryptData(String(record.id), true)}`);
  };

  const handleSvgData = (name: string) => {
    let svg = SvgDetails.find((svg) => svg.name === name);
    if (!svg || isEmpty(svg)) {
      svg = SvgDetails.find((svg) => svg.name === 'Other Register');
    }
    return svg?.svgPath;
  };

  const fetchDasboardDataResponse = async () => {
    try {
      // Fetch the records and update the state
      const response: any = await fetchDashboardData(selectedAccount, authToken);
      if (response) {
        setDashboardData(response ? response : []);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    fetchDasboardDataResponse();
  }, []);

  const findCountData = (prefix: string | undefined) => {
    let count = 0;
    if (prefix && dashboardData) {
      count = dashboardData[prefix] ? dashboardData[prefix] : 0;
    }
    return count;
  };

  const fetchLogoPath = (image: string) => {
    const imageObject = image ? JSON.parse(image) : null;
    const typedImageObject = imageObject as { path: string; original_name: string };

    if (image) {
      return typedImageObject.path;
    } else {
      return null;
    }
  };

  return (
    <>
      <CommonLayout title={metapageTitle}>
        <div className="container-full">
          <div className="dashboard-outer-wrapper">
            <TaskColumn />

            <div className="dashboard-middles-col">
              <div className="dashboard-form-outer">
                <form>
                  <div className="form-group">
                    <label className="form-label">Add new record</label>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={10}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}>
                      <MenuItem value={10} selected>
                        Select one
                      </MenuItem>
                      {Array.isArray(allRegisterModuleRecords) &&
                      allRegisterModuleRecords.length > 0 ? (
                        allRegisterModuleRecords.map((record) => (
                          <MenuItem
                            key={record.prefix}
                            value={record.prefix}
                            onClick={() => {
                              if (
                                (typeof record.account_register_access !== 'string' &&
                                  record.account_register_access &&
                                  parseInt(
                                    record.account_register_access[0]?.locked?.toString() ?? '0',
                                    10
                                  ) !== 1) ||
                                (typeof record.account_module_access !== 'string' &&
                                  record.account_module_access &&
                                  parseInt(
                                    record.account_module_access[0]?.locked?.toString() ?? '0',
                                    10
                                  ) !== 1) ||
                                record.name === 'Request Pre-Vet'
                              ) {
                                handlerecordRedirect(record);
                              }
                            }}
                            disabled={
                              (typeof record.account_register_access !== 'string' &&
                                record.account_register_access &&
                                parseInt(
                                  record.account_register_access[0]?.locked?.toString() ?? '0',
                                  10
                                ) === 1) ||
                              (typeof record.account_module_access !== 'string' &&
                                record.account_module_access &&
                                parseInt(
                                  record.account_module_access[0]?.locked?.toString() ?? '0',
                                  10
                                ) === 1)
                            }>
                            {(typeof record.account_register_access !== 'string' &&
                              record.account_register_access &&
                              parseInt(
                                record.account_register_access[0]?.locked?.toString() ?? '0',
                                10
                              ) === 1) ||
                            (typeof record.account_module_access !== 'string' &&
                              record.account_module_access &&
                              parseInt(
                                record.account_module_access[0]?.locked?.toString() ?? '0',
                                10
                              ) === 1) ? (
                              <i className="left">
                                <img src={lockLightGrey} alt="" />
                              </i>
                            ) : (
                              ''
                            )}

                            {record.add_record_label ? record.add_record_label : record.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>
                          <p className="no-register-available">There are no record available</p>
                        </MenuItem>
                      )}
                    </Select>
                  </div>
                </form>
              </div>
              <div className="dashboard-list">
                <h5>Registers</h5>
                <ul>
                  {Array.isArray(filteredRegistersRecords) &&
                  filteredRegistersRecords.length > 0 ? (
                    filteredRegistersRecords.map((register) => (
                      <li key={register.prefix} value={register.prefix}>
                        <a
                          className={`${
                            !checkUserPermission(user, REGISTERS, register.id, READ) ||
                            (register.account_register_access &&
                              (register.account_register_access.length === 0 ||
                                parseInt(
                                  register.account_register_access[0]?.locked?.toString() ?? '0',
                                  10
                                ) === 1)) ||
                            subscriptonExpired
                              ? 'in-active'
                              : ''
                          }`}
                          onClick={() => {
                            if (
                              checkUserPermission(user, REGISTERS, register.id, READ) &&
                              register.account_register_access &&
                              register.account_register_access.length > 0 &&
                              parseInt(
                                register.account_register_access[0]?.locked?.toString() ?? '0',
                                10
                              ) !== 1 &&
                              !subscriptonExpired
                            ) {
                              navigate(
                                register.prefix === 'PRV'
                                  ? `/providers/${encryptData(String(register.id), true)}`
                                  : `/register/${encryptData(String(register.id), true)}`
                              );
                            }
                          }}
                          style={{ background: selectedAccount?.color }}>
                          {register.logo ? (
                            <img
                              className="dashboard-icon"
                              src={`${fetchLogoPath(register.logo)}`}
                              alt="Logo"
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.style.display = 'none'; // Hide the image
                                const nextElement = target.nextElementSibling as HTMLElement | null;
                                if (nextElement) {
                                  nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                }
                              }}
                            />
                          ) : (
                            <i>
                              <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <g clipPath="url(#clip0_6545_879)">
                                  <path
                                    d={`${handleSvgData(register.name)}`}
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_6545_879">
                                    <rect width="40" height="40" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </i>
                          )}
                          <div className="content-wrapper">
                            <h6>{register.name}</h6>
                            {!checkUserPermission(user, REGISTERS, register.id, READ) ||
                            (register.account_register_access &&
                              (register.account_register_access.length === 0 ||
                                parseInt(
                                  register.account_register_access[0]?.locked?.toString() ?? '0',
                                  10
                                ) === 1)) ||
                            subscriptonExpired ? (
                              <p>Locked</p>
                            ) : (
                              <p>{`${findCountData(register?.prefix)} Open`}</p>
                            )}
                          </div>
                        </a>
                      </li>
                    ))
                  ) : (
                    <MenuItem>
                      <p className="no-register-available">There are no register available</p>
                    </MenuItem>
                  )}
                </ul>
              </div>
              <div className="dashboard-list">
                <h5>Modules</h5>
                <ul>
                  {Array.isArray(filteredModulesRecords) && filteredModulesRecords.length > 0 ? (
                    filteredModulesRecords.map((module) => (
                      <li key={module.prefix}>
                        <a
                          className={`${
                            !checkUserPermission(user, MODULES, module.id, READ) ||
                            (module.account_module_access &&
                              (module.account_module_access.length === 0 ||
                                parseInt(
                                  module.account_module_access[0]?.locked?.toString() ?? '0',
                                  10
                                ) === 1)) ||
                            subscriptonExpired
                              ? 'in-active'
                              : ''
                          }`}
                          onClick={() => {
                            if (
                              checkUserPermission(user, MODULES, module.id, READ) &&
                              module.account_module_access &&
                              module.account_module_access.length > 0 &&
                              parseInt(
                                module.account_module_access[0]?.locked?.toString() ?? '0',
                                10
                              ) !== 1 &&
                              !subscriptonExpired
                            ) {
                              navigate(`/${module.route}/${encryptData(String(module.id), true)}`);
                            }
                          }}
                          style={{ background: selectedAccount?.color }}>
                          {module.icon_path ? (
                            <img
                              className="dashboard-icon"
                              src={`${fetchLogoPath(module.icon_path)}`}
                              alt="Logo"
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.style.display = 'none'; // Hide the image
                                const nextElement = target.nextElementSibling as HTMLElement | null;
                                if (nextElement) {
                                  nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                }
                              }}
                            />
                          ) : (
                            <i>
                              <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <g clipPath="url(#clip0_6545_879)">
                                  <path
                                    d={`${handleSvgData(module.name)}`}
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_6545_879">
                                    <rect width="40" height="40" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </i>
                          )}
                          <div className="content-wrapper">
                            <h6>{module.name}</h6>
                            {!checkUserPermission(user, MODULES, module.id, READ) ||
                            (module.account_module_access &&
                              (module.account_module_access.length === 0 ||
                                parseInt(
                                  module.account_module_access[0]?.locked?.toString() ?? '0',
                                  10
                                ) === 1)) ||
                            subscriptonExpired ? (
                              <p>Locked</p>
                            ) : (
                              <p>{`${findCountData(module?.prefix)} Open`}</p>
                            )}
                          </div>
                        </a>
                      </li>
                    ))
                  ) : (
                    <MenuItem>
                      <p className="no-register-available">There are no modules available</p>
                    </MenuItem>
                  )}
                </ul>
              </div>
            </div>

            <UpdateColumn />
          </div>
        </div>
      </CommonLayout>
    </>
  );
};

export default Dashboard;
